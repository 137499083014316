/**
 * @file GdprNotification.jsx
 * @description Displays a notification bar for GDPR compliance and two modals (Privacy & Cookie Policies).
 *              Uses Bootstrap Buttons and includes final, formatted policies.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import { privacyPolicyText, cookiePolicyText } from "../content/policies";
import { localData } from "../functions/api/localData";

/**
 * @function PolicyModal
 * @description A reusable modal component for displaying policy information.
 * @param {Object} props - The props object
 * @param {boolean} props.isOpen - Determines whether the modal is visible
 * @param {Function} props.onClose - Callback function to close the modal
 * @param {Function} [props.onAccept] - Optional callback function to accept the policy
 * @param {string} props.title - Title displayed in the modal header
 * @param {string} props.content - Main text content (policy details) displayed in the modal
 * @returns {JSX.Element|null} The rendered modal or null if not open
 */
const PolicyModal = ({ isOpen, onClose, onAccept, title, content }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h2 style={{ marginBottom: "15px" }}>{title}</h2>
        <div
          style={{
            marginBottom: "20px",
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {content.split("\n\n").map((para) => (
            <p style={{ lineHeight: 1.6, marginBottom: "1rem" }} key={uuidv4()}>
              {para}
            </p>
          ))}
        </div>
        {/* Modal Buttons: Accept on the left, Close on the far right */}
        <div style={modalStyles.ButtonContainer}>
          {typeof onAccept === "function" && (
            <Button className="btn btn-success" onClick={onAccept}>
              Accept
            </Button>
          )}
          <Button className="btn btn-secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

PolicyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

/**
 * @function GdprNotification
 * @description Handles the GDPR notification bar. Prompts users to accept/reject GDPR compliance.
 *              If not previously set, the notification is shown. Also includes modals for Privacy & Cookie Policies.
 * @returns {JSX.Element} The GDPR notification bar with two modals for policy details
 */
const GdprNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  // State for controlling the policy modals
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [cookieModalOpen, setCookieModalOpen] = useState(false);

  useEffect(() => {
    /**
     * @function checkGdprStatus
     * @description Checks whether the gdpr key is stored in localData. If not, show the notification.
     */
    const checkGdprStatus = async () => {
      const gdprStatus = await localData("get", "gdpr");
      if (!gdprStatus) {
        // Show notification if no gdpr status is set
        setShowNotification(true);
      } else {
        // Hide if accepted or rejected
        setShowNotification(false);
      }
    };

    // Check GDPR status on mount
    checkGdprStatus();

    // Optionally poll every second (based on your requirement)
    const interval = setInterval(checkGdprStatus, 1000);
    return () => clearInterval(interval);
  }, []);

  /**
   * @function handleAcceptGdpr
   * @description Accepts GDPR and saves the status in local storage.
   */
  const handleAcceptGdpr = async () => {
    await localData("save", "gdpr", "accept");
    setShowNotification(false);
  };

  /**
   * @function handleRejectGdpr
   * @description Rejects GDPR and saves the status in local storage.
   */
  const handleRejectGdpr = async () => {
    await localData("save", "gdpr", "reject");
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <div style={styles.notificationContainer}>
          <div style={styles.notificationContent}>
            <p style={styles.message}>
              We value your privacy and are committed to complying with the
              General Data Protection Regulation (GDPR). Please review our{" "}
              <Button
                className="btn bg-transparent"
                onClick={() => setPrivacyModalOpen(true)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") setPrivacyModalOpen(true);
                }}
              >
                Privacy Policy
              </Button>{" "}
              and{" "}
              <Button
                className="btn bg-transparent"
                onClick={() => setCookieModalOpen(true)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") setCookieModalOpen(true);
                }}
              >
                Cookie Policy
              </Button>
              . Kindly accept our use of GDPR-compliant practices to continue.
            </p>

            <div style={styles.ButtonContainer}>
              <Button
                className="btn btn-primary"
                onClick={handleAcceptGdpr}
                style={styles.acceptButton}
              >
                Accept
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={handleRejectGdpr}
                style={styles.rejectButton}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Privacy Policy Modal */}
      <PolicyModal
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        onAccept={handleAcceptGdpr}
        title="Marmasco Technologies Privacy Policy"
        content={privacyPolicyText}
      />

      {/* Cookie Policy Modal */}
      <PolicyModal
        isOpen={cookieModalOpen}
        onClose={() => setCookieModalOpen(false)}
        onAccept={handleAcceptGdpr}
        title="Marmasco Technologies Cookie Policy"
        content={cookiePolicyText}
      />
    </>
  );
};

/**
 * Inline styles for the notification bar and elements
 */
const styles = {
  notificationContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#f9f9f9",
    color: "#333",
    padding: "15px",
    textAlign: "center",
    zIndex: 1000,
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  notificationContent: {
    maxWidth: "800px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginBottom: "10px",
    fontSize: "0.95rem",
    lineHeight: 1.6,
  },
  modalLinkText: {
    textDecoration: "none", // Removed underlining
    cursor: "pointer",
    fontWeight: "bold",
    color: "#0d6efd", // Bootstrap primary color for clickable text
    outline: "none",
  },
  ButtonContainer: {
    display: "flex",
    gap: "10px",
  },
  acceptButton: {
    fontWeight: "bold",
  },
  rejectButton: {
    fontWeight: "bold",
  },
};

/**
 * Inline styles for the policy modal overlay and content
 */
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 2000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#f8f8f8",
    color: "#333",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "600px",
    padding: "20px",
    boxSizing: "border-box",
    position: "relative",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
};

export default GdprNotification;
