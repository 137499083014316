/**
 * @file GetStarted.jsx
 * @description Handles user authentication flow (account check, login, account creation, OTP) for Marmasco.
 */

import React, { useState, useEffect, useRef } from "react";
import { runData } from "../functions/api/processor";
import { setSession } from "../utils/auth";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { setUserProfile } from "../account/profileData";
import CreateAccountModal from "./CreateAccountModal";
import OtpModal from "./OtpModal";
import {
  checkEmail,
  pinLogin,
  requestOtp,
  otpLogin,
  createAccount,
} from "./AuthService";
import featImg from "../assets/bg.jpg";
import { Col, Container, Row } from "react-bootstrap";
import DomainCarousel from "../components/DomainCourosel";
import { FaChevronRight, FaSignInAlt } from "react-icons/fa";
import { localData } from "../functions/api/localData";

const GetStarted = () => {
  // Processing & reporting contexts.
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // SEO state and fetching states.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  const [loadingSeo, setLoadingSeo] = useState(true);
  const [errorSeo, setErrorSeo] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "start" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setErrorSeo(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoadingSeo(false);
      }
    })();
  }, []);

  // Authentication and form states.
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasAccount, setHasAccount] = useState(null);
  const [userName, setUserName] = useState("");
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const otpKeys = [0, 1, 2, 3, 4, 5];
  const [otpRequested, setOtpRequested] = useState(false);
  const [lastOtpRequestTime, setLastOtpRequestTime] = useState(null);

  const [accountFormPage, setAccountFormPage] = useState(1);
  const [accountFormData, setAccountFormData] = useState({
    companyName: "",
    companyEmail: "",
    serviceOffered: [],
    useCompanyEmailForLogin: true,
    userEmail: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  // OTP event handlers.
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d$/.test(value) && value !== "") return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        otpRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6);
    if (!/^\d+$/.test(pastedData)) return;
    const newOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
      if (otpRefs.current[i]) {
        otpRefs.current[i].value = pastedData[i];
      }
    }
    setOtp(newOtp);
    const nextIndex = pastedData.length >= 6 ? 5 : pastedData.length;
    if (otpRefs.current[nextIndex]) otpRefs.current[nextIndex].focus();
  };

  useEffect(() => {
    if (otpRefs.current[0]) {
      otpRefs.current[0].addEventListener("paste", handleOtpPaste);
    }
    return () => {
      if (otpRefs.current[0]) {
        otpRefs.current[0].removeEventListener("paste", handleOtpPaste);
      }
    };
  }, []);

  // Authentication functions.
  const handleEmailCheck = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const response = await checkEmail(email);
      if (response.status === 200) {
        if (response.data.account === 1) {
          setHasAccount(true);
          setUserName(response.data.name);
        } else {
          setHasAccount(false);
          setShowCreateAccountModal(true);
        }
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred during email check.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during email check.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const response = await pinLogin(email, password);
      if (response.status === 200) {
        const { user } = response.data;
        const gdprStatus = await localData("get", "gdpr");
        if (gdprStatus === "accept") {
          await setUserProfile(user);
          setSession(true);
          setReport({
            show: true,
            message: "Logged in successfully using password!",
            type: "success",
          });
        } else {
          await localData("remove", "gdpr");
          localStorage.removeItem("session");
          setReport({
            show: true,
            message:
              "Your request has been accepted. However, you must accept our GDPR compliance to proceed.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "An error occurred during login.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "Unexpected error during login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleRequestOtp = async () => {
    setShowOtpModal(false);
    const TWO_MINUTES = 2 * 60 * 1000;
    const now = Date.now();
    if (lastOtpRequestTime && now - lastOtpRequestTime < TWO_MINUTES) {
      const remainingTime = Math.ceil(
        (TWO_MINUTES - (now - lastOtpRequestTime)) / 60000
      );
      setReport({
        show: true,
        message: `Please wait ${remainingTime} minute(s) before requesting a new OTP.`,
        type: "error",
      });
      return;
    }
    setProcessing(true);
    try {
      const response = await requestOtp(email);
      if (response.status === 200) {
        setOtpRequested(true);
        setLastOtpRequestTime(now);
        setShowOtpModal(true);
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred while requesting OTP.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while requesting OTP.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setShowOtpModal(false);
    setProcessing(true);
    try {
      const otpStr = otp.join("").trim();
      const response = await otpLogin(email, otpStr);
      if (response.status === 200) {
        const { user } = response.data;
        const gdprStatus = await localData("get", "gdpr");
        if (gdprStatus === "accept") {
          user.last_refresh = new Date().toISOString();
          await setUserProfile(user);
          setSession(true);
          setReport({
            show: true,
            message: "Logged in successfully using OTP!",
            type: "success",
          });
        } else {
          await localData("remove", "gdpr");
          localStorage.removeItem("session");
          setReport({
            show: true,
            message: "Accepted, but you must accept GDPR to continue.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "Invalid OTP. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "Unexpected error during OTP login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleAccountCreationSubmit = async (event) => {
    event.preventDefault();
    setShowCreateAccountModal(false);
    setProcessing(true);
    try {
      const response = await createAccount(accountFormData);
      if (response.status === 200) {
        setReport({
          show: true,
          message: response.data.message,
          type: "success",
        });
        setHasAccount(null);
      } else {
        setReport({
          show: true,
          message:
            response.data.message ||
            "An error occurred during account creation.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during account creation.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const getHeaderText = () => {
    if (hasAccount === null) {
      return seoData.title;
    } else if (hasAccount) {
      return `Welcome Back, ${userName}`;
    } else {
      return "Create Account";
    }
  };

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <h1 className="display-4 fw-bold">{getHeaderText()}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-12rem", borderRadius: "1rem" }}
      >
        {loadingSeo ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : errorSeo ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <h2>Error: {errorSeo}</h2>
          </div>
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (hasAccount === null) {
                handleEmailCheck(e);
              } else if (hasAccount) {
                handleLoginSubmit(e);
              } else {
                setShowCreateAccountModal(true);
              }
            }}
            className="mb-2 py-5 px-5"
          >
            <Row className="gy-3 mb-5">
              {/* If user is uncertain (null) or new (false) => two columns */}
              {(hasAccount === null || hasAccount === false) && (
                <>
                  <Col xs={12} md={8} className="d-flex align-items-end">
                    <Form.Group className="w-100">
                      <Form.Label
                        className="mb-1"
                        style={{ fontSize: "1rem", color: "#222" }}
                      >
                        {hasAccount === null
                          ? "What's your email address?"
                          : "Email"}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setAccountFormData((prev) => ({
                            ...prev,
                            companyEmail: e.target.value,
                            userEmail: e.target.value,
                          }));
                        }}
                        required
                        className="mb-0"
                        style={{
                          fontSize: "1rem",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col
                    xs={12}
                    md={4}
                    className="d-flex align-items-end justify-content-center"
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      style={{ borderRadius: "8px" }}
                    >
                      {hasAccount === null ? "Continue" : "Create an Account"}{" "}
                      <FaChevronRight style={{ marginLeft: "1rem" }} />
                    </Button>
                  </Col>
                </>
              )}

              {/* If user has an account => three columns */}
              {hasAccount === true && (
                <>
                  {/* Email Field */}
                  <Col xs={12} md={4} className="d-flex align-items-end">
                    <Form.Group className="w-100">
                      <Form.Label
                        className="mb-1"
                        style={{ fontSize: "1rem", color: "#666" }}
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setAccountFormData((prev) => ({
                            ...prev,
                            companyEmail: e.target.value,
                            userEmail: e.target.value,
                          }));
                        }}
                        required
                        disabled
                        className="mb-0"
                        style={{
                          fontSize: "1rem",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          backgroundColor: "#f8f8f8",
                        }}
                      />
                      {/* "Not you?" link below Email */}
                      <div style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                        <Button
                          variant="link"
                          onClick={() => {
                            setHasAccount(null);
                            setEmail("");
                            setPassword("");
                          }}
                          style={{
                            color: "#007bff",
                            textDecoration: "underline",
                            padding: 0,
                          }}
                        >
                          Not you, {userName}?
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Password Field */}
                  <Col xs={12} md={4} className="d-flex align-items-end">
                    <Form.Group className="w-100">
                      <Form.Label
                        className="mb-1"
                        style={{ fontSize: "1rem", color: "#666" }}
                      >
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="mb-0"
                        style={{
                          fontSize: "1rem",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                      {/* Forgot password link below Password */}
                      <div style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                        <Button
                          variant="link"
                          onClick={() => setShowOtpModal(true)}
                          style={{
                            color: "#007bff",
                            textDecoration: "underline",
                            padding: 0,
                          }}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Login Button */}
                  <Col
                    xs={12}
                    md={4}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      style={{ borderRadius: "8px" }}
                    >
                      Login <FaSignInAlt style={{ marginLeft: "1rem" }} />
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <DomainCarousel domainOffers={[]} />
          </Form>
        )}

        <CreateAccountModal
          show={showCreateAccountModal}
          onHide={() => {
            setShowCreateAccountModal(false);
            setHasAccount(null);
            setEmail("");
            setPassword("");
          }}
          defaultEmail={email}
          accountFormData={accountFormData}
          setAccountFormData={setAccountFormData}
          accountFormPage={accountFormPage}
          setAccountFormPage={setAccountFormPage}
          handleAccountCreationSubmit={handleAccountCreationSubmit}
        />

        <OtpModal
          show={showOtpModal}
          onHide={() => setShowOtpModal(false)}
          otp={otp}
          otpRefs={otpRefs}
          otpKeys={otpKeys}
          handleOtpChange={handleOtpChange}
          handleOtpKeyDown={handleOtpKeyDown}
          handleOtpSubmit={handleOtpSubmit}
          handleRequestOtp={handleRequestOtp}
          otpRequested={otpRequested}
        />
      </Container>
    </>
  );
};

export default GetStarted;
