import { initDB } from "../../config/initDB";

/**
 * IndexedDB settings management.
 *
 * @param {string} action - The action to perform on the settings (e.g., "save", "remove", "get", "update").
 * @param {string} item - The setting key.
 * @param {any} value - The value to save or update.
 * @returns {Promise<any>} The result of the operation.
 */
export async function localData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("settings", "readwrite");
  const store = tx.objectStore("settings");

  switch (action) {
    case "save":
      await store.put({ setting: item, value });
      await tx.done;
      return true;
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        return true;
      }
      return false;
    }
    case "get": {
      const data = await store.get(item);
      return data?.value ?? null;
    }
    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ setting: item, value });
        await tx.done;
        return true;
      }
      return false;
    }
    default:
      return null;
  }
}
