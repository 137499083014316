import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  FormControl,
  Row,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap";
import {
  FaArrowLeft,
  FaSave,
  FaCheckCircle,
  FaExclamationTriangle,
} from "react-icons/fa";
import validator from "validator"; // validator.js for hostname validation
import { runData } from "../functions/api/processor";
import { localData } from "../functions/api/localData";

/**
 * @component
 * @name NameserverEntry
 * @description Step 5 - Collect nameserver information, either by loading from local data
 * (caching default nameservers for up to 5 minutes) or from a previously entered wizard state.
 * Validates authority if needed, allowing the user to proceed once valid.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onSubmit - Callback when the form is submitted.
 * @param {Function} props.onBack - Callback for handling the "Back" button click.
 * @param {String} props.domain - The domain name to check authority against.
 * @returns {JSX.Element} The rendered component.
 */
const NameserverEntry = ({ onSubmit, onBack, domain }) => {
  // ------------------- State -------------------
  const [ns1, setNs1] = useState("");
  const [ns2, setNs2] = useState("");
  const [ns3, setNs3] = useState("");
  const [ns4, setNs4] = useState("");

  const [validationResults, setValidationResults] = useState({});
  const [allValid, setAllValid] = useState(false);
  const [warning, setWarning] = useState("");
  const [isValidating, setIsValidating] = useState(false);

  // ------------------- Effects -------------------

  /**
   * @function useEffect - On mount, we load user wizard data (if any).
   * If the user has not already entered nameservers, we load default nameservers
   * from local cache (if <5 mins old) or fetch new ones.
   */
  useEffect(() => {
    (async () => {
      try {
        // 1. Load existing wizard data
        const wizardData = await localData("get", "nameserverWizard");
        if (wizardData && typeof wizardData === "object") {
          const { ns1, ns2, ns3, ns4 } = wizardData;
          // If the user has already been here, load those
          if (ns1 || ns2 || ns3 || ns4) {
            setNs1(ns1 || "");
            setNs2(ns2 || "");
            setNs3(ns3 || "");
            setNs4(ns4 || "");
            return; // We can stop if we have user data
          }
        }

        // 2. If wizard data doesn't exist or is all blank, fetch cached defaults or from server
        await maybeLoadDefaultNameservers();
      } catch (error) {
        console.error("Error initializing nameservers:", error);
      }
    })();
  }, []);

  /**
   * @function useEffect - Whenever ns1, ns2, ns3, or ns4 changes,
   * we store them in local data so the user won't lose them.
   */
  useEffect(() => {
    (async () => {
      const wizardData = { ns1, ns2, ns3, ns4 };
      await localData("save", "nameserverWizard", wizardData);
    })();
  }, [ns1, ns2, ns3, ns4]);

  // ------------------- Caching Logic for Default Nameservers -------------------

  /**
   * @async
   * @function maybeLoadDefaultNameservers
   * @description Loads default nameservers from localData if they're <5 minutes old,
   * else fetches them from the server, caches them, and sets them in state.
   */
  const maybeLoadDefaultNameservers = async () => {
    try {
      const savedDefaults = await localData("get", "defaultNameservers");
      const savedTimestamp = await localData(
        "get",
        "defaultNameserversTimestamp"
      );
      const now = Date.now();
      const fiveMinutes = 5 * 60 * 1000; // 300,000 ms

      if (
        savedDefaults &&
        typeof savedDefaults === "object" &&
        savedTimestamp &&
        now - savedTimestamp < fiveMinutes
      ) {
        // Use cached defaults
        setNs1((cur) => cur || savedDefaults.ns1 || "");
        setNs2((cur) => cur || savedDefaults.ns2 || "");
        setNs3((cur) => cur || savedDefaults.ns3 || "");
        setNs4((cur) => cur || savedDefaults.ns4 || "");
      } else {
        // Fetch fresh defaults from server
        await fetchDefaultNameservers();
      }
    } catch (error) {
      console.error("Failed to load default nameservers:", error);
    }
  };

  /**
   * @async
   * @function fetchDefaultNameservers
   * @description Fetches default nameservers from server, caches them locally with a timestamp,
   * and sets them in state if the fields are empty.
   */
  const fetchDefaultNameservers = async () => {
    try {
      const fetchResult = await runData(
        {},
        "/web/domains/default-nameservers/"
      );
      // All runData responses have either response or error otherwise it is unknown
      if (fetchResult.status === 200 && fetchResult.data) {
        const { ns1: d1, ns2: d2, ns3: d3, ns4: d4 } = fetchResult.data;
        // Save them in local data
        await localData("save", "defaultNameservers", {
          ns1: d1 || "",
          ns2: d2 || "",
          ns3: d3 || "",
          ns4: d4 || "",
        });
        await localData("save", "defaultNameserversTimestamp", Date.now());

        // Only set them if user hasn't already typed something
        setNs1((current) => current || d1 || "");
        setNs2((current) => current || d2 || "");
        setNs3((current) => current || d3 || "");
        setNs4((current) => current || d4 || "");
      } else {
        console.error(
          `Non-200 status from server while fetching default nameservers: ${fetchResult.status}`
        );
      }
    } catch (error) {
      console.error("Failed to fetch default nameservers:", error);
    }
  };

  // ------------------- Validation and Submission -------------------

  /**
   * @async
   * @function checkAuthority
   * @description Uses runData to check whether a nameserver is authoritative for the specified domain.
   * @param {string} nameserver - The nameserver to validate.
   * @returns {Promise<boolean>} Resolves to true if authoritative, false otherwise.
   */
  const checkAuthority = async (nameserver) => {
    try {
      const requestData = { domain, nameserver };
      const runDataResponse = await runData(
        requestData,
        "/api/domains/check-authority/"
      );
      if (runDataResponse.status === 200) {
        return runDataResponse.data.authoritative;
      } else {
        console.error(
          `Authority check returned non-200 status: ${runDataResponse.status}`
        );
        return false;
      }
    } catch (error) {
      console.error("Failed to check authority:", error);
      return false;
    }
  };

  /**
   * @function resetValidationState
   * @description Resets the validation-related state whenever the user edits a nameserver field.
   */
  const resetValidationState = () => {
    setAllValid(false);
    setValidationResults({});
    setWarning("");
  };

  /**
   * @async
   * @function validateNameservers
   * @description Validates each provided nameserver for correct hostname and authority.
   * Updates the validation results and determines if all nameservers are authoritative.
   * Provides warnings for hostname validation failures or insufficient input.
   */
  const validateNameservers = async () => {
    // Must have at least ns1 and ns2
    if (!ns1 || !ns2) {
      setWarning(
        "Please provide at least two nameservers (Nameserver 1 and Nameserver 2) before validating."
      );
      return;
    }

    // Validate hostnames
    const hostsToCheck = [ns1, ns2, ns3, ns4].filter(Boolean);
    for (const ns of hostsToCheck) {
      if (!validator.isFQDN(ns)) {
        setWarning(
          `The hostname "${ns}" is not valid. Please correct it before validating.`
        );
        return;
      }
    }

    setWarning("");
    setIsValidating(true);

    try {
      let results = {};
      let validCount = 0;

      for (const ns of hostsToCheck) {
        const isAuthoritative = await checkAuthority(ns);
        results[ns] = isAuthoritative;
        if (isAuthoritative) validCount++;
      }

      setValidationResults(results);
      const allAreValid =
        validCount === hostsToCheck.length && hostsToCheck.length > 0;
      setAllValid(allAreValid);
    } finally {
      setIsValidating(false);
    }
  };

  /**
   * @function handleSubmit
   * @description Checks for required fields and valid hostnames, then calls onSubmit with all non-empty nameservers.
   * @param {React.FormEvent} e - The form submission event.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ns1 || !ns2) {
      setWarning("Submission requires at least Nameserver 1 and Nameserver 2.");
      return;
    }

    // Validate hostnames again upon submission
    const hostsToSubmit = [ns1, ns2, ns3, ns4].filter(Boolean);
    for (const ns of hostsToSubmit) {
      if (!validator.isFQDN(ns)) {
        setWarning(
          `The hostname "${ns}" is not valid. Please correct it before submitting.`
        );
        return;
      }
    }

    setWarning("");
    onSubmit(hostsToSubmit);
  };

  // ------------------- Helper for Field Feedback -------------------

  /**
   * @function renderFieldFeedback
   * @description Renders a small inline feedback message under a field if available.
   * @param {string} ns - The nameserver value.
   * @returns {JSX.Element|null} Inline feedback element or null.
   */
  const renderFieldFeedback = (ns) => {
    if (ns && validationResults[ns] === false) {
      return (
        <Form.Text className="text-danger small">
          <FaExclamationTriangle className="me-1" />
          {ns} is not authoritative for {domain}.
        </Form.Text>
      );
    }
    if (ns && validationResults[ns] === true) {
      return (
        <Form.Text className="text-success small">
          <FaCheckCircle className="me-1" />
          {ns} is authoritative for {domain}.
        </Form.Text>
      );
    }
    return null;
  };

  // ------------------- Render -------------------

  return (
    <Form onSubmit={handleSubmit} aria-labelledby="nameserver-entry-heading">
      <p
        id="nameserver-entry-heading"
        className="mb-4"
        style={{ textAlign: "justify" }}
      >
        To register a domain, valid and authoritative nameservers are required.
        If you do not have at least two nameservers, you can use our default
        nameservers which are ns1.marmasco.net and ns2.marmasco.net. You can
        update your nameservers in future using our system.
      </p>

      {warning && (
        <Alert variant="warning" aria-live="assertive">
          {warning}
        </Alert>
      )}

      {/* Nameserver 1 */}
      <Form.Group className="mb-3" controlId="nameserver1">
        <Form.Label>Nameserver 1 (Required)</Form.Label>
        <FormControl
          type="text"
          value={ns1}
          onChange={(e) => {
            resetValidationState();
            setNs1(e.target.value);
          }}
          required
          aria-required="true"
          aria-label="Nameserver 1"
        />
        {renderFieldFeedback(ns1)}
      </Form.Group>

      {/* Nameserver 2 */}
      <Form.Group className="mb-3" controlId="nameserver2">
        <Form.Label>Nameserver 2 (Required)</Form.Label>
        <FormControl
          type="text"
          value={ns2}
          onChange={(e) => {
            resetValidationState();
            setNs2(e.target.value);
          }}
          required
          aria-required="true"
          aria-label="Nameserver 2"
        />
        {renderFieldFeedback(ns2)}
      </Form.Group>

      {/* Nameserver 3 */}
      <Form.Group className="mb-3" controlId="nameserver3">
        <Form.Label>Nameserver 3 (Optional)</Form.Label>
        <FormControl
          type="text"
          value={ns3}
          onChange={(e) => {
            resetValidationState();
            setNs3(e.target.value);
          }}
          aria-label="Nameserver 3"
        />
        {renderFieldFeedback(ns3)}
      </Form.Group>

      {/* Nameserver 4 */}
      <Form.Group className="mb-3" controlId="nameserver4">
        <Form.Label>Nameserver 4 (Optional)</Form.Label>
        <FormControl
          type="text"
          value={ns4}
          onChange={(e) => {
            resetValidationState();
            setNs4(e.target.value);
          }}
          aria-label="Nameserver 4"
        />
        {renderFieldFeedback(ns4)}
      </Form.Group>

      {/* Action buttons */}
      <Row className="mt-5 mb-3">
        <Col>
          <Button
            variant="outline-warning"
            onClick={onBack}
            className="w-100"
            aria-label="Go back"
          >
            <FaArrowLeft aria-hidden="true" className="me-2" />
            Back
          </Button>
        </Col>
        <Col>
          {!allValid ? (
            <Button
              variant="outline-info"
              type="button"
              onClick={validateNameservers}
              className="w-100"
              aria-label="Validate nameservers"
              disabled={isValidating}
            >
              {isValidating ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    className="me-2"
                  >
                    <span className="visually-hidden">Validating...</span>
                  </Spinner>
                  Validating...
                </>
              ) : (
                "Validate Nameservers"
              )}
            </Button>
          ) : (
            <Button
              variant="outline-secondary"
              type="submit"
              className="w-100"
              aria-label="Save nameservers"
            >
              <FaSave aria-hidden="true" className="me-2" />
              Save Nameservers
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default NameserverEntry;
