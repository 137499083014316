/**
 * @file DashboardPage.jsx
 * @description A professionally designed, fully responsive dashboard page
 *              for managing domain and hosting details. Includes:
 *                - Top Up button for mtsuite
 *                - Add Domain / Add Hosting buttons
 *                - Domain extension statistics with a matching color key
 *                - Random color generation for any newly added extension
 *              - Updated to display "Expiring" and "Expired" items in
 *                a grid layout instead of tables.
 * @author
 *   Masimba Maregedze, 1995
 * @version 1.4.0
 */

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Accordion,
} from "react-bootstrap";
import { FaDollarSign, FaGlobe, FaLayerGroup, FaServer } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import SearchDomainModal from "../domains/SearchDomainModal";
import { Link } from "react-router-dom";

// Register the required elements
Chart.register(ArcElement, Tooltip, Legend);

/**
 * @function getBadgeVariant
 * @description Returns the appropriate Bootstrap badge variant for a given status.
 * @param {string} status - The status label (e.g., "Expiring", "Expired").
 * @returns {string} The badge variant to apply.
 */
const getBadgeVariant = (status) => {
  switch (status.toLowerCase()) {
    case "expiring":
      return "warning";
    case "expired":
      return "danger";
    default:
      return "secondary";
  }
};

/**
 * @function getRandomColor
 * @description Generates a random hex color code (e.g., "#A1B2C3").
 * @returns {string} A random hex color code.
 */
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * @function DashboardPage
 * @description Renders a dashboard with domain/hosting data, mtsuite,
 *              domain extension statistics chart, and color swatches serving
 *              as a legend. Includes quick "Top Up" and "Add" actions.
 *              Tables are replaced with grid-box layouts for Expiring/Expired items.
 * @returns {JSX.Element} A fully responsive and production-ready dashboard component.
 */
const DashboardPage = () => {
  // -------------------------------------
  // States
  // -------------------------------------

  /**
   * @constant
   * @type {number}
   * @default
   * @description MTSuite for the user.
   */
  const [balance] = useState(0.01);

  /**
   * @constant
   * @type {Array}
   * @description List of domains that are about to expire.
   */
  const [domains] = useState([]);

  /**
   * @constant
   * @type {Array}
   * @description List of hosting services about to expire.
   */
  const [expiringHosting] = useState([]);

  /**
   * @constant
   * @type {Object}
   * @description Data for the domain extensions chart.
   *              Each extension has a corresponding data value and color.
   */
  const domainStatsData = {
    labels: [],
    datasets: [
      {
        data: [18, 3, 2, 1, 1, 1, 1],
        backgroundColor: [
          "#FF6384", // .com
          "#36A2EB", // .net
          "#FFCE56", // .org
          "#4BC0C0", // .co.uk
          "#9966FF", // .services
          "#FF9F40", // .info
          "#4CAF50", // .dev
        ],
      },
    ],
  };

  /**
   * @constant
   * @type {Object}
   * @description Configuration for the Pie chart. We disable the default legend,
   *              opting to manually display a legend in the card to the right.
   */
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  function getExpirationClass(expirationDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date
    const expiration = new Date(expirationDate);
    expiration.setHours(0, 0, 0, 0); // Normalize expiration date

    if (isNaN(expiration)) return "text-muted"; // Fallback for invalid dates

    // Calculate 1 month from today
    const oneMonthFromNow = new Date(today);
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    if (expiration < today) {
      return "text-danger";
    } else if (expiration <= oneMonthFromNow) {
      return "text-warning";
    }
    return "text-muted";
  }

  // -------------------------------------
  // JSX Return
  // -------------------------------------
  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Dashboard - Marmasco Technologies</title>
      </Helmet>

      {/* Top Information Section */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4 shadow-sm">
            <h4 className="fw-bold">Hello, Marmasco Technologies P/L</h4>

            {/* MTSuites & Stats */}
            <Row className="mb-4 mt-5">
              {/* MTSuite */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaLayerGroup size={30} className="text-success me-3" />
                      <Card.Title className="mb-0">MTSuite</Card.Title>
                    </div>
                    <Link to={`suite/`} size="sm" className="btn btn-outline-primary">
                      Open
                    </Link>
                  </Card.Body>
                </Card>
              </Col>

              {/* Domains */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaGlobe size={30} className="text-primary me-3" />
                      <Card.Title className="mb-0">Domains</Card.Title>
                    </div>
                    <Link
                      to={`domains/`}
                      variant="outline-success"
                      size="sm"
                      className="btn btn-outline-primary"
                    >
                      Open
                    </Link>
                  </Card.Body>
                </Card>
              </Col>

              {/* Hosting Services */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaServer size={30} className="text-warning me-3" />
                      <Card.Title className="mb-0">Hosting</Card.Title>
                    </div>
                    <Link
                      to={`hosting/`}
                      size="sm"
                      className="btn btn-outline-primary"
                    >
                      Open
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Collapsible Sections for Domains & Hosting */}
      <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen flush>
        {/* Expiring Domains */}
        <Accordion.Item eventKey="0" className="mb-4">
          <Accordion.Header className="fw-bold">
            Expiring Domains
          </Accordion.Header>
          <Accordion.Body>
            <Card className="p-3 shadow-sm">
              <h5 className="d-flex justify-content-between align-items-center mb-3">
                <span>Expiring Domains</span>
                <div>
                  <Button variant="link" className="p-0">
                    All Domains
                  </Button>
                </div>
              </h5>

              {/* Modified expiration date display */}
              <div className="grid-wrapper">
                {domains.map((item, index) => (
                  <div className="grid-item" key={`expiring-${index}`}>
                    <FaGlobe size={40} className="mb-4 folder-icon" />
                    <div className="mb-2 text-truncate">
                      <span className="fw-bold">{item.name}</span>
                    </div>
                    <div
                      className={`small ${getExpirationClass(item.expiration)}`}
                    >
                      Expires: {item.expiration}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Accordion.Body>
        </Accordion.Item>

        {/* Expiring Hosting */}
        <Accordion.Item eventKey="2" className="mb-4">
          <Accordion.Header className="fw-bold">
            Expiring Hosting
          </Accordion.Header>
          <Accordion.Body>
            <Card className="p-3 shadow-sm">
              <h5 className="d-flex justify-content-between align-items-center mb-3">
                <span>Expiring Hosting</span>
                <div>
                  <Button variant="link" className="p-0">
                    All Hosting
                  </Button>
                </div>
              </h5>

              {/* Modified expiration date display */}
              <div className="grid-wrapper">
                {expiringHosting.map((item, index) => (
                  <div className="grid-item" key={`hosting-${index}`}>
                    <FaServer size={40} className="mb-4 folder-icon" />
                    <div className="mb-2 text-truncate">
                      <span className="fw-bold">{item.name}</span>
                    </div>
                    <div
                      className={`small ${getExpirationClass(item.expiration)}`}
                    >
                      Expires: {item.expiration}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        {/* Domain Statistics & Chart */}
        <Accordion.Item eventKey="3" className="mb-4">
          <Accordion.Header className="fw-bold">
            Domain Statistics
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {/* Chart Column */}
              <Col md={6}>
                <Card style={{ height: "300px" }} className="p-3 shadow-sm">
                  <Pie data={domainStatsData} options={chartOptions} />
                </Card>
              </Col>

              {/* Extensions (Legend) Column */}
              <Col md={6}>
                <Card
                  className="p-3 shadow-sm"
                  style={{ height: "300px", overflowY: "auto" }}
                >
                  <h5>Extensions</h5>
                  {domainStatsData.labels.map((ext, idx) => {
                    // If there's no color in backgroundColor for this index,
                    // use a random color.
                    const color =
                      domainStatsData.datasets[0].backgroundColor[idx] ||
                      getRandomColor();

                    return (
                      <div key={ext} className="d-flex align-items-center mb-2">
                        {/* Color Swatch for the legend */}
                        <span
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            backgroundColor: color,
                            marginRight: "8px",
                            borderRadius: "2px",
                          }}
                        />
                        <span>{ext}</span>
                        <Badge bg="info" className="ms-auto" text="dark">
                          {domainStatsData.datasets[0].data[idx]}
                        </Badge>
                      </div>
                    );
                  })}
                </Card>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default DashboardPage;
