import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";

const OtpModal = ({ 
  show, 
  onHide, 
  otp, 
  otpRefs, 
  otpKeys, 
  handleOtpChange, 
  handleOtpKeyDown, 
  handleOtpSubmit, 
  handleRequestOtp, 
  otpRequested 
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Request OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "0.9rem", color: "#666", marginBottom: "20px" }}>
          {/* ...existing text... */}
          Enter the OTP sent to your email. You can request a new OTP every 2 minutes.
        </p>
        {!otpRequested ? (
          <div className="text-center">
            <Button variant="primary" onClick={handleRequestOtp} className="w-100">
              Request OTP
            </Button>
          </div>
        ) : (
          <>
            <Form onSubmit={handleOtpSubmit}>
              <Form.Group className="mt-4 mb-5">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
                  <Form.Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                    Enter OTP
                  </Form.Label>
                  <Button style={{ cursor: "pointer", fontSize: "0.85rem" }} onClick={handleRequestOtp}>
                    Request new OTP
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginTop: "10px" }}>
                  {otpKeys.map((key, index) => (
                    <input
                      key={`otp-input-${key}`}
                      type="text"
                      maxLength="1"
                      ref={(ref) => (otpRefs.current[index] = ref)}
                      value={otp[index] || ""}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      style={{
                        width: "40px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        outline: "none",
                        transition: "border-color 0.3s ease",
                      }}
                    />
                  ))}
                </div>
              </Form.Group>
              <div className="d-flex justify-content-between">
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Login with OTP
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

OtpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  otp: PropTypes.array.isRequired,
  otpRefs: PropTypes.object.isRequired,
  otpKeys: PropTypes.array.isRequired,
  handleOtpChange: PropTypes.func.isRequired,
  handleOtpKeyDown: PropTypes.func.isRequired,
  handleOtpSubmit: PropTypes.func.isRequired,
  handleRequestOtp: PropTypes.func.isRequired,
  otpRequested: PropTypes.bool.isRequired,
};

export default OtpModal;
