/**
 * @file HelpPage.jsx
 * @description A professionally designed, fully responsive help page for Marmasco Technologies.
 *              This production-ready page is optimized with full SEO content including meta tags,
 *              canonical links, and Open Graph tags. The page displays a list of help topics with summaries,
 *              featured images, and a "Read More" button for each topic.
 * @author
 *   Masimba Maregedze
 * @version 1.0.1
 */

import React, { useState, useEffect } from "react"; // modified: added useEffect
import {
  Container,
  Row,
  Col,
  Card,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import helpImage from "../assets/bg.jpg";
import { runData } from "../functions/api/processor"; // added runData

/**
 * HelpPage component renders a fully production-ready help page with SEO enhancements.
 *
 * @returns {JSX.Element} The fully rendered help page component.
 */
const HelpPage = () => {
  // Set dynamic SEO state and help topics separately; remove hardcoded topics.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: helpImage,
    canonical_url: window.location.href,
  });
  const [helpTopics, setHelpTopics] = useState([]);
  // New state for loading and error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "help" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) hostname = "www." + hostname;
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
          // Update helpTopics if provided by API
          if (response.data.helpTopics) {
            setHelpTopics(response.data.helpTopics);
          }
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Pagination logic using helpTopics state
  const [activePage, setActivePage] = useState(1);
  const topicsPerPage = 6;
  const totalPages = Math.ceil(helpTopics.length / topicsPerPage);
  const indexOfLastTopic = activePage * topicsPerPage;
  const indexOfFirstTopic = indexOfLastTopic - topicsPerPage;
  const currentTopics = helpTopics.slice(indexOfFirstTopic, indexOfLastTopic);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  // Render loading spinner if data is still being fetched.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Render error message if data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Main Content Section */}
      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Section Header */}
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Latest Posts</h2>
            <p className="lead text-muted">Discover help topics and FAQs</p>
          </Col>
        </Row>

        {/* Help Topics Listing */}
        {currentTopics.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px", width: "100%" }}
          >
            <p>No posts found</p>
          </div>
        ) : (
          <Row>
            {currentTopics.map((topic) => (
              <Col
                key={topic.id}
                md={4}
                className="d-flex align-items-stretch mb-4"
                style={{ padding: "0 15px" }}
              >
                <Card
                  className="shadow-sm w-100"
                  style={{ marginBottom: "30px" }}
                >
                  <Card.Img variant="top" src={topic.image} alt={topic.title} />
                  <Card.Body className="d-flex flex-column p-3">
                    <Card.Title>{topic.title}</Card.Title>
                    <Card.Text>{topic.summary}</Card.Text>
                    <div className="mt-auto">
                      <Link
                        to={`/help/${topic.slug}/`}
                        className="btn btn-primary w-100"
                      >
                        Read More
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        {/* Pagination: Render only if there's more than one page */}
        {totalPages > 1 && (
          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev
                  onClick={() =>
                    activePage > 1 && handlePageChange(activePage - 1)
                  }
                  disabled={activePage === 1}
                />
                {renderPaginationItems()}
                <Pagination.Next
                  onClick={() =>
                    activePage < totalPages && handlePageChange(activePage + 1)
                  }
                  disabled={activePage === totalPages}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default HelpPage;
