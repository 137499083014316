import { initDB } from "../../config/initDB";

/**
 * Shopping cart management.
 *
 * @param {string} action - The action to perform on the shopping cart (e.g., "save", "remove", "get", "list", "check", "update", "increase", "decrease").
 * @param {any} item - The reference or identifier of the item.
 * @param {Object} value - Additional data for the operation.
 * @returns {Promise<any>} The result of the cart operation.
 */
export async function cartData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("shopping", "readwrite");
  const store = tx.objectStore("shopping");

  switch (action) {
    case "save":
      await store.put({ reference: item, ...value });
      await tx.done;
      window.dispatchEvent(new Event("cartChange"));
      return true;
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "get":
      return await store.getAll();
    case "list": // Alias for "get" to improve semantic clarity.
      return await store.getAll();
    case "check":
      return (await store.get(item)) ?? false;
    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ reference: item, ...value });
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "increase": {
      const existingItem = await store.get(item);
      if (existingItem) {
        existingItem.quantity += value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "decrease": {
      const existingItem = await store.get(item);
      if (existingItem?.quantity > 1) {
        existingItem.quantity -= value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    default:
      return false;
  }
}
