import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import domainImage from "../assets/domains.jpg";
import { runData } from "../functions/api/processor";
import { FaSearch } from "react-icons/fa";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import DomainCarousel from "../components/DomainCourosel";
import DomainOrder from "../order/DomainOrder";

/**
 * DomainsPage Component
 *
 * Renders the domains page where users can search for, register, transfer,
 * or configure domains. The page fetches SEO data and available domain offers,
 * and conditionally renders the domain order form based on the search results.
 *
 * @returns {JSX.Element} Rendered domains page component.
 */
const DomainsPage = () => {
  // State for domain search options and values.
  const [domainOption, setDomainOption] = useState("register");
  const [domainName, setDomainName] = useState("");
  const [domainChecked, setDomainChecked] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  // SEO data state with fallback defaults.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: domainImage,
    canonical_url: window.location.href,
  });
  // States for handling SEO data loading and errors.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetch SEO data upon component mount.
   * SEO data is used for metadata and page presentation
   */
  useEffect(() => {
    (async () => {
      try {
        // Retrieve SEO data and available domain extensions.
        const response = await runData({ page: "domains" }, "/web/pages/");

        // Validate response and update state.
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;

          // Adjust canonical URL if needed.
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  /**
   * Handles the domain search form submission.
   * Validates input, calls the domain check API, and processes the response.
   *
   * @param {Event} e - The form submission event.
   */
  const handleDomainSearch = async (e) => {
    e.preventDefault();
    const trimmedDomain = domainName.trim();

    // Ensure a valid domain name is provided.
    if (!trimmedDomain) {
      setReport({
        show: true,
        message:
          "Please enter a valid domain name with the appropriate extension (e.g., .com, .org, .co.zw).",
        type: "error",
      });
      return;
    }

    setProcessing(true);
    setDomainDetails(null);

    try {
      // Send search request for the specified domain.
      const response = await runData(
        { domain: trimmedDomain, action: domainOption },
        "/web/domains/check/"
      );
      if (response.status === 200 && response.data.domain) {
        const data = response.data;
        setDomainName(data.domain);

        // Evaluate response based on selected domain option.
        if (domainOption === "register" && data.status !== "available") {
          setReport({
            show: true,
            message: `The domain "${data.domain}" is not available for registration. If you wish to transfer it, please select the "Transfer Domain" option.`,
            type: "warning",
          });
        } else if (
          domainOption === "transfer" &&
          data.status !== "registered"
        ) {
          setReport({
            show: true,
            message: `The domain "${data.domain}" is not registered. To transfer a domain, it must be registered elsewhere. If you wish to register it, please select the appropriate option.`,
            type: "warning",
          });
        } else if (
          domainOption === "external" &&
          data.status !== "registered"
        ) {
          setReport({
            show: true,
            message: `The domain "${data.domain}" does not appear to be registered. External domains must be registered before configuration.`,
            type: "warning",
          });
        } else if (data.error) {
          setReport({
            show: true,
            message: `Error processing your request: ${data.error}. Please verify the domain and try again.`,
            type: "error",
          });
        } else {
          // Successful domain check; provide feedback and update state.
          const actionMessage =
            domainOption === "register"
              ? "registration"
              : domainOption === "transfer"
              ? "transfer"
              : "configuration";
          setReport({
            show: true,
            message: `Congratulations! The domain "${data.domain}" is available for ${actionMessage}. Please complete the owner’s details to proceed.`,
            type: "success",
          });
          setDomainDetails(data);
          setDomainChecked(true);
        }
      }
    } catch (err) {
      let errorMessage =
        "An unexpected error occurred. Please try again later. If the problem persists, contact support.";
      if (err instanceof Error) {
        errorMessage += ` (${err.message})`;
      }
      setReport({
        show: true,
        message: errorMessage,
        type: "error",
      });
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  // Set the heading text based on the selected domain option.
  let headingText = "Submit Domain";
  if (domainOption === "register") {
    headingText = "Register Domain";
  } else if (domainOption === "transfer") {
    headingText = "Transfer Domain";
  } else if (domainOption === "external") {
    headingText = "Configure Domain";
  }

  // Display a loading spinner while SEO data is being fetched.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Display an error message if SEO data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      {/* SEO and Social Meta Tags */}
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "${seoData.title} - Marmasco Web Hosting",
              "description": "${seoData.meta_description}",
              "offers": {
                "@type": "AggregateOffer",
                "lowPrice": "10.00",
                "highPrice": "1000.00",
                "priceCurrency": "USD",
                "offerCount": "1000"
              }
            }
          `}
        </script>
      </Helmet>

      {/* Hero Section */}
      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Domain Order or Search Section */}
      {domainChecked && domainDetails ? (
        // Render the domain order form if a domain check has been completed.
        <Container
          id="content-start"
          className="bg-white py-4 fade-in mb-2 rounded-3 shadow-lg"
          style={{ marginTop: "-18rem", borderRadius: "1rem" }}
        >
          <Row className="px-4 py-4">
            <DomainOrder
              data={domainDetails}
              action={domainOption}
              onDismiss={() => setDomainChecked(false)}
            />
          </Row>
        </Container>
      ) : (
        // Render the domain search form.
        <Container
          id="content-start"
          className="bg-white py-4 fade-in mb-2 rounded-3 shadow-lg"
          style={{ marginTop: "-18rem", borderRadius: "1rem" }}
        >
          <Row className="mb-5">
            <Col className="text-center py-4 px-4">
              <h2 className="section-title">{headingText}</h2>
              <p className="lead text-muted">
                Find your perfect domain! You can register a new domain, connect
                an existing one, or transfer your domain to us. Pricing details
                will be displayed after your search.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center px-4 py-3 mt-5">
            <Form onSubmit={handleDomainSearch} className="domain-search-form">
              <div
                className="shadow-sm mb-3 card border-0 bg-light"
                style={{
                  height: "150px",
                  borderRadius: "0.375rem",
                  overflow: "hidden",
                  padding: "15px",
                }}
              >
                <InputGroup size="lg" className="h-100 align-items-center">
                  {/* Domain option selector */}
                  <Form.Select
                    value={domainOption}
                    onChange={(e) => {
                      setDomainOption(e.target.value);
                      setDomainName("");
                      setDomainDetails(null);
                      setDomainChecked(false);
                    }}
                    aria-label="Select domain option"
                    style={{
                      maxWidth: "200px",
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      flexShrink: 0,
                      padding: "0.5rem 0.75rem",
                    }}
                    className="me-0"
                  >
                    <option value="register">Register</option>
                    <option value="transfer">Transfer</option>
                    <option value="external">External</option>
                  </Form.Select>
                  {/* Domain name input field */}
                  <Form.Control
                    type="text"
                    placeholder="Enter domain name"
                    value={domainName}
                    onChange={(e) => {
                      setDomainName(e.target.value);
                      setDomainChecked(false);
                      setDomainDetails(null);
                    }}
                    aria-label="Domain Name"
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#eee",
                      flex: 1,
                      padding: "0.5rem 0.75rem",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  {/* Search button */}
                  <Button
                    type="submit"
                    variant="secondary"
                    aria-label="Search Domain"
                    style={{
                      border: "none",
                      boxShadow: "none",
                      padding: "0.5rem 1rem",
                      borderRadius: "10px",
                    }}
                    className="px-4"
                  >
                    <FaSearch className="me-2" /> Search
                  </Button>
                </InputGroup>
              </div>
              {/* Domain offers carousel */}
              <DomainCarousel />
            </Form>
          </Row>
        </Container>
      )}
    </>
  );
};

export default DomainsPage;
