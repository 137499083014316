/**
 * @file TermsPage.jsx
 * @description A professionally designed, fully responsive dashboard page for managing domain and hosting details.
 *              Includes a Terms of Service section detailing our policies.
 * @author
 *   Masimba Maregedze
 * @version 1.4.1
 */

import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import featImg from "../assets/bg.jpg";
import { runData } from "../functions/api/processor";

const TermsPage = () => {
  // Dynamic SEO state with fallback values.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  // State to manage loading and error during data fetching.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "terms" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) hostname = "www." + hostname;
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Display an animated spinner while data is loading.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Display an error message if data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Terms of Service Heading */}
        <Row className="mb-5">
          <Col className="text-center">
            <h5 className="section-title">Last Updated: 01 January 2025</h5>
            <p className="lead text-muted">
              These Terms of Service govern your use of our services and may be
              updated periodically.
            </p>
          </Col>
        </Row>

        {/* Terms of Service Content */}
        <Row>
          <Col xs={12} className="mb-3">
            <h5>1. Introduction</h5>
            <p>
              These Terms of Service ("Terms") govern your use of the Marmasco
              Technologies website and services ("Services"). By accessing or
              using our Services, you agree to comply with these Terms.
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>2. Services</h5>
            <p>
              Marmasco Technologies provides web hosting, domain registration,
              and related services. We reserve the right to modify or
              discontinue any part of our Services at any time.
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>3. User Responsibilities</h5>
            <ul>
              <li>
                You agree to use our Services in compliance with all applicable
                laws and regulations.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account information and for all activities that occur under your
                account.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>4. Payment and Billing</h5>
            <ul>
              <li>
                You agree to pay all fees associated with the Services you
                purchase.
              </li>
              <li>All payments are non-refundable unless otherwise stated.</li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>5. Intellectual Property</h5>
            <ul>
              <li>
                All content and materials on our website are the intellectual
                property of Marmasco Technologies or its licensors.
              </li>
              <li>
                You may not use, reproduce, or distribute any content without
                our prior written permission.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>6. Limitation of Liability</h5>
            <ul>
              <li>
                Marmasco Technologies is not liable for any indirect,
                incidental, or consequential damages arising from your use of
                our Services.
              </li>
              <li>
                Our total liability to you for any claims arising from your use
                of our Services is limited to the amount you paid us in the past
                12 months.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>7. Termination</h5>
            <ul>
              <li>
                We may terminate or suspend your access to our Services at any
                time, without prior notice or liability, for any reason,
                including if you breach these Terms.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>8. Changes to Terms</h5>
            <ul>
              <li>
                We may update these Terms from time to time. We will notify you
                of any changes by posting the new Terms on our website. Your
                continued use of our Services after any changes constitutes your
                acceptance of the new Terms.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>9. Governing Law</h5>
            <ul>
              <li>
                These Terms are governed by and construed in accordance with the
                laws of Zimbabwe, without regard to its conflict of law
                principles.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>10. Contact Information</h5>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at:
            </p>
            <p>
              <strong>Email:</strong> support@marmasco.com
            </p>
            <p>
              <strong>Address:</strong> 15992 Unit P, Seke, Chitungwiza,
              Zimbabwe
            </p>
            <p>
              <strong>Phone:</strong> +263 78 605 8024
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TermsPage;
