import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

const SearchModal = ({ show, handleClose, initialQuery }) => {
  // Local state for query, category, loading status, and results
  const [query, setQuery] = useState(initialQuery || "");
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);

  /**
   * Handles the search form submission.
   * Simulates an API call using the query and category.
   * Replace simulation with actual API integration as needed.
   * @param {React.FormEvent} [e] - The form event, optional for programmatic calls.
   */
  const handleSearch = async (e) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    try {
      console.log("Searching with:", { query, category });
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Simulate received results
      const simulatedResults = [
        { id: 1, title: "Result 1", description: "Description for result 1" },
        { id: 2, title: "Result 2", description: "Description for result 2" },
        { id: 3, title: "Result 3", description: "Description for result 3" },
      ];

      setResults(simulatedResults);
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show && initialQuery) {
      setQuery(initialQuery);
      // Automatically trigger search upon modal open if there's an initial query
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, initialQuery]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Search System</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Form Section */}
        <Row className="justify-content-center mt-5">
          <Form onSubmit={handleSearch} style={{ width: "100%" }}>
            <Row className="align-items-end">
              {/* Search Query Input */}
              <Col xs={12} md={5} className="mb-3 mb-md-0">
                <Form.Group controlId="searchQuery">
                  <Form.Label>Search Query</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter keywords..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    aria-label="Search Query"
                  />
                </Form.Group>
              </Col>

              {/* Category Selection */}
              <Col xs={12} md={4} className="mb-3 mb-md-0">
                <Form.Group controlId="searchCategory">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    aria-label="Select Category"
                  >
                    <option value="">All Categories</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              {/* Search Button */}
              <Col xs={12} md={3}>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={isLoading}
                >
                  <FaSearch className="me-2" />
                  {isLoading ? "Searching..." : "Search"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>

        {/* Results Area */}
        <Row className="justify-content-center mt-4">
          <Col xs={12}>
            <h5>Search Results</h5>
            {results.length === 0 ? (
              <p>No results found. Try different keywords or categories.</p>
            ) : (
              <ListGroup>
                {results.map((result) => (
                  <ListGroup.Item key={result.id}>
                    <h6>{result.title}</h6>
                    <p>{result.description}</p>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

SearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialQuery: PropTypes.string,
};

export default SearchModal;
