import { openDB, deleteDB } from "idb";

// Centralized function to initialize the database
export const initDB = async () => {
  return await openDB("Marmasco", 2, {
    // This version number should be manually incremented for schema changes
    upgrade(db) {
      console.log("Initializing database with all object stores");

      // Create initial stores
      db.createObjectStore("settings", { keyPath: "setting" });
      db.createObjectStore("profile", { keyPath: "id" });
      db.createObjectStore("shopping", { keyPath: "reference" });
    },
  });
};

// Function to delete the entire database
export const destroyDB = async () => {
  await deleteDB("Marmasco");
  console.log('Database "Marmasco" deleted successfully');
};

// Function to delete a specific table (object store) from the database
export const deleteTable = async (storeName) => {
  const db = await openDB("Marmasco", 1);
  const version = db.version + 1;
  db.close();

  // Upgrade the database to delete the specific object store
  await openDB("Marmasco", version, {
    upgrade(upgradedDb) {
      if (upgradedDb.objectStoreNames.contains(storeName)) {
        upgradedDb.deleteObjectStore(storeName);
        console.log(`Object store "${storeName}" deleted successfully`);
      } else {
        console.log(`Object store "${storeName}" does not exist`);
      }
    },
  });
};
