// src/context/ThemeContext.js

import React, { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { localData } from "../functions/api/localData";

/**
 * ThemeContext provides the current theme and a function to toggle the theme.
 */
export const ThemeContext = createContext();

/**
 * ThemeProvider component that provides theme-related context to its children.
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The child components that will receive the theme context.
 * @returns {JSX.Element}
 */
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light-theme"); // Default to 'light-theme' initially

  useEffect(() => {
    // Asynchronously fetch the theme from localData
    const fetchTheme = async () => {
      try {
        const savedTheme = await localData("get", "theme");
        if (savedTheme) {
          setTheme(savedTheme);
        }
      } catch (error) {
        console.error("Failed to fetch theme from local storage:", error);
      }
    };

    fetchTheme();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "light-theme") {
      root.classList.add("light-theme");
      root.classList.remove("dark-theme");
    } else {
      root.classList.add("dark-theme");
      root.classList.remove("light-theme");
    }
  }, [theme]);

  /**
   * Toggles the current theme between 'light-theme' and 'dark-theme'.
   */
  const toggleTheme = async () => {
    const newTheme = theme === "light-theme" ? "dark-theme" : "light-theme";
    setTheme(newTheme);
    try {
      await localData("save", "theme", newTheme);
    } catch (error) {
      console.error("Failed to save theme to local storage:", error);
    }
  };

  const value = useMemo(() => ({ theme, toggleTheme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
