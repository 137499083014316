/**
 * @file PrivacyPage.jsx
 * @description A professionally designed, fully responsive dashboard page for managing domain and hosting details.
 *              Includes a Privacy Policy section detailing our policies.
 * @author
 *   Masimba Maregedze
 * @version 1.4.1
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import featImg from "../assets/bg.jpg";
import { runData } from "../functions/api/processor";

const PrivacyPage = () => {
  // Dynamic SEO state with fallback values.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  // State to manage loading and error during data fetching.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "privacy" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) hostname = "www." + hostname;
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Display an animated spinner while data is loading.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Display an error message if data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Privacy Policy Heading */}
        <Row className="mb-5">
          <Col className="text-center">
            <h5 className="section-title">Last Updated: 01 January 2025</h5>
            <p className="lead text-muted">
              This Privacy Policy explains how we collect, use, and share your
              personal data.
            </p>
          </Col>
        </Row>

        {/* Privacy Policy Content */}
        <Row>
          <Col xs={12} className="mb-3">
            <h5>1. Introduction</h5>
            <p>
              Marmasco Technologies (“we,” “us,” or “our”) respects your privacy
              and is committed to protecting your personal information. This
              Privacy Policy explains how we collect, use, and share your
              personal data when you interact with our services in accordance
              with the General Data Protection Regulation (GDPR).
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>2. Data We Collect</h5>
            <ul>
              <li>
                <strong>Personal Information:</strong> This may include your
                name, email address, phone number, and similar details when you
                register or use our services.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect information on how you
                navigate our website or platform, such as IP addresses, browser
                types, and activity logs.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>3. How We Use Your Data</h5>
            <ul>
              <li>
                <strong>Service Provision:</strong> We process your personal
                data to operate and maintain our core offerings, including
                domain registration, web hosting, and DNS management.
              </li>
              <li>
                <strong>Communication:</strong> We may send you emails or
                messages about your account, important updates, and technical
                notices.
              </li>
              <li>
                <strong>Marketing (If Applicable):</strong> We may send you
                promotional messages if you consent to receive them. You can opt
                out at any time.
              </li>
            </ul>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>4. Data Sharing</h5>
            <p>
              We may share your data with trusted third-party providers who
              assist in delivering our services (such as payment processors or
              hosting partners). These entities are obligated to comply with
              GDPR and protect your data.
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>5. Data Retention &amp; Security</h5>
            <p>
              We retain personal data only as long as necessary for legitimate
              business or legal purposes. We implement security measures,
              including encryption and access restrictions, to safeguard your
              data against unauthorized access, alteration, or disclosure.
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>6. Your Rights Under GDPR</h5>
            <ul>
              <li>
                You have the right to access the personal data we hold about
                you.
              </li>
              <li>You can request corrections or deletions.</li>
              <li>
                You may object to or restrict our processing of your data.
              </li>
              <li>You have the right to port your data to another service.</li>
            </ul>
            <p>For assistance, please contact us using the details below.</p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>7. Updates to This Policy</h5>
            <p>
              We may update this Privacy Policy to reflect changes in our
              practices or to comply with legal obligations. Updated versions
              will be posted on our website.
            </p>
          </Col>
          <Col xs={12} className="mb-3">
            <h5>8. Contact Information</h5>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <p>
              <strong>Email:</strong> support@marmasco.com
            </p>
            <p>
              <strong>Address:</strong> 15992 Unit P, Seke, Chitungwiza,
              Zimbabwe
            </p>
            <p>
              <strong>Phone:</strong> +263 78 605 8024
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPage;
