export default function notifyProfileChanged(changeDetails = {}) {
  const event = new CustomEvent("profileChanged", {
    detail: {
      message: "The profile has been updated",
      ...changeDetails,
      timestamp: Date.now(),
    },
  });
  window.dispatchEvent(event);
}
