/**
 * @file BillingPage.jsx
 * @description A React component that allows customers to manage their account balance and billing settings, download invoices, and make deposits.
 */

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Modal,
  Form,
  Badge,
  Alert,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { FaAngleLeft, FaDownload, FaEdit, FaWallet } from "react-icons/fa";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

/**
 * @component
 * @name BillingPage
 * @description Provides functionalities for customers to manage account balance, billing settings, download invoices, and make deposits.
 * @returns {JSX.Element}
 */
const BillingPage = () => {
  // --------------------------------------------------------------------------
  // Sample Data
  // --------------------------------------------------------------------------

  /**
   * Sample invoices to mimic API response.
   */
  const sampleInvoices = [
    {
      id: 1,
      date: "2025-01-15",
      amount: 99.99,
      status: "Paid",
      invoiceUrl: "#", // Replace with actual URL or path
    },
    {
      id: 2,
      date: "2024-12-15",
      amount: 89.99,
      status: "Paid",
      invoiceUrl: "#",
    },
    {
      id: 3,
      date: "2024-11-15",
      amount: 109.99,
      status: "Unpaid",
      invoiceUrl: "#",
    },
    // Add more sample invoices as needed
  ];

  /**
   * Sample billing information to mimic API response.
   */
  const sampleBillingInfo = {
    paymentMethod: "Visa **** 4242",
    billingAddress: "123 Main St, Springfield, IL 62704",
  };

  // --------------------------------------------------------------------------
  // State Management
  // --------------------------------------------------------------------------

  /**
   * Holds the current account balance.
   */
  const [balance, setBalance] = useState(250.0);

  /**
   * Holds billing information.
   */
  const [billingInfo, setBillingInfo] = useState(sampleBillingInfo);

  /**
   * Holds the list of invoices.
   */
  const [invoices, setInvoices] = useState(sampleInvoices);

  /**
   * Controls the visibility of the "Edit Billing Info" modal.
   */
  const [showEditBillingModal, setShowEditBillingModal] = useState(false);

  /**
   * Controls the visibility of the "Deposit Funds" modal.
   */
  const [showDepositModal, setShowDepositModal] = useState(false);

  /**
   * Holds form input values for editing billing information.
   */
  const [editBillingInfo, setEditBillingInfo] = useState(billingInfo);

  /**
   * Holds the deposit amount entered by the user.
   */
  const [depositAmount, setDepositAmount] = useState("");

  /**
   * Indicates whether an operation is in progress.
   */
  const [isProcessing, setIsProcessing] = useState(false);

  /**
   * Holds any error messages.
   */
  const [error, setError] = useState(null);

  /**
   * Holds success messages.
   */
  const [success, setSuccess] = useState(null);

  // --------------------------------------------------------------------------
  // Handler Functions
  // --------------------------------------------------------------------------

  /**
   * Opens the "Edit Billing Info" modal.
   */
  const handleOpenEditBillingModal = () => {
    setEditBillingInfo(billingInfo);
    setShowEditBillingModal(true);
  };

  /**
   * Closes the "Edit Billing Info" modal.
   */
  const handleCloseEditBillingModal = () => {
    setShowEditBillingModal(false);
    setError(null);
    setSuccess(null);
  };

  /**
   * Handles changes in the billing information form.
   * @param {Event} e - The input change event.
   */
  const handleBillingInfoChange = (e) => {
    const { name, value } = e.target;
    setEditBillingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  /**
   * Submits the updated billing information.
   * @param {Event} e - The form submission event.
   */
  const handleSubmitBillingInfo = (e) => {
    e.preventDefault();

    // Basic validation
    if (!editBillingInfo.paymentMethod || !editBillingInfo.billingAddress) {
      setError("All fields are required.");
      return;
    }

    setIsProcessing(true);
    setError(null);
    setSuccess(null);

    // Simulate API call with delay
    setTimeout(() => {
      setBillingInfo(editBillingInfo);
      setIsProcessing(false);
      setSuccess("Billing information updated successfully.");
    }, 1500);
  };

  /**
   * Opens the "Deposit Funds" modal.
   */
  const handleOpenDepositModal = () => {
    setDepositAmount("");
    setShowDepositModal(true);
  };

  /**
   * Closes the "Deposit Funds" modal.
   */
  const handleCloseDepositModal = () => {
    setShowDepositModal(false);
    setError(null);
    setSuccess(null);
  };

  /**
   * Handles changes in the deposit amount input.
   * @param {Event} e - The input change event.
   */
  const handleDepositAmountChange = (e) => {
    setDepositAmount(e.target.value);
  };

  /**
   * Submits the deposit funds.
   * @param {Event} e - The form submission event.
   */
  const handleSubmitDeposit = (e) => {
    e.preventDefault();

    const amount = parseFloat(depositAmount);

    // Validation
    if (isNaN(amount) || amount <= 0) {
      setError("Please enter a valid amount greater than zero.");
      return;
    }

    setIsProcessing(true);
    setError(null);
    setSuccess(null);

    // Simulate API call with delay
    setTimeout(() => {
      setBalance((prevBalance) => prevBalance + amount);
      setIsProcessing(false);
      setSuccess(`Successfully deposited $${amount.toFixed(2)}.`);
      setShowDepositModal(false);
    }, 1500);
  };

  /**
   * Downloads the invoice as a PDF.
   * @param {string} invoiceUrl - The URL of the invoice.
   */
  const handleDownloadInvoice = (invoiceUrl) => {
    // In a real application, you would use the actual invoice URL.
    // For demonstration, we'll simulate a download.
    alert("Invoice download functionality is not implemented in this demo.");
  };

  // --------------------------------------------------------------------------
  // Utility Functions
  // --------------------------------------------------------------------------

  /**
   * Formats a number as currency.
   * @param {number} amount - The amount to format.
   * @returns {string} - Formatted currency string.
   */
  const formatCurrency = (amount) => {
    return `$${amount.toFixed(2)}`;
  };

  // --------------------------------------------------------------------------
  // Rendering Helpers
  // --------------------------------------------------------------------------

  /**
   * Renders the account balance card.
   * @returns {JSX.Element}
   */
  const renderBalanceCard = () => {
    return (
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col xs={12} md={8}>
              <Card.Title>Account Balance</Card.Title>
              <Card.Text style={{ fontSize: "2rem", fontWeight: "bold" }}>
                {formatCurrency(balance)}
              </Card.Text>
            </Col>
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
              <Button variant="success" onClick={handleOpenDepositModal}>
                <FaWallet className="me-2" />
                Make a Deposit
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  /**
   * Renders the billing settings card.
   * @returns {JSX.Element}
   */
  const renderBillingSettingsCard = () => {
    return (
      <Card className="mb-4">
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Card.Title>Billing Settings</Card.Title>
            </Col>
            <Col className="text-end">
              <Button variant="outline-primary" size="sm" onClick={handleOpenEditBillingModal}>
                <FaEdit className="me-1" />
                Edit
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <strong>Payment Method:</strong>
              <p>{billingInfo.paymentMethod}</p>
            </Col>
            <Col md={6}>
              <strong>Billing Address:</strong>
              <p>{billingInfo.billingAddress}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  /**
   * Renders the invoices table.
   * @returns {JSX.Element}
   */
  const renderInvoicesTable = () => {
    return (
      <Card className="mb-4">
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Card.Title>Invoices</Card.Title>
            </Col>
          </Row>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <tr key={invoice.id}>
                  <td>{invoice.id}</td>
                  <td>{invoice.date}</td>
                  <td>{formatCurrency(invoice.amount)}</td>
                  <td>
                    <Badge bg={invoice.status === "Paid" ? "success" : "warning"}>
                      {invoice.status}
                    </Badge>
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleDownloadInvoice(invoice.invoiceUrl)}
                    >
                      <FaDownload className="me-1" />
                      Download
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  /**
   * Renders success and error alerts.
   * @returns {JSX.Element|null}
   */
  const renderAlerts = () => {
    return (
      <>
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
            {success}
          </Alert>
        )}
      </>
    );
  };

  /**
   * Renders the "Edit Billing Info" modal.
   * @returns {JSX.Element}
   */
  const renderEditBillingModal = () => {
    return (
      <Modal show={showEditBillingModal} onHide={handleCloseEditBillingModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Billing Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderAlerts()}
          <Form onSubmit={handleSubmitBillingInfo}>
            <Form.Group controlId="paymentMethod" className="mb-3">
              <Form.Label>Payment Method</Form.Label>
              <Form.Control
                type="text"
                name="paymentMethod"
                value={editBillingInfo.paymentMethod}
                onChange={handleBillingInfoChange}
                placeholder="e.g., Visa **** 4242"
                required
              />
            </Form.Group>
            <Form.Group controlId="billingAddress" className="mb-3">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="billingAddress"
                value={editBillingInfo.billingAddress}
                onChange={handleBillingInfoChange}
                placeholder="Enter your billing address"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isProcessing}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Saving...
                </>
              ) : (
                "Save Changes"
              )}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditBillingModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /**
   * Renders the "Deposit Funds" modal.
   * @returns {JSX.Element}
   */
  const renderDepositModal = () => {
    return (
      <Modal show={showDepositModal} onHide={handleCloseDepositModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Make a Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderAlerts()}
          <Form onSubmit={handleSubmitDeposit}>
            <Form.Group controlId="depositAmount" className="mb-3">
              <Form.Label>Amount (USD)</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                min="0.01"
                value={depositAmount}
                onChange={handleDepositAmountChange}
                placeholder="Enter amount to deposit"
                required
              />
            </Form.Group>
            <Button variant="success" type="submit" disabled={isProcessing}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Processing...
                </>
              ) : (
                "Deposit Funds"
              )}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDepositModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // --------------------------------------------------------------------------
  // Main Render
  // --------------------------------------------------------------------------

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Billing Management - Marmasco Technologies</title>
      </Helmet>

      {/* Back Button and Title */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <NavLink
            title="Back"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px green",
              marginRight: "10px",
            }}
            to={`/`}
          >
            <FaAngleLeft
              style={{ marginRight: "0.5rem" }}
              className="text-primary"
            />{" "}
            Back
          </NavLink>
          <Col>
          <h2>
            Billing Management <FaWallet className="ms-2" />
          </h2>
          <p>Manage your account balance, billing settings, and invoices.</p>
        </Col>
        </div>
      </div>
      <hr />

      {/* Alerts */}
      {renderAlerts()}

      {/* Account Balance */}
      {renderBalanceCard()}

      {/* Billing Settings */}
      {renderBillingSettingsCard()}

      {/* Invoices */}
      {renderInvoicesTable()}

      {/* Edit Billing Info Modal */}
      {renderEditBillingModal()}

      {/* Deposit Funds Modal */}
      {renderDepositModal()}
    </Container>
  );
};

/**
 * @component
 * @name PaginationComponent
 * @description Renders pagination controls.
 * @param {Object} props - The component props.
 * @param {number} props.currentPage - The current active page.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.onPageChange - The function to call when page changes.
 * @returns {JSX.Element}
 */
const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const paginationItems = [];

  // Determine the range of page numbers to display
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > 5) {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  for (let number = startPage; number <= endPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="justify-content-center">
      <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {startPage > 1 && <Pagination.Ellipsis disabled />}
      {paginationItems}
      {endPage < totalPages && <Pagination.Ellipsis disabled />}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );
};

// Adding PropTypes validations to PaginationComponent
PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default BillingPage;
