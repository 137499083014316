import React from "react";
import { Button, Row, Col, Alert } from "react-bootstrap";
import { FaArrowLeft, FaCreditCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // For navigation
import { runData } from "../functions/api/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { localData } from "../functions/api/localData";

/**
 * @component
 * @name OrderSubmission
 * @description Step 6 - Finalize the order submission process and proceed to place a domain order.
 *
 * @param {Object} props - The component properties.
 * @param {string} props.domainInput - The domain name input (e.g., "example").
 * @param {string} props.extension - The domain extension (e.g., ".com").
 * @param {Object} props.searchResult - An object containing { status: "available"|"taken", price, etc. }
 * @param {Object} props.profileData - Registrant data. If it contains .reference, it's an existing profile; otherwise new.
 * @param {string[]} props.nameservers - An array of nameserver addresses (e.g., ["ns1.domain.com", "ns2.domain.com"]).
 * @param {Function} props.onBack - Callback to go back one step in the wizard.
 * @param {Function} props.onCloseModal - Callback to close the domain registration modal.
 * @returns {JSX.Element} A React component that allows users to finalize their domain order.
 */
const OrderSubmission = ({
  domainInput,
  extension,
  searchResult,
  profileData,
  nameservers,
  onBack,
  onCloseModal,
}) => {
  const navigate = useNavigate();
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  /**
   * @async
   * @function handleOrder
   * @description Gathers all data and attempts to place the domain order via runData.
   * On success, removes wizard data from local storage, closes the modal, shows a success message,
   * then navigates to "/billing/" after 3 seconds.
   */
  const handleOrder = async () => {
    setProcessing(true);
    try {
      // Build final domain (e.g., "example.com")
      const domain = `${domainInput}${extension}`;

      // Determine domain action based on availability
      const action =
        searchResult?.status === "available" ? "registration" : "transfer";

      // Build profile portion
      let profileType = "new";
      if (profileData?.reference) {
        profileType = "existing";
      }

      // Combine into final request object
      const requestData = {
        domain,
        action, // "registration" or "transfer"
        nameservers,
        profileType,
        ...profileData, // Spread the properties of profileData into requestData
      };

      // Submit order
      const response = await runData(requestData, "/api/domains/order/");
console.log(response)
      if (response.status === 200 || response.status === 201) {
        // Remove all wizard data from local storage
        await clearAllLocalWizardData();

        // Close the modal right away
        onCloseModal();

        // Show a success message
        setReport({
          show: true,
          type: "success",
          message: "Order placed successfully!",
        });

        // Delay 3 seconds before navigating to /billing/
        setTimeout(() => {
          navigate("/billing/");
        }, 3000);
      } else {
        // Non-200 => error
        setReport({
          show: true,
          type: "error",
          message:
            response.data?.message ||
            "An unexpected error occurred during order submission.",
        });
      }
    } catch (error) {
      console.log(error)
      setReport({
        show: true,
        type: "error",
        message:
          error?.message ||
          "An unexpected error occurred during order submission.",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function clearAllLocalWizardData
   * @description Removes the local data for the domain wizard, profiles, nameservers, etc.
   */
  const clearAllLocalWizardData = async () => {
    // Nameserver wizard data
    await localData("remove", "nameserverWizard");
    await localData("remove", "defaultNameservers");
    await localData("remove", "defaultNameserversTimestamp");

    // Profile wizard data
    await localData("remove", "profileWizard");
    await localData("remove", "profiles");
    await localData("remove", "profilesTimestamp");

    // Domain search data
    await localData("remove", "lastSearchedDomain");
    await localData("remove", "extensions");
    await localData("remove", "extensionsTimestamp");

    // If you have other keys (e.g., "lastSearchStatus"), remove them too:
    // await localData("remove", "lastSearchStatus");
  };

  return (
    <section aria-labelledby="order-submission-heading" className="p-3">
      <h2 id="order-submission-heading" className="sr-only">
        Order Submission
      </h2>

      <div className="mb-5" style={{ textAlign: "justify" }}>
        <p>
          You are about to place a new domain order for{" "}
          <strong>
            {domainInput}
            {extension}
          </strong>
          .{" "}
          {searchResult?.status === "available" ? (
            <>
              Once you submit this order, our system will attempt to register
              this domain on your behalf.
            </>
          ) : (
            <>
              This domain appears to be already registered. By submitting this
              order, you are requesting to transfer the domain to Marmasco.
              Please contact your current registrar to initiate the release or
              unlock of the domain. You may also be required to provide an EPP
              code or authorization key for a successful transfer.
            </>
          )}
        </p>
        <p>
          Upon submission, a new invoice will be generated, and you will be
          redirected to the invoice payment page. Please finalize payment
          promptly to complete your order. Your domain will be configured with
          the following nameservers by default:{" "}
          <strong>
            {nameservers && nameservers.length > 0
              ? nameservers.join(", ")
              : "No nameservers provided"}
          </strong>
          . You can update these at any time in the future using our system.
        </p>
      </div>

      <Row className="mt-5" xs={1} sm={2}>
        <Col className="mb-3 mb-sm-0">
          <Button
            type="button"
            variant="outline-warning"
            onClick={onBack}
            className="w-100"
            aria-label="Go back to the previous step"
          >
            <FaArrowLeft aria-hidden="true" className="me-2" />
            Back
          </Button>
        </Col>
        <Col>
          <Button
            type="button"
            variant="outline-secondary"
            onClick={handleOrder}
            className="w-100"
            aria-label="Proceed to place your domain order"
          >
            <FaCreditCard aria-hidden="true" className="me-2" />
            Place Order
          </Button>
        </Col>
      </Row>
    </section>
  );
};

export default OrderSubmission;
