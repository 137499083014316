/**
 * @file ServerModal.jsx
 * @description Encapsulates the server ordering process within a modal.
 * The order form includes:
 * - A mandatory image selection field (filtered by server architecture),
 * - A mandatory hostname field with validation,
 * - A billing cycle select (Monthly/Yearly) that computes the total due.
 * A note informs the user that further configuration will be available after activation.
 * The final subscription consists of: hostname, location (from pricing),
 * selected image (image.name), billing cycle, and total due.
 * @version 1.5.25
 * @author
 * Masimba Maregedze
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../functions/api/processor";
import { useReport } from "../context/ReportModal";
import { isAuthenticated } from "../utils/auth";
import { cartData } from "../functions/api/cartData";

// Helper: Basic hostname validation regex.
const validateHostname = (hostname) => {
  const hostnameRegex =
    /^(?=.{1,253}$)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*$/;
  return hostnameRegex.test(hostname);
};

const ServerModal = ({ server, selectedLocation }) => {
  const { setProcessing, processing } = useProcessing();
  const { setReport, report } = useReport();

  // Local state for modal form fields.
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [hostname, setHostname] = useState("");
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const [totalDue, setTotalDue] = useState(0);
  const [hostnameError, setHostnameError] = useState("");
  const authenticated = isAuthenticated();

  /**
   * Fetch images from the API and open the modal.
   */
  const handleOpenModal = async () => {
    setProcessing(true);
    try {
      const response = await runData({}, "/web/cloud/images/");
      if (
        response.status === 200 &&
        response.data &&
        Array.isArray(response.data.images) &&
        response.data.images.length > 0
      ) {
        setImages(response.data.images);
        setShowModal(true);
      } else {
        setReport({
          show: true,
          message: "No images found, cannot proceed with the order process.",
          type: "info",
        });
      }
    } catch (error) {
      setReport({
        show: true,
        message:
          error.message ||
          "An unexpected error occurred while fetching images.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Resets the modal form fields and closes the modal.
   */
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage([]);
    setHostname("");
    setBillingCycle("Monthly");
    setHostnameError("");
  };

  /**
   * Updates the total due based on the selected billing cycle using pricing info
   * from the currently selected location.
   */
  const updateTotalDue = () => {
    // Use the current server's groupedPrices for the selected location.
    const pricingGroup =
      server.groupedPrices && server.groupedPrices[selectedLocation];
    const pricingArray = pricingGroup && pricingGroup.prices;
    const priceInfo =
      pricingArray && pricingArray.length > 0
        ? pricingArray[0]
        : { price_monthly: { gross: 0 } };
    const basePrice = parseFloat(priceInfo.price_monthly.gross) || 0;
    setTotalDue(billingCycle === "Monthly" ? basePrice : basePrice * 12);
  };

  // When the server prop changes, update pricing.
  useEffect(() => {
    setSelectedImage([]);
    setHostname("");
    setBillingCycle("Monthly");
    setHostnameError("");
    updateTotalDue();
  }, [server, selectedLocation]); // include selectedLocation as well

  // Recalculate total due when billing cycle changes.
  useEffect(() => {
    updateTotalDue();
  }, [billingCycle]);

  /**
   * Handles form submission.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleCloseModal();
    setProcessing(true);
    try {
      if (!hostname || !validateHostname(hostname)) {
        setHostnameError(
          "Please enter a valid hostname (e.g. cloud.example.com)."
        );
        return;
      }
      if (selectedImage.length === 0) return;

      const order = {
        product: "hosting",
        name: hostname,
        serverType: server.name,
        location: selectedLocation,
        image: selectedImage[0]?.name,
        cycle: billingCycle,
        price: totalDue.toFixed(2),
        currency: "USD",
        offer: server,
      };

      const response = await runData(
        order,
        `/${authenticated ? "api" : "web"}/orders/save/`
      );
      if (response.status === 200) {
        await cartData("save", response.data.reference, order);
        setReport({
          show: true,
          message:
            "Your order has been saved successfully! Redirecting to checkout...",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/checkout/";
        }, 3000);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Order submission failed.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "Order submission encountered an error. Please try again later.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Filter images based on server architecture.
  const filteredImages = images.filter(
    (image) => image.architecture === server.architecture
  );

  return (
    <>
      <div className="mt-auto">
        <Button
          variant="outline-primary"
          className="btn w-100"
          onClick={handleOpenModal}
        >
          <FaShoppingCart /> Buy
        </Button>
      </div>

      {!processing && !report.show && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {server?.name
                ? `Buy ${server.name.toUpperCase()} Server`
                : "Buy Server"}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="pt-4">
              {/* Hostname Field */}
              <Form.Group controlId="hostname" className="mt-3 mb-4">
                <Form.Label>Enter Server Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name (e.g. cloud.example.com)"
                  value={hostname}
                  onChange={(e) => {
                    setHostname(e.target.value);
                    if (hostnameError) setHostnameError("");
                  }}
                  required
                />
                {hostnameError && (
                  <Form.Text className="text-danger">{hostnameError}</Form.Text>
                )}
              </Form.Group>
              {/* Image Selection Field */}
              <Form.Group controlId="imageSelection">
                <Form.Label>Select Server Image</Form.Label>
                <Typeahead
                  id="image-typeahead"
                  labelKey="friendlyTitle"
                  options={filteredImages}
                  placeholder="Select server image..."
                  renderMenuItemChildren={(option) => (
                    <div>{option.friendlyTitle}</div>
                  )}
                  onChange={(selected) => setSelectedImage(selected)}
                  selected={selectedImage}
                />
              </Form.Group>

              {/* Billing Cycle Selection */}
              <Form.Group controlId="billingCycle" className="mt-3">
                <Form.Label>Choose Billing Cycle</Form.Label>
                <Form.Select
                  value={billingCycle}
                  onChange={(e) => setBillingCycle(e.target.value)}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </Form.Select>
              </Form.Group>

              {/* Total Due Display */}
              <Form.Group controlId="totalDue" className="mt-3">
                <Form.Label>Server's {billingCycle} Price</Form.Label>
                <Form.Control
                  type="text"
                  value={`$${totalDue.toFixed(2)}`}
                  readOnly
                  disabled
                />
              </Form.Group>

              {/* Informational Note */}
              <Form.Group className="mt-3">
                <Form.Text className="text-muted">
                  You will be able to further configure your server after
                  activation.
                </Form.Text>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="outline-danger" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="secondary"
                disabled={!hostname || selectedImage.length === 0}
              >
                Submit Order
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

ServerModal.propTypes = {
  /**
   * Server details required for ordering.
   *
   * @property {string} name - The server name.
   * @property {string} architecture - The server architecture (e.g., "x86", "arm").
   * @property {object} groupedPrices - An object where each key is a location system code and its value is an object containing:
   * - title: The friendly location title.
   * - prices: An array of pricing objects (first element used for ordering).
   * @property {string} description - The server description.
   * @property {number|string} cores - The number of CPU cores.
   * @property {string} cpuType - The CPU type.
   * @property {number|string} memory - The memory size in GB.
   * @property {number|string} disk - The SSD disk capacity in GB.
   */
  server: PropTypes.shape({
    name: PropTypes.string.isRequired,
    architecture: PropTypes.string.isRequired,
    groupedPrices: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    cores: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    cpuType: PropTypes.string.isRequired,
    memory: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    disk: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  selectedLocation: PropTypes.string.isRequired,
};

export default ServerModal;
