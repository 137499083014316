/**
 * @file OrderForm.jsx
 * @description A modern and responsive order form for collecting domain name,
 *              number of users, billing cycle, and displaying the calculated price.
 *              Includes validation for a valid domain/hostname (excluding localhost).
 * @author
 *   Masimba Maregedze
 * @version 1.1.2
 */

import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import { runData } from "../functions/api/processor";
import { cartData } from "../functions/api/cartData";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { isAuthenticated } from "../utils/auth";

/**
 * @function isValidDomain
 * @description Checks whether the provided domain is valid.
 *              It does NOT allow "localhost" as a valid hostname.
 * @param {string} domain - The domain or hostname to validate.
 * @returns {boolean} True if valid, false otherwise.
 */
const isValidDomain = (domain) => {
  if (domain.toLowerCase() === "localhost") return false;
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
};

/**
 * @function OrderForm
 * @description Renders a modern and responsive order form for collecting domain name,
 *              number of users, and billing cycle. The form data is submitted using runData.
 *              It calculates the total price based on the selected number of users and billing cycle.
 *              The submit button remains disabled until all fields are valid.
 * @returns {JSX.Element} A production-ready order form component.
 */
const OrderForm = () => {
  // State for order form values.
  const [order, setOrder] = useState({
    product: "suite",
    domainName: "",
    numberOfUsers: 1,
    billingCycle: "monthly",
  });

  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const authenticated = isAuthenticated();

  // State for pricing details.
  const [basePrice, setBasePrice] = useState(5); // Default price for 1 user per month.
  const [currency, setCurrency] = useState("USD");
  const [totalPrice, setTotalPrice] = useState(5);

  // State for input validation errors.
  const [inputError, setInputError] = useState("");
  const [domainError, setDomainError] = useState("");

  // State to control form submission.
  const [isSubmitting, setIsSubmitting] = useState(false);

  // useRef to track if component is mounted.
  const isMountedRef = useRef(true);

  // Cleanup effect to avoid state updates on unmounted component.
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  /**
   * @function handleChange
   * @description Handles changes for the order form inputs.
   *              Validates "Number of Users" to ensure digits only and not less than 1.
   *              Validates "Domain Name" for a non-empty, valid domain (excluding localhost).
   * @param {object} e - The event object from the form input.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "numberOfUsers") {
      // Validate to allow only digits.
      if (!/^\d*$/.test(value)) {
        setInputError("Please enter digits only.");
        return;
      }
      const numValue = parseInt(value, 10);
      if (isNaN(numValue) || numValue < 1) {
        setInputError("Number of users must be at least 1.");
        return;
      }
      setInputError("");
      setOrder((prevOrder) => ({
        ...prevOrder,
        [name]: numValue,
      }));
    } else if (name === "domainName") {
      setOrder((prevOrder) => ({
        ...prevOrder,
        [name]: value,
      }));
      const trimmedValue = value.trim();
      if (trimmedValue === "") {
        setDomainError("Domain name cannot be empty.");
      } else if (trimmedValue.toLowerCase() === "localhost") {
        setDomainError("Hosting 'localhost' is not allowed.");
      } else if (!isValidDomain(trimmedValue)) {
        setDomainError("Please enter a valid domain.");
      } else {
        setDomainError("");
      }
    } else {
      setOrder((prevOrder) => ({
        ...prevOrder,
        [name]: value,
      }));
    }
  };

  /**
   * @function handleSubmit
   * @description Submits the order form details using the runData function.
   *              Validates that the domain is valid and the number of users is at least 1.
   * @param {object} e - The event object from form submission.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setProcessing(true);

    const trimmedDomain = order.domainName.trim();

    // Validate domain name.
    if (trimmedDomain === "" || !isValidDomain(trimmedDomain)) {
      setReport({
        show: true,
        message: "Please enter a valid domain.",
        type: "error",
      });
      setProcessing(false);
      setIsSubmitting(false);
      return;
    }

    // Validate number of users.
    if (order.numberOfUsers < 1) {
      setReport({
        show: true,
        message: "Number of users must be at least 1.",
        type: "error",
      });
      setProcessing(false);
      setIsSubmitting(false);
      return;
    }

    try {
      order.price = totalPrice;
      order.offer = { price: basePrice, currency: currency };
      const response = await runData(
        order,
        `/${authenticated ? "api" : "web"}/orders/save/`
      );
      if (response.status === 200) {
        await cartData("save", response.data.reference, order);
        setReport({
          show: true,
          message:
            "Your order has been saved successfully! Redirecting to checkout...",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/checkout/";
        }, 3000);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Order submission failed.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "Order submission encountered an error. Please try again later.",
        type: "error",
      });
    }
    setProcessing(false);
    setIsSubmitting(false);
  };

  /**
   * @function fetchPricing
   * @description Fetches pricing details from the API.
   *              If an error occurs, logs the error and continues using the default price.
   */
  const fetchPricing = async () => {
    try {
      const responseSuite = await runData({}, "/web/suite/price/");
      if (
        responseSuite.status === 200 &&
        responseSuite.data &&
        responseSuite.data.price &&
        responseSuite.data.currency
      ) {
        if (isMountedRef.current) {
          setBasePrice(responseSuite.data.price);
          setCurrency(responseSuite.data.currency);
        }
      } else {
        throw new Error("Invalid suite data received from API.");
      }
    } catch (err) {
      // Log error and continue with default pricing.
      console.error("Failed to fetch price:", err);
    }
  };

  // Fetch pricing details on component mount.
  useEffect(() => {
    fetchPricing();
  }, []);

  // Calculate total price when numberOfUsers, billingCycle, or basePrice changes.
  useEffect(() => {
    const multiplier = order.billingCycle === "yearly" ? 12 : 1;
    setTotalPrice(order.numberOfUsers * basePrice * multiplier);
  }, [order.numberOfUsers, order.billingCycle, basePrice]);

  // Determine if the form is valid.
  const isFormValid =
    order.domainName.trim() !== "" &&
    domainError === "" &&
    inputError === "" &&
    order.numberOfUsers >= 1;

  return (
    <Card className="p-4 my-4 shadow-sm">
      <h3 className="mb-3">
        Place Order
        <span className="float-end">
          {currency} {totalPrice.toFixed(2)}
        </span>
      </h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="domainName">
          <Form.Label>Domain Name</Form.Label>
          <Form.Control
            type="text"
            name="domainName"
            placeholder="Enter domain name (e.g. example.com)"
            value={order.domainName}
            onChange={handleChange}
            required
          />
          {domainError && (
            <Form.Text className="text-danger">{domainError}</Form.Text>
          )}
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="numberOfUsers">
              <Form.Label>Number of Users</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="numberOfUsers"
                  min="1"
                  value={order.numberOfUsers}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
              {inputError && (
                <Form.Text className="text-danger">{inputError}</Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="billingCycle">
              <Form.Label>Billing Cycle</Form.Label>
              <Form.Select
                name="billingCycle"
                value={order.billingCycle}
                onChange={handleChange}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="submit"
          disabled={!isFormValid || isSubmitting}
          className="w-100"
        >
          Submit Order
        </Button>
      </Form>
    </Card>
  );
};

export default OrderForm;
