/**
 * @file SuitePage.jsx
 * @description A fully responsive suite page for Marmasco Technologies,
 *              showcasing our business management solution with core features
 *              such as unlimited invoicing, customer management, and hosting services.
 *              The page includes a dynamic SEO section, feature highlights, a call-to-action,
 *              and an integrated modern order form.
 * @author
 *   Masimba Maregedze
 * @version 1.5.6
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  FaEnvelope,
  FaLink,
  FaCloudUploadAlt,
  FaUsers,
  FaFileInvoice,
  FaCheckCircle,
} from "react-icons/fa";
import featImg from "../assets/bg.jpg";
import { runData } from "../functions/api/processor"; // API function to submit data
import OrderForm from "../order/SuiteOrder";

/**
 * @function SuitePage
 * @description Renders a professional Suite Us page detailing our company background,
 *              core services, commitment to quality in web hosting and domain registration,
 *              along with an integrated order form for quick service signup.
 * @returns {JSX.Element} A production-ready Suite Us page component.
 */
const SuitePage = () => {
  // Dynamic SEO state using API fallback
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  // State to manage loading and error status during SEO data fetching.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "suite" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Display an animated loading spinner while SEO data is being fetched.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Display an error message if SEO data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      {/* Header Section */}
      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.85), rgba(0,0,0,0.85)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Main Content Section */}
      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Section Header with Title and Lead Text */}
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Improve Business Efficiency</h2>
            <p className="lead text-muted">
              Explore our comprehensive business management solution,
              meticulously designed to centralize your workflows and enhance
              operational control through a diverse suite of integrated
              features.
            </p>
          </Col>
        </Row>

        <Row className="align-items-center">
          {/* Textual Content Section */}
          <Col md={6} className="d-flex align-items-start px-5">
            <div>
              <p className="mb-3" style={{ textAlign: "justify" }}>
                Built for the demands of the modern enterprise, our integrated
                suite delivers the robust capabilities you need to innovate,
                scale, and stay ahead.{" "}
              </p>
              <ul
                className="list-unstyled text-muted mt-4 mb-4"
                style={{ paddingLeft: "50px" }}
              >
                <li className="d-flex align-items-center mb-2">
                  {/* Icon and Text on the left */}
                  <FaFileInvoice size={20} className="me-2" />
                  <span>Create & Manage Invoices</span>
                  {/* Green Checkmark pushed to the far right */}
                  <FaCheckCircle size={20} className="ms-auto text-primary" />
                </li>
                <li className="d-flex align-items-center mb-2">
                  <FaUsers size={20} className="me-2" />
                  <span>Create & Manage Clients</span>
                  <FaCheckCircle size={20} className="ms-auto text-primary" />
                </li>
                <li className="d-flex align-items-center mb-2">
                  <FaEnvelope size={20} className="me-2" />
                  <span>Email & Website Hosting</span>
                  <FaCheckCircle size={20} className="ms-auto text-primary" />
                </li>
                <li className="d-flex align-items-center mb-2">
                  <FaCloudUploadAlt size={20} className="me-2" />
                  <span>Create & Manage Documents</span>
                  <FaCheckCircle size={20} className="ms-auto text-primary" />
                </li>
              </ul>
              <p className="mt-3" style={{ textAlign: "justify" }}>
                Enjoy the flexibility of managing multiple domains and crafting
                distinct sites for each, ensuring that your team remains
                equipped for success in today’s competitive digital landscape.
              </p>
            </div>
          </Col>

          {/* Promotional Image, Order Button, and Order Form Section */}
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center px-5"
          >
            {/* Render the OrderForm component */}
            <OrderForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SuitePage;
