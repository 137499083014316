/**
 * @file GridSection.jsx
 * @description A React component for displaying a grid of hosting along with pagination controls.
 *              Each hosting item is clickable and indicates its expiry status via color coding.
 * @param {Object} props - The component props.
 * @param {Object[]} props.currentHosting - The list of hosting to display on the current page.
 * @param {Object} props.selectedItem - The currently selected hosting.
 * @param {Function} props.setSelectedItem - Function to update the selected hosting.
 * @param {Function} props.getExpiryClass - Function to determine the CSS class based on expiry date.
 * @param {number} props.totalPages - The total number of pagination pages.
 * @param {number} props.currentPage - The current active page number.
 * @param {Function} props.setCurrentPage - Function to update the current page.
 *
 * @author
 *   Masimba Maregedze, 1995
 */

import React from "react";
import { Pagination } from "react-bootstrap";
import { FaCalendarAlt, FaServer } from "react-icons/fa";
import SearchHostingModal from "./StartHosting";

const GridSection = ({
  currentHosting,
  selectedItem,
  setSelectedItem,
  getExpiryClass,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  /**
   * @function handleHostingClick
   * @description Handles selection of a hosting item when clicked.
   * @param {Object} hosting - The hosting item to be selected.
   */
  const handleHostingClick = (hosting) => {
    setSelectedItem(hosting);
  };

  /**
   * @function handleKeyDown
   * @description Handles keyboard interaction for hosting items.
   * @param {KeyboardEvent} e - The keyboard event.
   * @param {Object} hosting - The hosting item to be selected if "Enter" is pressed.
   */
  const handleKeyDown = (e, hosting) => {
    if (e.key === "Enter") {
      setSelectedItem(hosting);
    }
  };

  /**
   * @function formatDate
   * @description Formats a date string to a human-readable format (DD MMM YYYY).
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date.
   */
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  /**
   * @function determineStatus
   * @description Determines the display status for a hosting based on its status code and expiry date.
   * @param {number} statusCode - The numeric status code (1=active/expired, 2=pending, 0=deleted).
   * @param {string} expiryDate - The expiry date string.
   * @returns {string} - The textual representation of the status.
   */
  const determineStatus = (statusCode, expiryDate) => {
    const now = new Date();
    const expireDateObj = new Date(expiryDate);

    // If status is 1, check if expired
    if (statusCode === 1) {
      return expireDateObj > now ? "Active" : "Expired";
    }
    // If status is 2, it's pending
    if (statusCode === 2) {
      return "Pending";
    }
    // If status is 0, it's deleted
    if (statusCode === 0) {
      return "Deleted";
    }

    // Fallback if status code not recognized
    return "Unknown";
  };

  /**
   * @function goToPreviousPage
   * @description Navigates to the previous page if available.
   */
  const goToPreviousPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  /**
   * @function goToNextPage
   * @description Navigates to the next page if available.
   */
  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  /**
   * @function goToSpecificPage
   * @description Navigates to a specific page.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const goToSpecificPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Header row */}
      <div className="row mb-4 mt-5 align-items-center fw-bold">
        <div className="col-md-3 mb-2 mb-md-0">Name</div>
        <div className="col-md-2 mb-2 mb-md-0">Type</div>
        <div className="col-md-2 mb-2 mb-md-0">Created</div>
        <div className="col-md-3 mb-2 mb-md-0">Expiry</div>
        <div className="col-md-2 mb-2 mb-md-0">Status</div>
      </div>

      {/* Hosting items */}
      <div className="row">
        <div className="col-12">
          {currentHosting && currentHosting.length > 0 ? (
            currentHosting.map((hosting) => (
              <div
                key={hosting.reference}
                className={`card shadow-sm mb-3 p-3 ${
                  selectedItem?.reference === hosting.reference
                    ? "highlight"
                    : ""
                }`}
                onClick={() => handleHostingClick(hosting)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, hosting)}
              >
                <div className="row align-items-center">
                  <div className="col-md-3 mb-2 mb-md-0 text-truncate">
                    <FaServer
                      className={`${getExpiryClass(hosting.expiry)} me-2`}
                    />
                    {hosting.name}
                  </div>
                  <div className="col-md-2 mb-2 mb-md-0">
                    {hosting.typeOfService || "Shared Hosting"}
                  </div>
                  <div className="col-md-2 mb-2 mb-md-0">
                    <FaCalendarAlt className="text-primary me-2" />
                    {formatDate(hosting.registered)}
                  </div>
                  <div className="col-md-3 mb-2 mb-md-0">
                    <FaCalendarAlt className="text-primary me-2" />
                    <span className="me-2">{formatDate(hosting.expiry)}</span>
                  </div>
                  <div
                    className={`${getExpiryClass(
                      hosting.expiry
                    )} col-md-2 mb-2 mb-md-0`}
                  >
                    {/* Display the computed status */}
                    {determineStatus(hosting.status, hosting.expiry)}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="card">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6">
                  <div className="text-center p-4">
                    <p className="mb-5">Nothing Found!</p>
                    <SearchHostingModal />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination className="justify-content-center my-4">
          <Pagination.Prev
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, idx) => {
            const pageNumber = idx + 1;
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === currentPage}
                onClick={() => goToSpecificPage(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
};

export default GridSection;
