import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Create a context for managing report state
const ReportContext = createContext();
const MySwal = withReactContent(Swal);

/**
 * ReportProvider component to provide the report context to its children.
 * This component uses SweetAlert2 for displaying alerts and manages report state.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will receive the context.
 * @returns {JSX.Element} The provider component.
 */
export function ReportProvider({ children }) {
  // State to manage report details
  const [report, setReport] = useState({ show: false, message: "", type: "info" });

  // Function to close the modal by resetting the report state
  const closeModal = () => {
    setReport({ show: false, message: "", type: "" });
  };

  // Effect to show SweetAlert2 when report state changes
  useEffect(() => {
    if (report.show && report.message) {
      MySwal.fire({
        title: capitalizeFirstLetter(report.type),
        text: report.message,
        icon: report.type,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: `btn btn-${getBootstrapClass(report.type)}`
        },
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      }).then(() => {
        closeModal();
      });
    }
  }, [report]);

  /**
   * Function to capitalize the first letter of a string.
   *
   * @param {string} string - The input string.
   * @returns {string} The string with the first letter capitalized.
   */
  const capitalizeFirstLetter = (string) => {
    return typeof string === 'string' && string.length > 0
      ? string.charAt(0).toUpperCase() + string.slice(1)
      : '';
  };

  /**
   * Function to get Bootstrap button class based on report type.
   *
   * @param {string} type - The report type.
   * @returns {string} The Bootstrap button class.
   */
  const getBootstrapClass = (type) => {
    switch (type) {
      case 'success':
        return 'success';
      case 'error':
      case 'danger':
        return 'danger';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      case 'question':
        return 'primary';
      default:
        console.warn(`Unexpected report type: ${type}`);
        return 'secondary';
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ report, setReport }), [report]);

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
}

// PropTypes for ReportProvider to ensure children are provided
ReportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Custom hook to use the report context.
 *
 * @returns {object} The report context value.
 */
export function useReport() {
  return useContext(ReportContext);
}
