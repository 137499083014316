/**
 * @file HomePage.jsx
 * @description A professionally designed, fully responsive home page for Marmasco Technologies.
 *              Features include a hero section with a background image and overlay, service highlights for
 *              Domain Registration and Cloud VPS Hosting, and a "Why Choose Us" section emphasizing our
 *              reliability, security, and instant deployment with no setup fees.
 *              This version includes an animated loading spinner until SEO data is ready and an error screen if data loading fails.
 * @author
 *   Masimba Maregedze
 * @version 1.5.13
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import bgImage from "../assets/bg.jpg";
import hostingImage from "../assets/hosting.jpg";
import suitImage from "../assets/suite.png";
import domainImage from "../assets/domains.jpg";
import { runData } from "../functions/api/processor";
import DomainCarousel from "../components/DomainCourosel";

// Define a common style for card images to ensure equal sizing and proper spacing
const cardImageStyle = {
  height: "300px", // Fixed height for equal image display
  objectFit: "cover", // Ensures the image covers the container uniformly
  padding: "10px", // Adds spacing so the image doesn't touch the card edges
  borderRadius: "20px",
};

// Define styles for the service card hover effect
const serviceCardStyle = {
  position: "relative",
  overflow: "hidden",
};

const serviceCardOverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: "opacity 0.3s ease",
};

/**
 * HomePage component renders the landing page with multiple sections optimized for SEO and user engagement.
 *
 * @returns {JSX.Element} The fully rendered home page component, an animated loading screen, or an error screen based on API response.
 */
const HomePage = () => {
  // State for SEO data, loading status, and error handling.
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: bgImage,
    canonical_url: window.location.href,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "home" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
        setLoading(false);
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
        setLoading(false);
      }
    })();
  }, []);

  // Render an animated loading spinner while SEO data is being fetched.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Render an error screen if the SEO data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  // Once SEO data is loaded successfully, render the full page.
  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      {/* Header Section */}
      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Main Content Section */}
      <Container
        id="content-start"
        className="bg-white py-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Section Header */}
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Industry-Leading Solutions</h2>
            <p className="lead text-muted">
              Explore our expert digital services specializing in professional
              domain registration, cloud VPS hosting, and comprehensive business
              solutions with MTSuite. Enjoy instant setup, zero setup fees, and
              high-performance solutions designed to power your online presence.
            </p>
          </Col>
        </Row>

        {/* Services Cards */}
        <Row className="px-3 py-3">
          {/* Cloud VPS Hosting Service Card */}
          <Col md={4} className="mb-4 mb-md-0">
            <Card
              className="shadow-md h-100 border-0 bg-light"
              style={serviceCardStyle}
            >
              <Card.Img
                variant="top"
                src={hostingImage}
                alt="Cloud VPS Hosting"
                style={cardImageStyle}
              />
              <div
                className="service-card-overlay"
                style={serviceCardOverlayStyle}
              >
                <Link to="/hosting/" className="btn btn-primary">
                  Cloud Web Hosting
                </Link>
              </div>
              <Card.Body className="d-flex flex-column">
                <Card.Title>Cloud VPS Hosting</Card.Title>
                <Card.Text>
                  Our enterprise-grade cloud VPS hosting delivers exceptional
                  performance, reliability, and security. With a 99.9% uptime
                  guarantee, rapid deployment, advanced monitoring, and 24/7
                  support, your digital projects are in capable hands.
                </Card.Text>
                <Button as={Link} to="/hosting/" className="mt-auto">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Domain Registration Service Card */}
          <Col md={4}>
            <Card
              className="shadow-md h-100 border-0 bg-light"
              style={serviceCardStyle}
            >
              <Card.Img
                variant="top"
                src={domainImage}
                alt="Domain Registration Service"
                style={cardImageStyle}
              />
              <div
                className="service-card-overlay"
                style={serviceCardOverlayStyle}
              >
                <Link to="/domains/" className="btn btn-primary">
                  Domain Registration
                </Link>
              </div>
              <Card.Body className="d-flex flex-column">
                <Card.Title>Domain Registration</Card.Title>
                <Card.Text>
                  Secure your online identity with our comprehensive domain
                  registration services. Benefit from a streamlined process,
                  competitive pricing, proactive monitoring, and expert guidance
                  to select the perfect domain for your business.
                </Card.Text>
                <Button as={Link} to="/domains/" className="mt-auto">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* MTSuite Service Card */}
          <Col md={4}>
            <Card
              className="shadow-md h-100 border-0 bg-light"
              style={serviceCardStyle}
            >
              <Card.Img
                variant="top"
                src={suitImage}
                alt="MTSuite Business Solutions"
                style={cardImageStyle}
              />
              <div
                className="service-card-overlay"
                style={serviceCardOverlayStyle}
              >
                <Link to="/suite/" className="btn btn-primary">
                  MTSuite Solutions
                </Link>
              </div>
              <Card.Body className="d-flex flex-column">
                <Card.Title>MTSuite: Business Solutions</Card.Title>
                <Card.Text>
                  MTSuite is an all-in-one business management suite designed to
                  streamline your operations. Our platform offers robust invoice
                  and sales management, efficient document handling, and
                  reliable website and email hosting.
                </Card.Text>
                <Button as={Link} to="/suite/" className="mt-auto">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Information Section */}
        <Row className="mt-5 px-4">
          <DomainCarousel />
        </Row>
      </Container>

      {/* Add CSS for hover effect */}
      <style>
        {`
          .service-card-overlay:hover {
            opacity: 1 !important;
          }
          .service-card-overlay {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
        `}
      </style>
    </>
  );
};

export default HomePage;
