/**
 * @file SearchDomainModal.jsx
 * @description Encapsulates the entire domain addition process within a modal, guiding the user through multiple steps.
 *              The launch button is now configurable via props for size and text, enhancing adaptability in different contexts.
 *              It fetches available domain extensions from the server (or local storage) and proceeds through multiple steps based on user input.
 * @author
 *   Masimba Maregedze
 * @version 1.5.7
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useProcessing } from "../context/ProcessingModal";
import { localData } from "../functions/api/localData";
import { useReport } from "../context/ReportModal";
import DomainSearchForm from "./DomainSearchForm";
import DomainResultStep from "./DomainResultStep";
import ProfileSelection from "./ProfileSelection";
import NameserverEntry from "./NameserverEntry";
import OrderSubmission from "./OrderSubmission";
import { runData } from "../functions/api/processor";

/**
 * @component
 * @name SearchDomainModal
 * @description Encapsulates the entire domain addition process within a modal, guiding the user through multiple steps.
 *              The launch button for opening the modal can be customized with the provided props.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.buttonSize - Specifies the size of the launch button (e.g., "lg", "sm"). Default is "lg".
 * @param {string} props.buttonText - Specifies the text displayed on the launch button. Default is "Search Domain".
 *
 * @returns {JSX.Element} The rendered component.
 */
const SearchDomainModal = ({
  buttonSize = "sm",
  buttonText = "Add Domain",
}) => {
  const { setProcessing, processing } = useProcessing();
  const { setReport, report } = useReport();

  // Modal visibility and step management
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  // State variables for domain addition process
  const [domainInput, setDomainInput] = useState("");
  const [extensions, setExtensions] = useState([]);
  const [extension, setExtension] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [nameservers, setNameservers] = useState([]);

  /**
   * @async
   * @function handleOpenModal
   * @description Opens the modal after verifying and fetching domain extensions if necessary.
   *              Checks locally stored extensions first; if they're older than 5 minutes, fetches new ones from the API.
   *              Saves fetched extensions and sets the default extension for the search process.
   *
   * @returns {Promise<void>}
   */
  const handleOpenModal = async () => {
    setProcessing(true);

    try {
      // Retrieve locally stored extensions and their timestamp.
      const savedExtensions = await localData("get", "extensions");
      const savedTimestamp = await localData("get", "extensionsTimestamp");
      const now = Date.now();
      const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds

      // Use local extensions if available and not stale.
      if (
        savedExtensions &&
        Array.isArray(savedExtensions) &&
        savedExtensions.length > 0 &&
        savedTimestamp &&
        now - savedTimestamp < fiveMinutes
      ) {
        setExtensions(savedExtensions);
        setExtension(savedExtensions[0].extension || "");
      } else {
        // Fetch fresh extensions from the server.
        const response = await runData({}, "/web/domains/extensions/");
        if (response.status === 200 && response.data.extensions?.length) {
          // Save extensions and timestamp locally.
          await localData("save", "extensions", response.data.extensions);
          await localData("save", "extensionsTimestamp", now);

          setExtensions(response.data.extensions);
          setExtension(response.data.extensions[0].extension || "");
        } else {
          setReport({
            show: true,
            message: "No extensions available for search.",
            type: "info",
          });
          return; // Abort modal opening if no data is available.
        }
      }

      // Proceed to first step and display the modal.
      setCurrentStep(1);
      setShowModal(true);
    } catch (error) {
      setReport({
        show: true,
        message: error.message || "Error fetching extensions.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function handleCloseModal
   * @description Resets all state and closes the modal, reverting to the initial step.
   */
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStep(1);
    setDomainInput("");
    setExtensions([]);
    setExtension("");
    setSearchResult(null);
    setProfileData(null);
    setNameservers([]);
  };

  return (
    <>
      {/* Launch Button with Configurable Text and Size */}
      <Button
        variant="outline-primary"
        className="me-2"
        size={buttonSize}
        onClick={handleOpenModal}
      >
        {buttonText}
      </Button>

      {/* Render Modal only if not processing and no report is currently displayed */}
      {!processing && !report.show && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {currentStep === 1 && "Search Domain"}
              {currentStep === 2 &&
                (searchResult?.status === "available"
                  ? "Congratulations!"
                  : "Domain Taken!")}
              {currentStep === 3 && "Registrant Profile"}
              {currentStep === 4 && "Nameservers"}
              {currentStep === 5 && "Place Order"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-4">
            {currentStep === 1 && (
              <DomainSearchForm
                domainInput={domainInput}
                setDomainInput={setDomainInput}
                extension={extension}
                setExtension={setExtension}
                extensions={extensions}
                onSuccess={(result) => {
                  setSearchResult(result);
                  setCurrentStep(2);
                }}
                setProcessing={setProcessing}
                setReport={setReport}
                onCancel={handleCloseModal}
              />
            )}
            {currentStep === 2 && (
              <DomainResultStep
                searchResult={searchResult}
                onNext={() => setCurrentStep(3)}
                onCancel={handleCloseModal}
                onBack={() => setCurrentStep(1)}
              />
            )}
            {currentStep === 3 && (
              <ProfileSelection
                onComplete={(data) => {
                  setProfileData(data);
                  setCurrentStep(4);
                }}
                onBack={() => setCurrentStep(2)}
              />
            )}
            {currentStep === 4 && (
              <NameserverEntry
                onSubmit={(nameserverData) => {
                  setNameservers(nameserverData);
                  setCurrentStep(5);
                }}
                onBack={() => setCurrentStep(3)}
                domain={`${domainInput}${extension}`}
              />
            )}
            {currentStep === 5 && (
              <OrderSubmission
                domainInput={domainInput}
                extension={extension}
                searchResult={searchResult}
                profileData={profileData}
                nameservers={nameservers}
                onBack={() => setCurrentStep(4)}
                onCloseModal={handleCloseModal}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

SearchDomainModal.propTypes = {
  buttonSize: PropTypes.string,
  buttonText: PropTypes.string,
};

export default SearchDomainModal;
