import axios from "axios";
import { logout } from "../../utils/auth";
import { Errored } from "./errors";

/**
 * Axios instance for API requests with a default base URL and headers.
 *
 * @constant {axios.AxiosInstance}
 */
export const apiData = axios.create({
  baseURL: "/query",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});

/**
 * Axios request interceptor.
 */
apiData.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(new Error(error.message))
);

/**
 * Axios response interceptor for handling 401 errors.
 */
apiData.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout(); // Execute logout
      return Promise.reject(
        new Errored("Session expired. Please login again.", 401)
      );
    }
    return Promise.reject(error);
  }
);
