import React, { useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { runData } from "../functions/api/processor";
import { FaSearch } from "react-icons/fa";
import { localData } from "../functions/api/localData";

/**
 * @component
 * @name DomainSearchForm
 * @description Step 1 of the domain order process. Allows the user to input a domain name and extension,
 * validates it, checks availability via an API call, and advances to the next step on success.
 *
 * @param {Object} props - The component props.
 * @param {string} props.domainInput - The current value of the domain name input.
 * @param {Function} props.setDomainInput - Setter for the domain name input state.
 * @param {string} props.extension - The currently selected domain extension (e.g., ".com").
 * @param {Function} props.setExtension - Setter for the domain extension.
 * @param {Array} props.extensions - A list of available domain extensions.
 * @param {Function} props.onSuccess - Callback invoked on successful domain availability check.
 * @param {Function} props.setProcessing - Setter for controlling a global or parent "processing" indicator.
 * @param {Function} props.setReport - Setter for showing user notifications (errors, info, etc.).
 * @param {Function} props.onCancel - Callback to cancel or close this step.
 * @returns {JSX.Element} The rendered component.
 */
const DomainSearchForm = ({
  domainInput,
  setDomainInput,
  extension,
  setExtension,
  extensions,
  onSuccess,
  setProcessing,
  setReport,
}) => {
  /**
   * @function isValidDomain
   * @description Simple domain validation to ensure it matches the allowed pattern (no starting or ending dashes, etc.).
   * @param {string} domain - The domain name (without extension).
   * @returns {boolean} Whether the domain is valid based on a basic pattern match.
   */
  const isValidDomain = (domain) => {
    const pattern = /^(?!-)[a-z0-9-]{1,63}(?<!-)$/;
    return pattern.test(domain);
  };

  /**
   * @function handleDomainSearch
   * @description Validates the domain input and sends an API request to check its availability.
   * Displays errors via setReport if invalid or if the server returns an error.
   * @param {Event} event - The form submission event.
   * @returns {Promise<void>}
   */
  const handleDomainSearch = async (event) => {
    event.preventDefault();
    const trimmedDomain = domainInput.trim();

    // Basic client-side validation
    if (!isValidDomain(trimmedDomain)) {
      setReport({
        show: true,
        message:
          "Invalid domain name. Please enter a valid domain (e.g., 'example').",
        type: "error",
      });
      return;
    }
    await clearAllLocalWizardData();
    setProcessing(true);
    try {
      const fullDomain = `${trimmedDomain}${extension}`;
      const response = await runData(
        { domain: fullDomain },
        "/web/domains/check/"
      );

      if (response.status === 200) {
        // onSuccess can move to the next step in your workflow
        onSuccess(response.data);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Domain search failed.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function clearAllLocalWizardData
   * @description Removes the local data for the domain wizard, profiles, nameservers, etc.
   */
  const clearAllLocalWizardData = async () => {
    // Nameserver wizard data
    await localData("remove", "nameserverWizard");
    await localData("remove", "defaultNameservers");
    await localData("remove", "defaultNameserversTimestamp");

    // Profile wizard data
    await localData("remove", "profileWizard");
    await localData("remove", "profiles");
    await localData("remove", "profilesTimestamp");

    // If you have other keys (e.g., "lastSearchStatus"), remove them too:
    // await localData("remove", "lastSearchStatus");
  };

  /**
   * @function handleDomainInputChange
   * @description Updates domain input state and saves the last searched domain locally.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handleDomainInputChange = async (e) => {
    const newValue = e.target.value.toLowerCase();
    setDomainInput(newValue);
    await localData("save", "lastSearchedDomain", newValue);
  };

  /**
   * @function useEffect - Load any previously stored domain name on mount.
   */
  useEffect(() => {
    (async () => {
      const storedDomain = await localData("get", "lastSearchedDomain");
      if (storedDomain) {
        setDomainInput(storedDomain);
      }
    })();
  }, [setDomainInput]);

  return (
    <Form onSubmit={handleDomainSearch}>
      <p className="mb-5" style={{ textAlign: "justify" }}>
        Whether you want to register a new domain or transfer an existing one to
        Marmasco, this is your starting point. Please enter your preferred
        domain name below to check its availability and proceed with the
        ordering process.
      </p>
      <Form.Group className="mb-3" controlId="domainSearch">
        <Form.Label>Enter Domain Name</Form.Label>
        <InputGroup>
          <FormControl
            type="text"
            placeholder="e.g., example"
            value={domainInput}
            onChange={handleDomainInputChange}
            required
            aria-label="Domain name input"
          />
          <Form.Select
            style={{ maxWidth: "120px" }}
            value={extension}
            onChange={(e) => setExtension(e.target.value)}
            disabled={extensions.length === 0}
            aria-label="Select domain extension"
          >
            {extensions.map((ext) => (
              <option key={ext.extension} value={ext.extension}>
                {ext.extension}
              </option>
            ))}
          </Form.Select>

          <Button
            variant="outline-secondary"
            type="submit"
            disabled={!extensions.length}
            aria-label="Search Domain"
          >
            <FaSearch aria-hidden="true" className="me-2" />
            Search
          </Button>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default DomainSearchForm;
