/**
 * @file domainApi.js
 * @description Provides an API function to fetch domain data.
 *              Currently returns sample data after performing validations.
 *              In production, replace this with an actual API call.
 * @author
 *   Masimba Maregedze, 1995
 */

const sampleDomains = [];

/**
 * Validates that a domain object contains all required properties.
 * @param {Object} domain - The domain object to validate.
 * @returns {boolean} True if the domain is valid, false otherwise.
 */
function validateDomain(domain) {
  return (
    domain &&
    typeof domain === "object" &&
    typeof domain.reference === "string" &&
    typeof domain.name === "string" &&
    typeof domain.expiry === "string" &&
    typeof domain.registered === "string"
  );
}

/**
 * Fetches domain data from an API endpoint.
 * For this example, returns sample data after validation.
 * @returns {Promise<Object[]>} A promise that resolves with an array of valid domain objects.
 */
export function fetchDomains() {
  return new Promise((resolve, reject) => {
    // Simulate an asynchronous API call
    setTimeout(() => {
      if (!Array.isArray(sampleDomains)) {
        return reject(new Error("Invalid data format: expected an array."));
      }
      for (const domain of sampleDomains) {
        if (!validateDomain(domain)) {
          return reject(new Error("Invalid domain object format."));
        }
      }
      resolve(sampleDomains);
    }, 1000);
  });
}
