"use strict";

import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Badge, Card } from "react-bootstrap";
import { FaMicrochip, FaLaptop, FaHdd } from "react-icons/fa";
import ServerModal from "../../order/ServerOrder";

/**
 * Infers the vCPU brand from the transformed server name (MTX..., MTPX..., MTAX..., etc.).
 */
function getArchitectureBrand(serverName) {
  const upperName = serverName.toUpperCase();
  if (upperName.startsWith("MTX") || upperName.startsWith("MTCX")) {
    return "Intel";
  }
  if (upperName.startsWith("MTPX") || upperName.startsWith("MTCCX")) {
    return "AMD";
  }
  if (upperName.startsWith("MTAX")) {
    return "ARM";
  }
  return "Unknown";
}

/**
 * Determines whether a server is Shared or Dedicated based on server.cpuType.
 */
function getUsageType(server) {
  if (server.cpuType && server.cpuType.toLowerCase() === "dedicated") {
    return "Dedicated";
  }
  return "Shared";
}

const ServerConfigurator = ({ location, servers }) => {
  // Slider states
  const [cpus, setCpus] = useState(1);
  const [memory, setMemory] = useState(1);
  const [storage, setStorage] = useState(10);

  // Dynamic maximum values
  const [maxCpus, setMaxCpus] = useState(16);
  const [maxMemory, setMaxMemory] = useState(64);
  const [maxStorage, setMaxStorage] = useState(1000);

  // Additional filters
  const [cpuUsage, setCpuUsage] = useState("Any"); // Shared / Dedicated / Any
  const [cpuBrand, setCpuBrand] = useState("Any"); // Intel / AMD / ARM / Any

  // Filtered servers + pagination
  const [matchingServers, setMatchingServers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  /**
   * Adjusts slider values while keeping them consistent.
   */
  const handleSliderChange = (sliderType, value) => {
    let newCpus = cpus;
    let newMemory = memory;
    let newStorage = storage;

    if (sliderType === "cpus") {
      newCpus = value;
      if (newMemory < newCpus * 2) {
        newMemory = newCpus * 2;
      }
    } else if (sliderType === "memory") {
      newMemory = value;
      if (newCpus > Math.floor(newMemory / 2)) {
        newCpus = Math.floor(newMemory / 2);
      }
    } else if (sliderType === "storage") {
      newStorage = value;
      if (newStorage < newMemory * 10) {
        newStorage = newMemory * 10;
      }
    }

    setCpus(newCpus);
    setMemory(newMemory);
    setStorage(newStorage);
  };

  /**
   * Computes dynamic maximum slider values.
   */
  useEffect(() => {
    const availableServers = servers.filter(
      (s) =>
        s.groupedPrices &&
        s.groupedPrices[location] &&
        s.groupedPrices[location].prices &&
        s.groupedPrices[location].prices.length > 0
    );
    if (availableServers.length > 0) {
      setMaxCpus(Math.max(...availableServers.map((s) => s.cores)));
      setMaxMemory(Math.max(...availableServers.map((s) => s.memory)));
      setMaxStorage(Math.max(...availableServers.map((s) => s.disk)));
    }
  }, [location, servers]);

  /**
   * Filters servers by vCPU usage, vCPU brand, plus the slider constraints.
   * Then sorts by closeness to the chosen specs.
   */
  useEffect(() => {
    const filtered = servers.filter((s) => {
      // Basic checks
      if (
        !s.groupedPrices ||
        !s.groupedPrices[location] ||
        !s.groupedPrices[location].prices ||
        s.groupedPrices[location].prices.length === 0
      ) {
        return false;
      }
      if (s.cores < cpus) return false;
      if (s.memory < memory) return false;
      if (s.disk < storage) return false;

      // vCPU usage
      const usage = getUsageType(s);
      if (cpuUsage !== "Any" && usage !== cpuUsage) {
        return false;
      }

      // vCPU brand
      const brand = getArchitectureBrand(s.name);
      if (cpuBrand !== "Any" && brand !== cpuBrand) {
        return false;
      }

      return true;
    });

    // Sort by closeness
    filtered.sort((a, b) => {
      const diffA = a.cores - cpus + (a.memory - memory) + (a.disk - storage);
      const diffB = b.cores - cpus + (b.memory - memory) + (b.disk - storage);
      return diffA - diffB;
    });

    setMatchingServers(filtered);
    setCurrentPage(1);
  }, [cpus, memory, storage, location, servers, cpuUsage, cpuBrand]);

  /**
   * Renders a single row in the table.
   */
  const renderServerCard = (server, index) => {
    const priceData = server.groupedPrices?.[location]?.prices;
    if (!priceData || priceData.length === 0) return null;

    const priceInfo = priceData[0];
    const priceMonthly = parseFloat(
      priceInfo.price_monthly?.gross || 0
    ).toFixed(2);
    const trafficTB = priceInfo.included_traffic
      ? (parseFloat(priceInfo.included_traffic) / 1099511627776).toFixed(2)
      : "0";

    const brand = getArchitectureBrand(server.name);

    return (
      <Card key={index} className="mb-3">
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={2} md={2} className="text-md-start">
              <strong>{server.name.toUpperCase()}</strong>
            </Col>
            <Col xs={2} md={2}>
              {server.cores}{" "}
              <Badge
                bg="secondary"
                style={{ fontSize: "0.75rem", marginLeft: "4px" }}
              >
                {brand}
              </Badge>
            </Col>
            <Col xs={1} md={1}>
              {server.memory} GB
            </Col>
            <Col xs={1} md={1}>
              {server.disk} GB
            </Col>
            <Col xs={2} md={2}>
              {trafficTB} TB
            </Col>
            <Col xs={2} md={2}>
              ${priceMonthly}
              <small style={{ color: "#999" }}>/mo</small>
            </Col>
            <Col xs={2} md={2} className="mt-2 mt-md-0">
              <ServerModal server={server} selectedLocation={location} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  /**
   * Renders the table and pagination controls.
   */
  const renderServerCards = () => {
    if (matchingServers.length === 0) {
      return (
        <p className="text-muted text-center">No matching server found.</p>
      );
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = matchingServers.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    return (
      <>
        <Row className="mb-3 p-2">
          <Col xs={2} md={2} className="text-md-start">
            <strong>NAME</strong>
          </Col>
          <Col xs={2} md={2}>
            <strong>vCPU</strong>
          </Col>
          <Col xs={1} md={1}>
            <strong>RAM</strong>
          </Col>
          <Col xs={1} md={1}>
            <strong>SSD</strong>
          </Col>
          <Col xs={2} md={2}>
            <strong>TRAFFIC</strong>
          </Col>
          <Col xs={2} md={2}>
            <strong>PRICE</strong>
          </Col>
          <Col xs={2} md={2}>
            <br />
          </Col>
        </Row>
        {currentItems.map((server, idx) => renderServerCard(server, idx))}
        {matchingServers.length > itemsPerPage && (
          <Row className="mt-5">
            <Col className="d-flex justify-content-start">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Back
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  currentPage >=
                  Math.ceil(matchingServers.length / itemsPerPage)
                }
              >
                Next
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  };

  /**
   * Renders the vCPU usage and vCPU brand filters in two side-by-side cards.
   */
  const renderCpuFilterCards = () => (
    <Row className="mb-4">
      <Col md={6}>
        <Card>
          <Card.Body>
            <Form.Label>
              <strong>vCPU Usage:</strong>
            </Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                id="usage-any"
                label="Any"
                value="Any"
                checked={cpuUsage === "Any"}
                onChange={(e) => setCpuUsage(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="usage-shared"
                label="Shared"
                value="Shared"
                checked={cpuUsage === "Shared"}
                onChange={(e) => setCpuUsage(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="usage-dedicated"
                label="Dedicated"
                value="Dedicated"
                checked={cpuUsage === "Dedicated"}
                onChange={(e) => setCpuUsage(e.target.value)}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Col md={6}>
        <Card>
          <Card.Body>
            <Form.Label>
              <strong>vCPU Brand:</strong>
            </Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                id="brand-any"
                label="Any"
                value="Any"
                checked={cpuBrand === "Any"}
                onChange={(e) => setCpuBrand(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="brand-intel"
                label="Intel"
                value="Intel"
                checked={cpuBrand === "Intel"}
                onChange={(e) => setCpuBrand(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="brand-amd"
                label="AMD"
                value="AMD"
                checked={cpuBrand === "AMD"}
                onChange={(e) => setCpuBrand(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="brand-arm"
                label="ARM"
                value="ARM"
                checked={cpuBrand === "ARM"}
                onChange={(e) => setCpuBrand(e.target.value)}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  /**
   * Renders a single full‑width card containing the sliders for vCPU, Memory, Storage.
   */
  const renderSliderCard = () => (
    <Card className="mb-4">
      <Card.Body>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaMicrochip className="me-2" />
                <strong>vCPUs:</strong> {cpus}
              </Form.Label>
              <Form.Range
                value={cpus}
                min={1}
                max={maxCpus}
                onChange={(e) =>
                  handleSliderChange("cpus", Number(e.target.value))
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaLaptop className="me-2" />
                <strong>Memory (GB):</strong> {memory}
              </Form.Label>
              <Form.Range
                value={memory}
                min={2}
                max={maxMemory}
                onChange={(e) =>
                  handleSliderChange("memory", Number(e.target.value))
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaHdd className="me-2" />
                <strong>Storage (GB):</strong> {storage}
              </Form.Label>
              <Form.Range
                value={storage}
                min={10}
                max={maxStorage}
                onChange={(e) =>
                  handleSliderChange("storage", Number(e.target.value))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return (
    <div className="mt-3">
      {renderCpuFilterCards()}
      {renderSliderCard()}
      {renderServerCards()}
    </div>
  );
};

export default ServerConfigurator;
