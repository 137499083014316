import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import KeenSlider from "keen-slider";
import "keen-slider/keen-slider.min.css";
import { runData } from "../functions/api/processor";

/**
 * DomainCarousel Component
 *
 * This production-ready component fetches domain extension offers via an API call
 * (using runData) and displays them in a responsive, auto-scrolling carousel.
 *
 * @returns {JSX.Element} Rendered carousel of domain offers.
 */
const DomainCarousel = () => {
  const [domainOffers, setDomainOffers] = useState([]);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    /**
     * Fetch domain extension data using runData.
     * This API call dynamically retrieves the available domain offers.
     */
    const fetchDomainExtensions = async () => {
      try {
        const responseExtension = await runData({}, "/web/domains/extensions/");
        if (
          responseExtension.status === 200 &&
          responseExtension.data.extensions
        ) {
          if (isMounted) {
            setDomainOffers(responseExtension.data.extensions);
          }
        } else {
          throw new Error("Invalid extension data received from API.");
        }
      } catch (err) {
        console.error("Failed to fetch domain extensions:", err);
        if (isMounted) {
          setError(
            err.message || "An error occurred while fetching domain extensions."
          );
        }
      }
    };

    fetchDomainExtensions();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (domainOffers.length > 0 && containerRef.current) {
      try {
        const options = {
          loop: true,
          slides: {
            perView: 4,
            spacing: 16,
          },
          breakpoints: {
            "(max-width: 576px)": {
              slides: {
                perView: 1,
                spacing: 8,
              },
            },
            "(min-width: 577px) and (max-width: 768px)": {
              slides: {
                perView: 2,
                spacing: 12,
              },
            },
            "(min-width: 769px) and (max-width: 992px)": {
              slides: {
                perView: 3,
                spacing: 16,
              },
            },
          },
        };

        sliderRef.current = new KeenSlider(containerRef.current, options);

        // Auto-scroll every 2000ms.
        const autoScrollInterval = setInterval(() => {
          if (sliderRef.current) {
            sliderRef.current.next();
          }
        }, 2000);

        return () => {
          clearInterval(autoScrollInterval);
          if (sliderRef.current) {
            sliderRef.current.destroy();
            sliderRef.current = null;
          }
        };
      } catch (error) {
        console.error("KeenSlider initialization error:", error);
      }
    }
  }, [domainOffers]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="bg-light" style={{ overflow: "hidden", padding: "10px" }}>
      <div className="keen-slider" ref={containerRef}>
        {domainOffers.map((offer, index) => (
          <div
            key={index}
            className="keen-slider__slide"
            style={{ padding: "5px" }}
          >
            <Card
              className="text-center domain-offer-card h-100"
              style={{
                transform: "scale(0.7)",
                transition: "transform 0.3s ease",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-items-center shadow-sm">
                <Card.Title as="h5" className="domain-extension">
                  {offer.extension}
                </Card.Title>
                <Card.Text className="domain-price small text-muted">
                  USD {offer.price}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DomainCarousel;
