/**
 * Custom error class with status code.
 *
 * @param {string} message - The error message.
 * @param {number} status - The HTTP status code.
 */
export class Errored extends Error {
    constructor(message, status) {
      super(message);
      this.status = status;
      this.name = "Errored";
    }
  }
  