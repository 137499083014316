import { apiData } from "./api";
import { cartData } from "./cartData";
import { Errored } from "./errors";

/**
 * Executes POST requests.
 *
 * Before constructing the FormData object, this function retrieves the shopping cart
 * items from IndexedDB and attaches only their reference values under a new key called "shopping".
 *
 * @param {Object} data - The original data to be sent in the request.
 * @param {string} path - The API endpoint path.
 * @param {string} [type] - Optional responseType for axios.
 * @returns {Promise<any>} The result of the POST request.
 */
export async function runData(data, path, type) {
  // Retrieve shopping cart items and extract only the references.
  try {
    const cartItems = await cartData("list");
    // Append the shopping cart references to the data object.
    data.shopping = cartItems.map((item) => item.reference);
  } catch (error) {
    console.error("Error retrieving shopping cart data:", error);
    // If retrieval fails, ensure shopping is still defined as an empty array.
    data.shopping = [];
  }

  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      if (typeof data[key] === "object" && !(data[key] instanceof Blob)) {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
  }

  const config = type ? { responseType: type } : {};

  try {
    return await apiData.post(path, formData, config);
  } catch (error) {
    let errorMessage, statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }

    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Executes GET requests.
 *
 * Converts object parameters to JSON strings and sends GET requests.
 *
 * @param {Object} data - The data to be converted to query parameters.
 * @param {string} path - The API endpoint path.
 * @param {string} [type] - Optional responseType for axios.
 * @returns {Promise<any>} The result of the GET request.
 */
export async function getData(data, path, type) {
  const params = {};
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      if (typeof data[key] === "object" && !(data[key] instanceof Blob)) {
        params[key] = JSON.stringify(data[key]);
      } else {
        params[key] = data[key];
      }
    }
  }
  const config = { params };
  if (type) config.responseType = type;

  try {
    return await apiData.get(path, config);
  } catch (error) {
    let errorMessage, statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }

    throw new Errored(errorMessage, statusCode);
  }
}
