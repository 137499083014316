/**
 * @file useDomains.js
 * @description A custom React hook to fetch and manage domain data state.
 *              It utilizes the fetchDomains API function and handles loading and error states.
 * @returns {Object} An object containing domains, loading, and error.
 * @author
 *   Masimba Maregedze, 1995
 */

import { useState, useEffect } from "react";
import { fetchDomains } from "./domainApi";

const useDomains = () => {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDomains()
      .then((data) => {
        setDomains(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return { domains, loading, error };
};

export default useDomains;
