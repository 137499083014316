/**
 * @file ManageHosting.jsx
 * @description A React component for managing hosting with dynamic expiry calculation,
 *              inline details viewing, backspace key navigation, and automatic selection via URL hash.
 *              It composes the HeaderSection, FilterSection, GridSection, and DetailsSection components.
 *              Hosting data is fetched using a custom hook from an external API module.
 * @author
 *   Masimba Maregedze, 1995
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import FilterSection from "./FilterSection";
import GridSection from "./GridSection";
import DetailsSection from "./DetailsSection";
import useHosting from "./useHosting";

const ManageHosting = () => {
  // Filter labels for dropdowns
  const filterLabels = {
    all: "All Hosting",
    active: "Active",
    expiring: "Expiring Soon",
    expired: "Expired",
  };

  // Local state declarations
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hostingFilter, setHostingFilter] = useState("all");
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageOptions = [6, 12, 24, 48];

  const detailsRef = useRef(null);
  const navigate = useNavigate();

  // Fetch hosting using the custom hook
  const { hosting, loading, error } = useHosting();

  /**
   * Processes the URL hash to auto-select a hosting if present.
   */
  useEffect(() => {
    if (!loading && hosting?.length > 0) {
      const hash = window.location.hash.startsWith("#")
        ? window.location.hash.substring(1)
        : window.location.hash;

      if (hash) {
        const foundInHosting = hosting.find(
          (hosting) => hosting.reference === hash
        );
        if (foundInHosting) {
          setSelectedItem(foundInHosting);
        }
      }
    }
  }, [hosting, loading, navigate]);

  // Reset current page when filtering parameters change
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, searchQuery, hostingFilter]);

  /**
   * @function handleSelectItem
   * @description Handles hosting selection and URL hash synchronization
   * @param {Object} hosting - The hosting object that was selected
   */
  const handleSelectItem = (hosting) => {
    setSelectedItem(hosting);
    navigate("");
  };

  /**
   * Checks whether the hosting is expired.
   * @param {string} expiryDate - The hosting expiry date.
   * @returns {boolean} - True if expired, false otherwise.
   */
  const isHostingExpired = (expiryDate) => new Date(expiryDate) < new Date();

  /**
   * Checks whether the hosting is expiring within one month.
   * @param {string} expiryDate - The hosting expiry date.
   * @returns {boolean} - True if expiring soon, false otherwise.
   */
  const isHostingExpiringSoon = (expiryDate) => {
    const expiry = new Date(expiryDate);
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
    return expiry > new Date() && expiry <= oneMonthFromNow;
  };

  /**
   * Determines the CSS class for a hosting based on its expiry status.
   * @param {string} expiryDate - The hosting expiry date.
   * @returns {string} - The corresponding CSS class.
   */
  const getExpiryClass = (expiryDate) =>
    isHostingExpired(expiryDate)
      ? "text-danger"
      : isHostingExpiringSoon(expiryDate)
      ? "text-warning"
      : "text-secondary";

  /**
   * Filters hosting based on the selected filter and search query.
   * @param {Object[]} hostingArray - Array of hosting objects.
   * @returns {Object[]} - Filtered array of hosting.
   */
  const filterHosting = (hostingArray) =>
    hostingArray
      .filter((hosting) => {
        switch (hostingFilter) {
          case "expired":
            return isHostingExpired(hosting.expiry);
          case "expiring":
            return (
              isHostingExpiringSoon(hosting.expiry) &&
              !isHostingExpired(hosting.expiry)
            );
          case "active":
            return (
              !isHostingExpired(hosting.expiry) &&
              !isHostingExpiringSoon(hosting.expiry)
            );
          default:
            return true;
        }
      })
      .filter((hosting) =>
        hosting.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

  const filteredHosting = filterHosting(hosting);
  const totalPages = Math.ceil(filteredHosting.length / itemsPerPage);
  const currentHosting = filteredHosting.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Responsive state: determine if the device is mobile (viewport width below 850 pixels)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  /**
   * @function handleResize
   * @description Updates the isMobile state on window resize.
   */
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 850);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * @function scrollToDetailsOnMobile
   * @description Scrolls the details section into view on mobile devices when a hosting is selected.
   */
  useEffect(() => {
    if (isMobile && selectedItem && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedItem, isMobile]);

  /**
   * Handles backspace key navigation (if not editing an input field).
   * @param {KeyboardEvent} e - The keyboard event.
   */
  const handleKeyDown = useCallback(
    (e) => {
      const tagName = e.target.tagName.toLowerCase();
      const isEditable =
        e.target.isContentEditable ||
        tagName === "input" ||
        tagName === "textarea" ||
        tagName === "select";

      if (e.key === "Backspace" && !isEditable) {
        e.preventDefault();
        navigate("/");
      }
    },
    [navigate]
  );

  // Attach keyboard listener for backspace navigation
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  // Display loading or error state if applicable
  if (loading) {
    return (
      <Container fluid className="mb-4">
        <div className="text-center mt-5">Loading hosting...</div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="mb-4">
        <div className="text-center mt-5 text-danger">
          Error: {error.message}
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col md={8} className="border-end">
          <HeaderSection />
          <FilterSection
            filterLabels={filterLabels}
            hostingFilter={hostingFilter}
            setHostingFilter={setHostingFilter}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            itemsPerPageOptions={itemsPerPageOptions}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <GridSection
            currentHosting={currentHosting}
            selectedItem={selectedItem}
            setSelectedItem={handleSelectItem}
            getExpiryClass={getExpiryClass}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Col>
        <Col
          md={4}
          className="d-flex flex-column"
          style={{ height: "75vh", position: "sticky", top: "80px" }}
          ref={detailsRef}
        >
          {selectedItem ? (
            <DetailsSection hosting={selectedItem} />
          ) : (
            <h5 className="text-center mt-5">
              Select a hosting to see the details
            </h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ManageHosting;
