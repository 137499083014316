import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import {
  FaArrowLeft,
  FaArrowRight,
  FaSave,
  FaUserCheck,
  FaUserPlus,
} from "react-icons/fa";
import { runData } from "../functions/api/processor";
import countryList from "react-select-country-list";
import { localData } from "../functions/api/localData";

/**
 * @component
 * @name ProfileSelection
 * @description Step 3 - Allow user to select or create a profile for domain registration/transfer.
 * Caches the list of profiles in localData for 5 minutes, and also persists the user's "wizard" state:
 * which path is chosen (existing vs. new), which sub-step for a new profile, and the form data.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onComplete - Callback when profile selection is complete.
 * @param {Function} props.onBack - Callback to navigate to the previous step.
 * @returns {JSX.Element} The ProfileSelection component.
 */
const ProfileSelection = ({ onComplete, onBack }) => {
  // ------------------- State -------------------
  const [useExisting, setUseExisting] = useState(null);
  const [step, setStep] = useState(1);
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProfileId, setSelectedProfileId] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [warning, setWarning] = useState("");

  // Fields for creating a new profile
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    company: "",
    city: "",
    address_physical: "",
    address_postal: "",
    address_legal: "",
    phone: "",
    description: "",
  });

  // ------------------- Effects -------------------

  /**
   * @function fetchProfilesWithCache
   * @description Retrieves profile data from localData if it's available and
   * was saved < 5 minutes ago. Otherwise, fetches fresh data via the API.
   */
  const fetchProfilesWithCache = async () => {
    try {
      const savedProfiles = await localData("get", "profiles");
      const savedTimestamp = await localData("get", "profilesTimestamp");
      const now = Date.now();
      const fiveMinutes = 5 * 60 * 1000; // 300,000 ms

      if (
        savedProfiles &&
        Array.isArray(savedProfiles) &&
        savedProfiles.length > 0 &&
        savedTimestamp &&
        now - savedTimestamp < fiveMinutes
      ) {
        // Use cached profiles
        setProfiles(savedProfiles);
        setLoading(false);
      } else {
        // Fetch fresh profiles
        const response = await runData({}, "/api/profiles/");
        if (response.status === 200 && Array.isArray(response.data.profiles)) {
          setProfiles(response.data.profiles);
          await localData("save", "profiles", response.data.profiles);
          await localData("save", "profilesTimestamp", now);
        } else {
          console.log(response.data.messages || "No profiles found.");
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching or caching profiles:", error);
      setLoading(false);
    }
  };

  /**
   * @function loadWizardState
   * @description Loads any previously saved wizard data (useExisting, step, selected profile, form data)
   * from local storage. Merges it into the component state if found.
   */
  const loadWizardState = async () => {
    const storedWizardData = await localData("get", "profileWizard");
    if (storedWizardData) {
      if (typeof storedWizardData.useExisting === "boolean") {
        setUseExisting(storedWizardData.useExisting);
      }
      if (typeof storedWizardData.step === "number") {
        setStep(storedWizardData.step);
      }
      if (storedWizardData.selectedProfileId) {
        setSelectedProfileId(storedWizardData.selectedProfileId);
      }
      if (storedWizardData.selectedProfile) {
        setSelectedProfile(storedWizardData.selectedProfile);
      }
      if (typeof storedWizardData.formData === "object") {
        setFormData((prev) => ({ ...prev, ...storedWizardData.formData }));
      }
    }
  };

  /**
   * On mount:
   * 1) Load profiles from cache (or fetch fresh if needed).
   * 2) Load existing wizard state from local storage.
   */
  useEffect(() => {
    fetchProfilesWithCache();
    loadWizardState();
  }, []);

  /**
   * Whenever key states change, we persist them to local storage
   * so the wizard can be resumed if the user navigates away and returns.
   */
  useEffect(() => {
    (async () => {
      const wizardData = {
        useExisting,
        step,
        selectedProfileId,
        selectedProfile,
        formData,
      };
      await localData("save", "profileWizard", wizardData);
    })();
  }, [useExisting, step, selectedProfileId, selectedProfile, formData]);

  // ------------------- Handlers -------------------

  /**
   * @function handleProfileChange
   * @description When the user selects an existing profile, populate formData from that profile.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The select event.
   */
  const handleProfileChange = (e) => {
    const profileRef = e.target.value;
    setSelectedProfileId(profileRef);
    setWarning(""); // Clear any previous warnings

    const profile = profiles.find((p) => p.reference === profileRef);

    if (profile) {
      setSelectedProfile(profile);
      setFormData({
        name: profile.name || "",
        email: profile.email || "",
        country: profile.country || "",
        company: profile.company || "",
        city: profile.city || "",
        address_physical: profile.address_physical || "",
        address_postal: profile.address_postal || "",
        address_legal: profile.address_legal || "",
        phone: profile.phone || "",
        description: profile.description || "",
      });
    } else {
      setSelectedProfile(null);
      setFormData({
        name: "",
        email: "",
        country: "",
        company: "",
        city: "",
        address_physical: "",
        address_postal: "",
        address_legal: "",
        phone: "",
        description: "",
      });
    }
  };

  /**
   * @function handleInputChange
   * @description Updates the formData state based on user input.
   * @param {React.ChangeEvent<HTMLInputElement | HTMLSelectElement>} e
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWarning("");
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * @function handleSubmitProfile
   * @description Validates required fields and invokes onComplete with the final chosen profile data.
   */
  const handleSubmitProfile = () => {
    const profileToSubmit = selectedProfile || formData;

    // Basic validation
    const requiredFields = ["name", "email", "country"];
    for (let field of requiredFields) {
      if (!profileToSubmit[field] || !profileToSubmit[field].trim()) {
        setWarning(`Please fill in the required field: ${field}`);
        return;
      }
    }

    setWarning("");
    onComplete(profileToSubmit);
  };

  // ------------------- Sub-Views -------------------

  const renderExistingProfileFlow = () => (
    <>
      <p className="mb-5" style={{ textAlign: "justify" }}>
        Please ensure that the profile you select accurately reflects the
        business or personal details associated with this domain. If it does
        not, or if you need a brand-new profile, go back and create a new one
        instead.
      </p>
      {loading ? (
        <Spinner animation="border" className="my-3" />
      ) : (
        <Form.Group controlId="existingProfile" className="mb-3">
          <Form.Label>Select Existing Profile</Form.Label>
          <Form.Select
            value={selectedProfileId}
            onChange={handleProfileChange}
            aria-label="Select Existing Profile"
          >
            <option value="">-- Select Profile --</option>
            {profiles.map((profile) => (
              <option key={profile.reference} value={profile.reference}>
                {profile.name} ({profile.email})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      <Row className="mt-5 mb-3">
        <Col>
          <Button
            variant="outline-warning"
            onClick={() => setUseExisting(null)}
            className="w-100"
            aria-label="Options"
          >
            <FaArrowLeft aria-hidden="true" className="me-2" />
            Options
          </Button>
        </Col>
        <Col>
          <Button
            variant="outline-secondary"
            onClick={handleSubmitProfile}
            className="w-100"
            disabled={loading || !selectedProfileId}
            aria-label="Apply registrant"
          >
            <FaSave aria-hidden="true" className="me-2" />
            Apply Registrant
          </Button>
        </Col>
      </Row>
    </>
  );

  const renderNewProfileStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h6>Step 1: Basic Information</h6>
            <br />
            <Form.Group className="mb-3" controlId="profileName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                aria-label="Full Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profileEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                aria-label="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profilePhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                aria-label="Phone Number"
              />
            </Form.Group>
            <Row className="mt-5 mb-3">
              <Col>
                <Button
                  variant="outline-warning"
                  onClick={() => setUseExisting(null)}
                  className="w-100"
                  aria-label="Options"
                >
                  <FaArrowLeft aria-hidden="true" className="me-2" />
                  Options
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={() => setStep(2)}
                  className="w-100"
                  aria-label="Next step"
                >
                  Next <FaArrowRight aria-hidden="true" className="me-2" />
                </Button>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <h6>Step 2: Company & Location Details</h6>
            <br />
            <Form.Group className="mb-3" controlId="profileCompany">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                aria-label="Company Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profileCountry">
              <Form.Label>Country</Form.Label>
              <Form.Select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                aria-label="Country"
              >
                <option value="">Select Country</option>
                {countryList()
                  .getData()
                  .map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="profileCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                aria-label="City"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profileDescription">
              <Form.Label>Business Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                aria-label="Business Description"
              />
            </Form.Group>
            <Row className="mt-5 mb-3">
              <Col>
                <Button
                  variant="outline-warning"
                  onClick={() => setStep(1)}
                  className="w-100"
                  aria-label="Previous step"
                >
                  <FaArrowLeft aria-hidden="true" className="me-2" /> Previous
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={() => setStep(3)}
                  className="w-100"
                  aria-label="Next step"
                >
                  Next <FaArrowRight aria-hidden="true" className="me-2" />
                </Button>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <h6>Step 3: Address & Contact Information</h6>
            <br />
            <Form.Group className="mb-3" controlId="profilePhysicalAddress">
              <Form.Label>Physical Address</Form.Label>
              <Form.Control
                type="text"
                name="address_physical"
                value={formData.address_physical}
                onChange={handleInputChange}
                aria-label="Physical Address"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profilePostalAddress">
              <Form.Label>Postal Address</Form.Label>
              <Form.Control
                type="text"
                name="address_postal"
                value={formData.address_postal}
                onChange={handleInputChange}
                aria-label="Postal Address"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="profileLegalAddress">
              <Form.Label>Legal Address</Form.Label>
              <Form.Control
                type="text"
                name="address_legal"
                value={formData.address_legal}
                onChange={handleInputChange}
                aria-label="Legal Address"
              />
            </Form.Group>
            <Row className="mt-5 mb-3">
              <Col>
                <Button
                  variant="outline-warning"
                  onClick={() => setStep(2)}
                  className="w-100"
                  aria-label="Previous step"
                >
                  <FaArrowLeft aria-hidden="true" className="me-2" /> Previous
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={handleSubmitProfile}
                  className="w-100"
                  aria-label="Save registrant"
                >
                  <FaSave aria-hidden="true" className="me-2" />
                  Save Registrant
                </Button>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  // ------------------- Main Render -------------------

  return (
    <div>
      {warning && (
        <Alert variant="warning" aria-live="assertive" className="mb-3">
          {warning}
        </Alert>
      )}

      {useExisting === null && (
        <>
          <Row className="mb-5" style={{ textAlign: "justify" }}>
            <p>
              Please ensure domain names are registered with the correct owner’s
              details. We will not initiate contact with your customers on your
              behalf. Choose an existing profile or create a new one to proceed.
            </p>
          </Row>
          <Row className="mb-3" style={{ textAlign: "justify" }}>
            <Col>
              <Button
                variant="outline-warning"
                onClick={onBack}
                className="w-100"
                aria-label="Go back"
              >
                <FaArrowLeft aria-hidden="true" className="me-2" />
                Back
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                onClick={() => setUseExisting(true)}
                className="w-100"
                aria-label="Use existing profile"
              >
                <FaUserCheck aria-hidden="true" className="me-2" />
                Profiles
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-secondary"
                onClick={() => setUseExisting(false)}
                className="w-100"
                aria-label="Create new profile"
              >
                <FaUserPlus aria-hidden="true" className="me-2" />
                Create
              </Button>
            </Col>
          </Row>
        </>
      )}

      {useExisting === true && renderExistingProfileFlow()}
      {useExisting === false && renderNewProfileStep()}
    </div>
  );
};

export default ProfileSelection;
