/**
 * @file FilterSection.jsx
 * @description A React component for rendering the filter and search controls.
 *              It provides dropdowns for selecting hosting filters and items-per-page options,
 *              as well as a search input.
 * @param {Object} props - The component props.
 * @param {Object} props.filterLabels - Labels for different filter types.
 * @param {string} props.hostingFilter - The currently selected hosting filter.
 * @param {Function} props.setHostingFilter - Function to update the hosting filter.
 * @param {number} props.itemsPerPage - The current number of items to display per page.
 * @param {Function} props.setItemsPerPage - Function to update items per page.
 * @param {number[]} props.itemsPerPageOptions - Available options for items per page.
 * @param {string} props.searchQuery - The current search query.
 * @param {Function} props.setSearchQuery - Function to update the search query.
 * @author
 *   Masimba Maregedze, 1995
 */

import React from "react";
import { Col, Dropdown, InputGroup, FormControl, Button } from "react-bootstrap";
import { Funnel, Search } from "react-bootstrap-icons";
import SearchHostingModal from "./StartHosting";

const FilterSection = ({
  filterLabels,
  hostingFilter,
  setHostingFilter,
  itemsPerPage,
  setItemsPerPage,
  itemsPerPageOptions,
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <div
      className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4"
      style={{ gap: "1rem" }}
    >
      <Col xs="auto">
        <Dropdown>
          <Dropdown.Toggle variant="light">
            <Funnel className="me-2" /> {filterLabels[hostingFilter]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(filterLabels).map((key) => (
              <Dropdown.Item key={key} onClick={() => setHostingFilter(key)}>
                {filterLabels[key]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs="auto">
        <Dropdown>
          <Dropdown.Toggle variant="light">
            Show {itemsPerPage}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {itemsPerPageOptions.map((option) => (
              <Dropdown.Item key={option} onClick={() => setItemsPerPage(option)}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} md className="mt-2 mt-md-0">
        <InputGroup>
          <FormControl
            placeholder="Search hosting..."
            aria-label="Search hosting"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button variant="primary" aria-label="Search">
            <Search />
          </Button>
        </InputGroup>
      </Col>
      <Col xs="auto">
      <SearchHostingModal /></Col>
    </div>
  );
};

export default FilterSection;
