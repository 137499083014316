/**
 * @file ManageDomains.jsx
 * @description A React component for managing domains with dynamic expiry calculation,
 *              inline details viewing, backspace key navigation, and automatic selection via URL hash.
 *              It composes the HeaderSection, FilterSection, GridSection, and DetailsSection components.
 *              Domain data is fetched using a custom hook from an external API module.
 * @author
 *   Masimba Maregedze, 1995
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import FilterSection from "./FilterSection";
import GridSection from "./GridSection";
import DetailsSection from "./DetailsSection";
import useDomains from "./useDomains";

const ManageDomains = () => {
  // Filter labels for dropdowns
  const filterLabels = {
    all: "All Domains",
    active: "Active",
    expiring: "Expiring Soon",
    expired: "Expired",
  };

  // Local state declarations
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [domainFilter, setDomainFilter] = useState("all");
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageOptions = [6, 12, 24, 48];

  const detailsRef = useRef(null);
  const navigate = useNavigate();

  // Fetch domains using the custom hook
  const { domains, loading, error } = useDomains();

  /**
   * Processes the URL hash to auto-select a domain if present.
   */
  useEffect(() => {
    if (!loading && domains?.length > 0) {
      const hash = window.location.hash.startsWith("#")
        ? window.location.hash.substring(1)
        : window.location.hash;

      if (hash) {
        const foundInDomains = domains.find(
          (domain) => domain.reference === hash
        );
        if (foundInDomains) {
          setSelectedItem(foundInDomains);
        }
      }
    }
  }, [domains, loading, navigate]);

  // Reset current page when filtering parameters change
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, searchQuery, domainFilter]);

  /**
   * @function handleSelectItem
   * @description Handles domain selection and URL hash synchronization
   * @param {Object} domain - The domain object that was selected
   */
  const handleSelectItem = (domain) => {
    setSelectedItem(domain);
    navigate("");
  };

  /**
   * Checks whether the domain is expired.
   * @param {string} expiryDate - The domain expiry date.
   * @returns {boolean} - True if expired, false otherwise.
   */
  const isDomainExpired = (expiryDate) => new Date(expiryDate) < new Date();

  /**
   * Checks whether the domain is expiring within one month.
   * @param {string} expiryDate - The domain expiry date.
   * @returns {boolean} - True if expiring soon, false otherwise.
   */
  const isDomainExpiringSoon = (expiryDate) => {
    const expiry = new Date(expiryDate);
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
    return expiry > new Date() && expiry <= oneMonthFromNow;
  };

  /**
   * Determines the CSS class for a domain based on its expiry status.
   * @param {string} expiryDate - The domain expiry date.
   * @returns {string} - The corresponding CSS class.
   */
  const getExpiryClass = (expiryDate) =>
    isDomainExpired(expiryDate)
      ? "text-danger"
      : isDomainExpiringSoon(expiryDate)
      ? "text-warning"
      : "text-secondary";

  /**
   * Filters domains based on the selected filter and search query.
   * @param {Object[]} domainArray - Array of domain objects.
   * @returns {Object[]} - Filtered array of domains.
   */
  const filterDomains = (domainArray) =>
    domainArray
      .filter((domain) => {
        switch (domainFilter) {
          case "expired":
            return isDomainExpired(domain.expiry);
          case "expiring":
            return (
              isDomainExpiringSoon(domain.expiry) &&
              !isDomainExpired(domain.expiry)
            );
          case "active":
            return (
              !isDomainExpired(domain.expiry) &&
              !isDomainExpiringSoon(domain.expiry)
            );
          default:
            return true;
        }
      })
      .filter((domain) =>
        domain.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

  const filteredDomains = filterDomains(domains);
  const totalPages = Math.ceil(filteredDomains.length / itemsPerPage);
  const currentDomains = filteredDomains.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Responsive state: determine if the device is mobile (viewport width below 850 pixels)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  /**
   * @function handleResize
   * @description Updates the isMobile state on window resize.
   */
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 850);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * @function scrollToDetailsOnMobile
   * @description Scrolls the details section into view on mobile devices when a domain is selected.
   */
  useEffect(() => {
    if (isMobile && selectedItem && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedItem, isMobile]);

  /**
   * Handles backspace key navigation (if not editing an input field).
   * @param {KeyboardEvent} e - The keyboard event.
   */
  const handleKeyDown = useCallback(
    (e) => {
      const tagName = e.target.tagName.toLowerCase();
      const isEditable =
        e.target.isContentEditable ||
        tagName === "input" ||
        tagName === "textarea" ||
        tagName === "select";

      if (e.key === "Backspace" && !isEditable) {
        e.preventDefault();
        navigate("/");
      }
    },
    [navigate]
  );

  // Attach keyboard listener for backspace navigation
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  // Display loading or error state if applicable
  if (loading) {
    return (
      <Container fluid className="mb-4">
        <div className="text-center mt-5">Loading domains...</div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="mb-4">
        <div className="text-center mt-5 text-danger">
          Error: {error.message}
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col md={8} className="border-end">
          <HeaderSection />
          <FilterSection
            filterLabels={filterLabels}
            domainFilter={domainFilter}
            setDomainFilter={setDomainFilter}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            itemsPerPageOptions={itemsPerPageOptions}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <GridSection
            currentDomains={currentDomains}
            selectedItem={selectedItem}
            setSelectedItem={handleSelectItem}
            getExpiryClass={getExpiryClass}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Col>
        <Col
          md={4}
          className="d-flex flex-column"
          style={{ height: "75vh", position: "sticky", top: "80px" }}
          ref={detailsRef}
        >
          {selectedItem ? (
            <DetailsSection domain={selectedItem} />
          ) : (
            <h5 className="text-center mt-5">
              Select a domain to see the details
            </h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ManageDomains;
