/**
 * @file useHosting.js
 * @description A custom React hook to fetch and manage hosting data state.
 *              It utilizes the fetchHosting API function and handles loading and error states.
 * @returns {Object} An object containing hosting, loading, and error.
 * @author
 *   Masimba Maregedze, 1995
 */

import { useState, useEffect } from "react";
import { fetchHosting } from "./hostingApi";

const useHosting = () => {
  const [hosting, setHosting] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHosting()
      .then((data) => {
        setHosting(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return { hosting, loading, error };
};

export default useHosting;
