// Create a BroadcastChannel instance for session changes
const broadcast = new BroadcastChannel("sessionChannel");

/**
 * Checks if the user is authenticated by verifying the session status in localStorage.
 * @returns {boolean} - Returns true if authenticated, otherwise false.
 */
export const isAuthenticated = () => {
  // Use localStorage to get the session status synchronously
  const session = localStorage.getItem("session");
  return session === "true";
};

/**
 * Sets the session data to indicate authentication status.
 * @param {boolean} status - True if user is logged in, false if logged out.
 */
export const setSession = (status) => {
  try {
    // Fetch current session status from localStorage
    const currentSession = localStorage.getItem("session") === "true";

    // Only update if the status has changed to avoid unnecessary actions
    if (currentSession !== status) {
      // Save authentication status (true/false) in localStorage
      localStorage.setItem("session", status.toString());

      // Broadcast the session status change to other tabs using BroadcastChannel
      broadcast.postMessage(status);
    }
  } catch (error) {
    console.error("Error saving session data:", error);
  }
};

/**
 * Logs the user out by setting the session data to false.
 */
export const logout = () => {
  try {
    // Set the session status to false (logged out)
    setSession(false);
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

// Handle session change events from other tabs using BroadcastChannel
broadcast.onmessage = (event) => {
  try {
    const status = event.data;

    // Update the local state and UI without touching the localStorage again.
    setSession(status);
  } catch (error) {
    console.error("Error handling broadcast message:", error);
  }
};
