import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, InputGroup, Badge } from "react-bootstrap";
import { runData } from "../functions/api/processor";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import { isAuthenticated } from "../utils/auth";
import PhoneInput from "react-phone-input-2";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FaInfoCircle,
  FaBriefcase,
  FaMapMarked,
  FaTimes,
  FaShoppingCart,
  FaPlusCircle,
  FaMinusCircle,
  FaServer,
} from "react-icons/fa";
import countries from "../utils/countries";
import { cartData } from "../functions/api/cartData";

/**
 * DomainOrder Component
 *
 * This component renders a view form to collect domain details,
 * registrant information, and nameserver data. It starts by validating
 * each nameserver through an authoritative check via the runData function.
 * If all nameservers pass (i.e., data.authoritative is true), the component
 * then validates the registrant details and proceeds to submit the order.
 * If default nameservers are provided in the data object, these are used to
 * populate the nameserver fields.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.data - Domain data details including code, status, price, currency, domain,
 *                              and an optional nameservers list.
 * @param {string} props.action - The type of action ("register", "transfer", "external").
 * @param {Function} props.onDismiss - Callback to dismiss the component.
 * @returns {JSX.Element} The rendered component.
 */
const DomainOrder = ({ data, action, onDismiss }) => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const authenticated = isAuthenticated();

  // Registrant form data state.
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "+263",
    company: "",
    country: "Zimbabwe",
    city: "",
    description: "",
    address_physical: "",
    address_postal: "",
    address_legal: "",
  });

  // Initialize nameservers from data if provided; otherwise, use default two fields.
  const [nameservers, setNameservers] = useState(() => {
    if (
      data.nameservers &&
      Array.isArray(data.nameservers) &&
      data.nameservers.length > 0
    ) {
      return data.nameservers.map((ns, index) => ({
        id: index + 1,
        label: `NS${index + 1}`,
        value: ns,
      }));
    }
    return [
      { id: 1, label: "NS1", value: "" },
      { id: 2, label: "NS2", value: "" },
    ];
  });

  // Track individual nameserver validation results.
  const [nameserverResults, setNameserverResults] = useState({});

  /**
   * Handles input changes for the registrant form.
   *
   * @param {Object} e - The input change event.
   */
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Handles changes in the nameserver fields.
   *
   * @param {number} id - The unique identifier for the nameserver field.
   * @param {string} value - The updated value for the nameserver.
   */
  const handleNameserverChange = (id, value) => {
    // Reset individual nameserver message upon change.
    setNameserverResults((prev) => ({ ...prev, [id]: "" }));
    setNameservers((prev) =>
      prev.map((ns) => (ns.id === id ? { ...ns, value } : ns))
    );
  };

  /**
   * Adds a new nameserver field with an incremented label.
   */
  const addNameserverField = () => {
    const newId = nameservers.length + 1; // new id: 3, 4, 5...
    setNameservers((prev) => [
      ...prev,
      { id: newId, label: `NS${newId}`, value: "" },
    ]);
  };

  /**
   * Removes an additional nameserver field.
   *
   * @param {number} id - The id of the nameserver field to remove.
   */
  const removeNameserverField = (id) => {
    // Only allow removal of fields beyond the first two mandatory nameservers.
    if (id <= 2) return;
    setNameserverResults((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
    setNameservers((prev) => prev.filter((ns) => ns.id !== id));
  };

  /**
   * Validates each nameserver by checking if it is authoritative.
   * It submits each nameserver to the /web/nameservers/authoritative-check/ endpoint.
   * If the returned data.authoritative is true, the nameserver is valid.
   *
   * @returns {Promise<boolean>} True if all nameservers pass the authoritative check; otherwise, false.
   */
  const validateAuthoritativeNameservers = async () => {
    let valid = true;
    const results = {};

    // Iterate through each nameserver for validation.
    for (const ns of nameservers) {
      if (!ns.value.trim()) {
        results[ns.id] = `${ns.label} is required`;
        valid = false;
      } else {
        try {
          // Submit nameserver for authoritative check.
          const response = await runData(
            { nameserver: ns.value.trim(), domain: data.domain },
            "/web/domains/nameservers/authority/"
          );
          if (response.data.authoritative) {
            results[ns.id] = "Valid";
          } else {
            results[ns.id] = `${ns.label} is not authoritative`;
            valid = false;
          }
        } catch (err) {
          results[ns.id] = `Error validating ${ns.label}`;
          valid = false;
        }
      }
    }
    setNameserverResults(results);
    if (!valid) {
      setReport({
        show: true,
        message:
          "One or more nameservers are not authoritative. Please check your entries.",
        type: "error",
      });
    } else {
      setReport({
        show: true,
        message: "All nameservers validated as authoritative.",
        type: "success",
      });
    }
    return valid;
  };

  /**
   * Validates the registrant form fields.
   *
   * @returns {boolean} True if valid; otherwise false.
   */
  const validateRegistrant = () => {
    if (!formData.name.trim()) {
      setReport({
        show: true,
        message: "Full Name is required",
        type: "error",
      });
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setReport({
        show: true,
        message: "A valid Email is required",
        type: "error",
      });
      return false;
    }
    if (formData.phone.replace(/\D/g, "").length < 8) {
      setReport({
        show: true,
        message: "A valid Phone Number is required",
        type: "error",
      });
      return false;
    }
    if (!formData.company.trim()) {
      setReport({
        show: true,
        message: "Company Name is required",
        type: "error",
      });
      return false;
    }
    if (!formData.country.trim()) {
      setReport({
        show: true,
        message: "Country is required",
        type: "error",
      });
      return false;
    }
    if (!formData.city.trim()) {
      setReport({
        show: true,
        message: "City is required",
        type: "error",
      });
      return false;
    }
    if (!formData.description.trim()) {
      setReport({
        show: true,
        message: "Business Description is required",
        type: "error",
      });
      return false;
    }
    if (!formData.address_physical.trim()) {
      setReport({
        show: true,
        message: "Physical Address is required",
        type: "error",
      });
      return false;
    }
    if (!formData.address_postal.trim()) {
      setReport({
        show: true,
        message: "Postal Address is required",
        type: "error",
      });
      return false;
    }
    if (!formData.address_legal.trim()) {
      setReport({
        show: true,
        message: "Legal Address is required",
        type: "error",
      });
      return false;
    }
    return true;
  };

  /**
   * Handles order submission by first validating the nameservers authoritativeness,
   * then validating the domain and registrant data, and finally submitting the order.
   *
   * @param {Object} e - The form submit event.
   */
  const handleSubmitOrder = async (e) => {
    e.preventDefault();

    setProcessing(true);

    // Validate domain details.
    const domainValid = data.domain.trim() !== "" && data;
    if (!domainValid) {
      setReport({
        show: true,
        message: "Please ensure that the domain details are valid.",
        type: "error",
      });

      setProcessing(false);
      return;
    }

    // Validate that all nameserver fields are filled.
    const nameserversFilled = nameservers.every((ns) => ns.value.trim() !== "");
    if (!nameserversFilled) {
      setReport({
        show: true,
        message: "Please fill in all nameserver fields.",
        type: "error",
      });
      setProcessing(false);
      return;
    }

    // Validate registrant details.
    if (!validateRegistrant()) {
      setProcessing(false);
      return;
    }

    // Optionally, perform the authoritative check here.
    const nsValid = await validateAuthoritativeNameservers();
    if (!nsValid) {
      setProcessing(false);
      return;
    }

    try {
      // Construct order object with all necessary details, including nameservers.
      const order = {
        product: "domain",
        action: action,
        domain: data.domain,
        registrant: formData,
        offer: data,
        nameservers: nameservers.map((ns) => ns.value.trim()),
        amount: data.price,
      };
      const response = await runData(
        order,
        `/${authenticated ? "api" : "web"}/orders/save/`
      );
      if (response.status === 200) {
        await cartData("save", response.data.reference, order);
        setReport({
          show: true,
          message:
            "Your order has been saved successfully! Redirecting to checkout...",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/checkout/";
        }, 3000);
      } else {
        setReport({
          show: true,
          message: response.data.message || "Order submission failed.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
        err.message ||  "Order submission encountered an error. Please try again later.",
        type: "error",
      });
    }
    setProcessing(false);
  };

  /**
   * Handles cancel action.
   */
  const handleCancel = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  // Determine if the registrant section is valid.
  const registrantValid =
    formData.name.trim() !== "" &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) &&
    formData.phone.replace(/\D/g, "").length >= 8 &&
    formData.company.trim() !== "" &&
    formData.country.trim() !== "" &&
    formData.city.trim() !== "" &&
    formData.description.trim() !== "" &&
    formData.address_physical.trim() !== "" &&
    formData.address_postal.trim() !== "" &&
    formData.address_legal.trim() !== "";

  // Check that the domain field is not empty.
  const domainValid = data.domain.trim() !== "" && data;

  // Instead of using nameserversValidated, check that every nameserver field is filled.
  const nameserversFilled = nameservers.every((ns) => ns.value.trim() !== "");

  // The submit button is enabled if domain, registrant, and all nameserver fields are filled.
  const isCheckoutEnabled = domainValid && registrantValid && nameserversFilled;

  // Determine heading text based on the action prop.
  let headingText = "Submit Domain";
  if (action === "register") {
    headingText = "Register Domain";
  } else if (action === "transfer") {
    headingText = "Transfer Domain";
  } else if (action === "external") {
    headingText = "Configure Domain";
  }

  return (
    <Form onSubmit={handleSubmitOrder}>
      {/* Header Section with Dismiss Option */}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="d-flex align-items-center">
            <h4 className="mb-0">
              <Badge className="ml-2">
                {data.currency} {data.price}
              </Badge>{" "}
              {data.domain}
            </h4>
          </div>
          <FaTimes
            style={{ cursor: "pointer" }}
            onClick={handleCancel}
            title="Cancel and dismiss"
            className="text-muted"
            size={30}
          />
        </div>
        <p className="text-mute small">
          Please ensure that the information provided in this form corresponds
          to the actual domain owner's details. If you are a consultant or
          registering on behalf of a client, you are required to enter your
          client's details. All communications from our system will be sent
          exclusively to you; we do not send notifications directly to your
          clients.
        </p>
      </div>
      <hr className="mb-5" />

      {/* Basic Information */}
      <h5 className="mb-4">
        <FaInfoCircle className="me-2" />
        Basic Information
      </h5>

      <Row className="mb-5">
        <Form.Group controlId="profileName" className="mb-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter your full name"
            required
          />
        </Form.Group>
        <Col md={6}>
          <Form.Group controlId="profileEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="profilePhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <PhoneInput
              country={"zw"}
              value={formData.phone}
              onChange={(phone) => setFormData({ ...formData, phone })}
              inputProps={{ name: "phone", required: true }}
              inputClass="form-control"
              inputStyle={{ width: "100%", height: "43px" }}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Company & Location Details */}
      <h5 className="mb-4">
        <FaBriefcase className="me-2" />
        Company Information
      </h5>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="profileCompany">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              placeholder="Enter your company name"
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="profileCountry">
            <Form.Label>Country</Form.Label>
            <Typeahead
              id="profileCountry"
              onChange={(selected) =>
                setFormData({ ...formData, country: selected[0] || "" })
              }
              options={countries}
              selected={formData.country ? [formData.country] : []}
              placeholder="Select Country"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={6}>
          <Form.Group controlId="profileCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="Enter your city"
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="profileDescription">
            <Form.Label>Business Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Describe your business"
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Address & Contact Information */}
      <h5 className="mb-4">
        <FaMapMarked className="me-2" />
        Address Information
      </h5>
      <Form.Group controlId="profilePhysicalAddress" className="mb-3">
        <Form.Label>Physical Address</Form.Label>
        <Form.Control
          type="text"
          name="address_physical"
          value={formData.address_physical}
          onChange={handleInputChange}
          placeholder="Enter your physical address"
          required
        />
      </Form.Group>
      <Row className="mb-5">
        <Col md={6}>
          <Form.Group controlId="profilePostalAddress">
            <Form.Label>Postal Address</Form.Label>
            <Form.Control
              type="text"
              name="address_postal"
              value={formData.address_postal}
              onChange={handleInputChange}
              placeholder="Enter your postal address"
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="profileLegalAddress">
            <Form.Label>
              Legal Address (Domicilium citandi et executandi)
            </Form.Label>
            <Form.Control
              type="text"
              name="address_legal"
              value={formData.address_legal}
              onChange={handleInputChange}
              placeholder="Enter your legal address"
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Nameserver Information Section */}
      <h5 className="mb-4">
        <FaServer /> Nameserver Information
      </h5>
      <Row className="mb-0">
        {nameservers.map((ns) => (
          <Col md={6} key={ns.id} className="mb-3">
            <Form.Group controlId={`nameserver-${ns.id}`}>
              <Form.Label>{ns.label}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={ns.value}
                  onChange={(e) =>
                    handleNameserverChange(ns.id, e.target.value)
                  }
                  placeholder={`Enter ${ns.label}`}
                  required
                />
                {ns.id > 2 && (
                  <Button
                    variant="outline-danger"
                    onClick={() => removeNameserverField(ns.id)}
                    title={`Remove ${ns.label}`}
                  >
                    <FaMinusCircle />
                  </Button>
                )}
              </InputGroup>
              {nameserverResults[ns.id] && (
                <Form.Text
                  className={
                    nameserverResults[ns.id] === "Valid"
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {nameserverResults[ns.id]}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        ))}
      </Row>
      <Row className="mb-5">
        <Col className="text-end">
          <Button
            variant="outline-primary"
            className="btn-tiny"
            onClick={addNameserverField}
          >
            <FaPlusCircle className="me-2" />
            Additional Nameservers
          </Button>
        </Col>
      </Row>
      <hr className="mt-5" />
      {/* Bottom Buttons: Cancel/Change & Validate/Submit */}
      <Row className="mt-5">
        <Col xs={6}>
          <Button variant="outline-danger" onClick={handleCancel}>
            Change Domain
          </Button>
        </Col>
        <Col xs={6} className="text-end">
          <Button
            variant="outline-secondary"
            type="submit"
            disabled={!isCheckoutEnabled}
          >
            <FaShoppingCart className="me-2" />
            {headingText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

DomainOrder.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string,
    domain: PropTypes.string.isRequired,
    // The nameservers property should be an optional array of strings.
    nameservers: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  action: PropTypes.oneOf(["register", "transfer", "external"]).isRequired,
  onDismiss: PropTypes.func,
};

export default DomainOrder;
