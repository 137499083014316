/**
 * @file hostingApi.js
 * @description Provides an API function to fetch hosting data.
 *              Currently returns sample data after performing validations.
 *              In production, replace this with an actual API call.
 * @author
 *   Masimba Maregedze, 1995
 */

const sampleHosting = [];

/**
 * Validates that a hosting object contains all required properties.
 * @param {Object} hosting - The hosting object to validate.
 * @returns {boolean} True if the hosting is valid, false otherwise.
 */
function validateHosting(hosting) {
  return (
    hosting &&
    typeof hosting === "object" &&
    typeof hosting.reference === "string" &&
    typeof hosting.name === "string" &&
    typeof hosting.expiry === "string" &&
    typeof hosting.registered === "string"
  );
}

/**
 * Fetches hosting data from an API endpoint.
 * For this example, returns sample data after validation.
 * @returns {Promise<Object[]>} A promise that resolves with an array of valid hosting objects.
 */
export function fetchHosting() {
  return new Promise((resolve, reject) => {
    // Simulate an asynchronous API call
    setTimeout(() => {
      if (!Array.isArray(sampleHosting)) {
        return reject(new Error("Invalid data format: expected an array."));
      }
      for (const hosting of sampleHosting) {
        if (!validateHosting(hosting)) {
          return reject(new Error("Invalid hosting object format."));
        }
      }
      resolve(sampleHosting);
    }, 1000);
  });
}
