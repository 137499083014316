/**
 * @module StartHosting
 * @description A component to start the hosting process. It manages modal steps for choosing a hosting plan,
 * selecting additional services, and completing the order.
 *
 * @requires react
 * @requires react-bootstrap
 * @requires react-bootstrap-typeahead
 * @requires ProcessingModalContext
 * @requires ReportModalContext
 */

import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import ProfileSelection from "./ProfileSelection";
import OrderSubmission from "./OrderSubmission";

const StartHosting = () => {
  const { setProcessing, processing } = useProcessing();
  const { setReport, report } = useReport();

  // Modal and step management
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  // Hosting state
  const [hostingPlans, setHostingPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [addOns, setAddOns] = useState([]);
  const [profileData, setProfileData] = useState(null);

  /**
   * Handle opening the modal and initializing sample data for testing.
   *
   * @async
   */
  const handleOpenModal = async () => {
    setProcessing(true);
    try {
      // Initialize sample data for hosting plans (packages).
      setHostingPlans([
        {
          id: 1,
          name: "Basic",
          price: 5,
          cpu: "1 CPU Core",
          ram: "1 GB",
          storage: "20 GB",
          bandwidth: "100 GB",
        },
        {
          id: 2,
          name: "Pro",
          price: 10,
          cpu: "2 CPU Cores",
          ram: "2 GB",
          storage: "40 GB",
          bandwidth: "200 GB",
        },
        {
          id: 3,
          name: "Premium",
          price: 20,
          cpu: "4 CPU Cores",
          ram: "4 GB",
          storage: "80 GB",
          bandwidth: "400 GB",
        },
      ]);

      setCurrentStep(1);
      setShowModal(true);
    } catch (error) {
      setReport({ show: true, message: error.message, type: "error" });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Handles closing the modal and resetting all state variables.
   */
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStep(1);
    // Reset state variables for a fresh start.
    setSelectedPlan(null);
    setAddOns([]);
    setProfileData(null);
  };

  return (
    <>
      <Button variant="primary" onClick={handleOpenModal}>
        Get Hosting
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} 
          backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentStep === 1 && "Select Hosting Package"}
            {currentStep === 2 && "Additional Services"}
            {currentStep === 3 && "Complete Order"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Step 1: Hosting package selection */}
          {currentStep === 1 && (
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {hostingPlans.map((plan) => (
                <div key={plan.id} className="col">
                  <div
                    className={`card h-100 ${
                      selectedPlan?.id === plan.id ? "border-primary" : ""
                    }`}
                    onClick={() => setSelectedPlan(plan)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <h5 className="card-title">{plan.name}</h5>
                      <p className="h4">${plan.price}/mo</p>
                      <ul className="list-unstyled">
                        <li>{plan.cpu}</li>
                        <li>{plan.ram}</li>
                        <li>{plan.storage}</li>
                        <li>{plan.bandwidth}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Step 2: Additional services */}
          {currentStep === 2 && (
            <Form>
              <h5>Additional Services</h5>
              {[
                { id: "ssl", name: "SSL Certificate", price: 12.99 },
                { id: "backup", name: "Daily Backups", price: 9.99 },
                { id: "cdn", name: "CDN", price: 4.99 },
              ].map((service) => (
                <Form.Check
                  key={service.id}
                  type="checkbox"
                  id={service.id}
                  label={`${service.name} - $${service.price}/mo`}
                  checked={addOns.includes(service.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAddOns([...addOns, service.id]);
                    } else {
                      setAddOns(addOns.filter((id) => id !== service.id));
                    }
                  }}
                />
              ))}
            </Form>
          )}

          {/* Step 3: Profile selection and order completion */}
          {currentStep === 3 && (
            <ProfileSelection
              onComplete={(profile) => {
                setProfileData(profile);
                // Submit order logic here using the gathered sample data.
                console.log("Order data:", {
                  plan: selectedPlan,
                  addOns,
                  profile,
                });
                handleCloseModal();
              }}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          {currentStep > 1 && currentStep < 3 && (
            <Button
              variant="outline-secondary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </Button>
          )}
          {currentStep < 2 && (
            <Button
              variant="primary"
              onClick={() => setCurrentStep(currentStep + 1)}
              disabled={currentStep === 1 && !selectedPlan}
            >
              Continue
            </Button>
          )}
          {currentStep === 3 && (
            <Button variant="success" onClick={handleCloseModal}>
              Complete Order
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StartHosting;
