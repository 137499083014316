import React, { useEffect } from "react";
import { Button, Row, Col, Alert } from "react-bootstrap";
import {
  FaCheckSquare,
  FaExclamationTriangle,
  FaSearch,
  FaArrowRight,
  FaQuestionCircle,
  FaArrowLeft,
} from "react-icons/fa";
import { localData } from "../functions/api/localData";

/**
 * @component
 * @name DomainResultStep
 * @description Step 2 - Display search result with appropriate messages and navigation buttons.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.searchResult - The result of the domain search containing status, domain, currency, price, etc.
 * @param {Function} props.onNext - Callback for proceeding to the next step (registration or transfer).
 * @param {Function} props.onCancel - Callback for canceling the entire operation.
 * @param {Function} props.onBack - Callback for going back to the previous step (re-search).
 * @returns {JSX.Element} The rendered component.
 */
const DomainResultStep = ({ searchResult, onNext, onCancel, onBack }) => {
  /**
   * @function useEffect - Persist the last search status in local data whenever `searchResult` changes.
   */
  useEffect(() => {
    (async () => {
      if (searchResult?.status) {
        await localData("save", "lastSearchStatus", searchResult.status);
      }
    })();
  }, [searchResult]);

  // If there's no searchResult or domain, show a fallback
  if (!searchResult || !searchResult.domain) {
    return (
      <div
        className="text-center"
        role="region"
        aria-labelledby="no-result-heading"
      >
        <h2 id="no-result-heading" className="visually-hidden">
          No Domain Search Result
        </h2>
        <Alert variant="warning" className="my-5">
          <FaQuestionCircle aria-hidden="true" size={24} className="me-2" />
          We couldn’t load the search result. Please try again.
        </Alert>
        <Row className="mt-4">
          <Col>
            <Button
              variant="info"
              onClick={onBack}
              className="w-100"
              aria-label="Change Domain"
            >
              <FaSearch aria-hidden="true" className="me-2" />
              Change Domain
            </Button>
          </Col>
          <Col>
            <Button
              variant="secondary"
              onClick={onCancel}
              className="w-100"
              aria-label="Cancel"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  // Determine if the domain is available
  const isAvailable = searchResult.status === "available";

  return (
    <div
      className="text-center"
      role="region"
      aria-labelledby="domain-result-heading"
    >
      <h2 id="domain-result-heading" className="visually-hidden">
        Domain Search Result
      </h2>

      {isAvailable ? (
        <div className="mb-5">
          <FaCheckSquare className="text-secondary" size={150} aria-hidden="true" />
          <p className="mt-5 mb-5">
            Good news! Your desired domain{" "}
            <strong>{searchResult.domain}</strong> is available for registration
            at{" "}
            <strong>
              {searchResult.currency}
              {searchResult.price}
            </strong>
            .
          </p>
        </div>
      ) : (
        <div className="mb-5">
          <FaExclamationTriangle className="text-warning" size={150} aria-hidden="true" />
          <p className="mt-5 mb-5">
            Unfortunately, the domain <strong>{searchResult.domain}</strong> is
            already taken. If you own it, you can still transfer it to us for{" "}
            <strong>
              {searchResult.currency}
              {searchResult.price}
            </strong>
            .
          </p>
        </div>
      )}

      <Row className="mt-5 mb-3">
        <Col>
          <Button
            variant="outline-warning"
            onClick={onBack}
            className="w-100"
            aria-label="Change Domain"
          >
            <FaArrowLeft aria-hidden="true" className="me-2" />
            Change Domain
          </Button>
        </Col>
        <Col>
          <Button
            variant="outline-secondary"
            onClick={onNext}
            className="w-100"
            aria-label={isAvailable ? "Register Domain" : "Transfer Domain"}
          >
            {isAvailable ? (
              <>
                Register Domain{" "}
                <FaArrowRight aria-hidden="true" className="ms-2" />
              </>
            ) : (
              <>
                Transfer Domain{" "}
                <FaArrowRight aria-hidden="true" className="ms-2" />
              </>
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DomainResultStep;
