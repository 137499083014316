/**
 * @file HeaderSection.jsx
 * @description A React component for rendering the header section in the domain management view.
 *              It includes the page title, description, navigation controls, and a modal for searching domains.
 * @author
 *   Masimba Maregedze, 1995
 */

import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleLeft, FaGlobe } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { Col } from "react-bootstrap";

const HeaderSection = () => {
  return (
    <>
      <Helmet>
        <title>Manage Domains - Marmasco Technologies</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center" style={{ gap: "1.5rem" }}>
          <NavLink
            to="/"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px green",
              marginRight: "10px",
            }}
          >
            <FaAngleLeft className="text-primary me-2" />
            Back
          </NavLink>
          <Col>
            <h2>
              Manage Domains <FaGlobe className="ms-2" />
            </h2>
            <p>
              Register, transfer, renew and manage DNS for your domain names.
            </p>
          </Col>
        </div>
      </div>
      <hr />
    </>
  );
};

export default HeaderSection;
