/**
 * @file SupportTicketsPage.jsx
 * @description A React component that allows support team members and customers to manage support tickets with pagination.
 */

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Badge,
  Alert,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { FaPlus, FaReply, FaEye, FaAngleLeft, FaLifeRing } from "react-icons/fa";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

/**
 * @component
 * @name SupportTicketsPage
 * @description Provides functionalities for support team members and customers to manage support tickets with pagination.
 * @returns {JSX.Element}
 */
const SupportTicketsPage = () => {
  // --------------------------------------------------------------------------
  // Sample Data for Mock API
  // --------------------------------------------------------------------------

  /**
   * Sample data to mimic API response for support tickets.
   * For demonstration purposes, we'll generate 60 sample tickets.
   */
  const generateSampleTickets = () => {
    const tickets = [];
    for (let i = 1; i <= 60; i++) {
      tickets.push({
        id: i,
        title: `Sample Ticket ${i}`,
        description: `This is the description for sample ticket number ${i}.`,
        status: i % 5 === 0 ? "Closed" : i % 3 === 0 ? "In Progress" : "Open",
        createdBy:
          i % 2 === 0
            ? "customer1@example.com"
            : i % 3 === 0
            ? "customer2@example.com"
            : "customer3@example.com",
        responses: [
          {
            responder: "support1@example.com",
            message: `This is a response from support to ticket ${i}.`,
            timestamp: `2025-01-${(i % 30) + 1}T10:00:00Z`,
          },
        ],
      });
    }
    return tickets;
  };

  const sampleTickets = generateSampleTickets();

  // --------------------------------------------------------------------------
  // State Management
  // --------------------------------------------------------------------------

  /**
   * Simulates the current user's role.
   * Possible values: "support" or "customer"
   * In a real application, this would come from authentication context or similar.
   */
  const [userRole, setUserRole] = useState("customer"); // Change to "support" to simulate support team

  /**
   * Holds all support tickets.
   */
  const [tickets, setTickets] = useState([]);

  /**
   * Indicates whether tickets are being fetched.
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Holds any error messages during fetching or updating tickets.
   */
  const [error, setError] = useState(null);

  /**
   * Controls the visibility of the "View Ticket" modal.
   */
  const [showViewModal, setShowViewModal] = useState(false);

  /**
   * Holds the currently selected ticket for viewing or responding.
   */
  const [selectedTicket, setSelectedTicket] = useState(null);

  /**
   * Controls the visibility of the "Create New Ticket" modal (for customers).
   */
  const [showCreateModal, setShowCreateModal] = useState(false);

  /**
   * Holds the input values for creating a new ticket.
   */
  const [newTicketTitle, setNewTicketTitle] = useState("");
  const [newTicketDescription, setNewTicketDescription] = useState("");

  /**
   * Holds the response message when responding to a ticket.
   */
  const [responseMessage, setResponseMessage] = useState("");

  /**
   * Pagination State Variables
   */
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 24;
  const [totalPages, setTotalPages] = useState(1);

  // --------------------------------------------------------------------------
  // Effect Hooks
  // --------------------------------------------------------------------------

  /**
   * Fetch tickets when the component mounts or when the user role changes.
   * In a real application, this would be an API call.
   */
  useEffect(() => {
    fetchTickets();
    // Reset pagination when user role changes
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  /**
   * Update total pages whenever tickets change
   */
  useEffect(() => {
    const calculatedTotalPages = Math.ceil(filteredTickets().length / ticketsPerPage);
    setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
  }, [tickets]);

  // --------------------------------------------------------------------------
  // Handler Functions
  // --------------------------------------------------------------------------

  /**
   * Fetches tickets based on the user role.
   * For support team, fetches all tickets.
   * For customers, fetches only their tickets.
   */
  const fetchTickets = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Filter tickets based on user role
      let fetchedTickets = [];
      if (userRole === "support") {
        // For support, prioritize tickets that are not closed
        fetchedTickets = sampleTickets
          .filter((ticket) => ticket.status !== "Closed")
          .sort((a, b) => a.id - b.id)
          .concat(sampleTickets.filter((ticket) => ticket.status === "Closed"));
      } else if (userRole === "customer") {
        // For simulation, assume the customer's email is "customer1@example.com"
        const customerEmail = "customer1@example.com";
        fetchedTickets = sampleTickets
          .filter((ticket) => ticket.createdBy === customerEmail)
          .filter((ticket) => ticket.status !== "Closed")
          .sort((a, b) => a.id - b.id)
          .concat(
            sampleTickets.filter(
              (ticket) => ticket.createdBy === customerEmail && ticket.status === "Closed"
            )
          );
      }

      setTickets(fetchedTickets);
    } catch (err) {
      setError("Failed to fetch tickets. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Filters tickets to prioritize those that are not closed.
   * @returns {Array} - Filtered tickets array
   */
  const filteredTickets = () => {
    return tickets.filter((ticket) => ticket.status !== "Closed");
  };

  /**
   * Handles opening the "View Ticket" modal.
   * @param {Object} ticket - The ticket to view.
   */
  const handleViewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setResponseMessage("");
    setShowViewModal(true);
  };

  /**
   * Handles closing the "View Ticket" modal.
   */
  const handleCloseViewModal = () => {
    setShowViewModal(false);
    setSelectedTicket(null);
    setResponseMessage("");
  };

  /**
   * Handles opening the "Create New Ticket" modal.
   */
  const handleOpenCreateModal = () => {
    setNewTicketTitle("");
    setNewTicketDescription("");
    setShowCreateModal(true);
  };

  /**
   * Handles closing the "Create New Ticket" modal.
   */
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewTicketTitle("");
    setNewTicketDescription("");
  };

  /**
   * Handles creating a new support ticket (for customers).
   * @param {Event} e - The form submission event.
   */
  const handleCreateTicket = async (e) => {
    e.preventDefault();

    if (!newTicketTitle.trim() || !newTicketDescription.trim()) {
      setError("Please provide both a title and a description for the ticket.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Create new ticket object
      const newTicket = {
        id: sampleTickets.length + 1,
        title: newTicketTitle,
        description: newTicketDescription,
        status: "Open",
        createdBy: "customer1@example.com", // Simulated customer email
        responses: [],
      };

      // Update tickets state by adding the new ticket at the beginning
      setTickets([newTicket, ...tickets]);

      // Close the modal
      handleCloseCreateModal();
    } catch (err) {
      setError("Failed to create a new ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles responding to a ticket.
   * @param {Event} e - The form submission event.
   */
  const handleRespondToTicket = async (e) => {
    e.preventDefault();

    if (!responseMessage.trim()) {
      setError("Please enter a response message.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update the selected ticket's responses
      const updatedTickets = tickets.map((ticket) => {
        if (ticket.id === selectedTicket.id) {
          const newResponse = {
            responder:
              userRole === "support"
                ? "support1@example.com"
                : "customer1@example.com", // Simulated responder email
            message: responseMessage,
            timestamp: new Date().toISOString(),
          };
          return {
            ...ticket,
            responses: [...ticket.responses, newResponse],
            status:
              userRole === "support" && ticket.status !== "Closed"
                ? "In Progress"
                : ticket.status,
          };
        }
        return ticket;
      });

      setTickets(updatedTickets);

      // Update the selected ticket
      const updatedTicket = updatedTickets.find(
        (ticket) => ticket.id === selectedTicket.id
      );
      setSelectedTicket(updatedTicket);

      // Clear the response message
      setResponseMessage("");
    } catch (err) {
      setError("Failed to respond to the ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles closing a ticket (for support team).
   * @param {number} ticketId - The ID of the ticket to close.
   */
  const handleCloseTicket = async (ticketId) => {
    setIsLoading(true);
    setError(null);

    try {
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update the ticket's status to "Closed"
      const updatedTickets = tickets.map((ticket) => {
        if (ticket.id === ticketId) {
          return { ...ticket, status: "Closed" };
        }
        return ticket;
      });

      setTickets(updatedTickets);

      // If the closed ticket is currently selected, update it
      if (selectedTicket && selectedTicket.id === ticketId) {
        const updatedTicket = updatedTickets.find(
          (ticket) => ticket.id === ticketId
        );
        setSelectedTicket(updatedTicket);
      }
    } catch (err) {
      setError("Failed to close the ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };


  /**
   * Handles navigating to a specific page.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // --------------------------------------------------------------------------
  // Utility Functions
  // --------------------------------------------------------------------------

  /**
   * Returns the badge color based on ticket status.
   * @param {string} status - The status of the ticket.
   * @returns {string} - Bootstrap variant string.
   */
  const getBadgeVariant = (status) => {
    switch (status) {
      case "Open":
        return "primary";
      case "In Progress":
        return "warning";
      case "Closed":
        return "success";
      default:
        return "secondary";
    }
  };

  // --------------------------------------------------------------------------
  // Rendering Helpers
  // --------------------------------------------------------------------------

  /**
   * Renders the table of support tickets.
   * @returns {JSX.Element}
   */
  const renderTicketsTable = () => {
    const filtered = filteredTickets();

    // Calculate pagination
    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = filtered.slice(indexOfFirstTicket, indexOfLastTicket);

    if (isLoading) {
      return (
        <div className="text-center my-5">
          <Spinner animation="border" variant="primary" />
          <p className="mt-3">Loading tickets...</p>
        </div>
      );
    }

    if (error) {
      return (
        <Alert variant="danger" className="my-3">
          {error}
        </Alert>
      );
    }

    if (filtered.length === 0) {
      return (
        <div className="text-center my-5">
          <p>No tickets found.</p>
          {userRole === "customer" && (
            <Button variant="primary" onClick={handleOpenCreateModal}>
              <FaPlus className="me-2" />
              Create New Ticket
            </Button>
          )}
        </div>
      );
    }

    return (
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              {userRole === "support" && <th>Customer</th>}
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentTickets.map((ticket, index) => (
              <tr key={ticket.id}>
                <td>{indexOfFirstTicket + index + 1}</td>
                <td>{ticket.title}</td>
                {userRole === "support" && <td>{ticket.createdBy}</td>}
                <td>
                  <Badge bg={getBadgeVariant(ticket.status)}>
                    {ticket.status}
                  </Badge>
                </td>
                <td>
                  <Button
                    variant="info"
                    size="sm"
                    className="me-2"
                    onClick={() => handleViewTicket(ticket)}
                  >
                    <FaEye className="me-1" />
                    View
                  </Button>
                  {userRole === "support" && ticket.status !== "Closed" && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleCloseTicket(ticket.id)}
                    >
                      Close
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </>
    );
  };

  /**
   * Renders the "View Ticket" modal.
   * @returns {JSX.Element}
   */
  const renderViewModal = () => {
    if (!selectedTicket) return null;

    return (
      <Modal
        show={showViewModal}
        onHide={handleCloseViewModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{selectedTicket.title}</h5>
          <p>{selectedTicket.description}</p>
          <p>
            <strong>Status:</strong>{" "}
            <Badge bg={getBadgeVariant(selectedTicket.status)}>
              {selectedTicket.status}
            </Badge>
          </p>
          {userRole === "support" && (
            <p>
              <strong>Customer:</strong> {selectedTicket.createdBy}
            </p>
          )}
          <hr />
          <h6>Responses:</h6>
          {selectedTicket.responses.length === 0 ? (
            <p>No responses yet.</p>
          ) : (
            selectedTicket.responses.map((response, idx) => (
              <div key={idx} className="mb-3">
                <strong>{response.responder}</strong>{" "}
                <Badge bg="secondary">
                  {new Date(response.timestamp).toLocaleString()}
                </Badge>
                <p>{response.message}</p>
                <hr />
              </div>
            ))
          )}
          {/* Response Form */}
          <Form onSubmit={handleRespondToTicket}>
            <Form.Group controlId="responseMessage" className="mb-3">
              <Form.Label>Response</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your response..."
                value={responseMessage}
                onChange={(e) => setResponseMessage(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
              <FaReply className="me-2" />
              {isLoading ? "Responding..." : "Submit Response"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /**
   * Renders the "Create New Ticket" modal (for customers).
   * @returns {JSX.Element}
   */
  const renderCreateModal = () => {
    if (userRole !== "customer") return null;

    return (
      <Modal
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateTicket}>
            <Form.Group controlId="newTicketTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ticket title"
                value={newTicketTitle}
                onChange={(e) => setNewTicketTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="newTicketDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Describe your issue in detail..."
                value={newTicketDescription}
                onChange={(e) => setNewTicketDescription(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="success" type="submit" disabled={isLoading}>
              <FaPlus className="me-2" />
              {isLoading ? "Submitting..." : "Submit Ticket"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // --------------------------------------------------------------------------
  // Main Render
  // --------------------------------------------------------------------------

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Support Tickets - Marmasco Technologies</title>
      </Helmet>
      {/* Back Button and Title */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <NavLink
            title="Back"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px green",
              marginRight: "10px",
            }}
            to={`/`}
          >
            <FaAngleLeft
              style={{ marginRight: "0.5rem" }}
              className="text-primary"
            />{" "}
            Back
          </NavLink>
                    <Col>
                    <h2>
                      Support Tickets <FaLifeRing className="ms-2" />
                    </h2>
                    <p>Manage your support tickets, create, respond and view support requests.</p>
                  </Col>
        </div>
      </div>
      <hr />

      {/* Create Ticket Button (for customers) */}
      {userRole === "customer" && (
        <Row className="mb-3">
          <Col className="text-end">
            <Button variant="primary" onClick={handleOpenCreateModal}>
              <FaPlus className="me-2" />
              Create New Ticket
            </Button>
          </Col>
        </Row>
      )}

      {/* Tickets Table */}
      <Row>
        <Col>{renderTicketsTable()}</Col>
      </Row>

      {/* View Ticket Modal */}
      {renderViewModal()}

      {/* Create New Ticket Modal */}
      {renderCreateModal()}
    </Container>
  );
};

/**
 * @component
 * @name PaginationComponent
 * @description Renders pagination controls.
 * @param {Object} props - The component props.
 * @param {number} props.currentPage - The current active page.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.onPageChange - The function to call when page changes.
 * @returns {JSX.Element}
 */
const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const paginationItems = [];

  // Determine the range of page numbers to display
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > 5) {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  for (let number = startPage; number <= endPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="justify-content-center">
      <Pagination.First
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {startPage > 1 && <Pagination.Ellipsis disabled />}
      {paginationItems}
      {endPage < totalPages && <Pagination.Ellipsis disabled />}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

// Adding PropTypes validations to PaginationComponent
PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default SupportTicketsPage;
