// Import the initDB function to initialize the database connection
import { initDB } from "../config/initDB";
import notifyProfileChanged from "../events/profileChanged";

/**
 * Function to add or update a user profile in the database.
 * @param {Object} profile - The profile data to be saved.
 * @returns {Promise<void>}
 */
export const setUserProfile = async (profile) => {
  try {
    // Initialize the database connection
    const db = await initDB();

    // Use a constant ID ("userProfile") for saving the single profile data
    await db.put("profile", { ...profile, id: "userProfile" });
    notifyProfileChanged();
  } catch (error) {
    console.error("Error setting user profile:", error);
    throw error; // Rethrow the error after logging for better debugging
  }
};

/**
 * Function to retrieve the user profile from the database.
 * @returns {Promise<Object>} The user profile data or null if not found.
 */
export const getUserProfile = async () => {
  try {
    // Initialize the database connection
    const db = await initDB();

    // Retrieve the user profile using the constant ID
    return await db.get("profile", "userProfile");
  } catch (error) {
    console.error("Error getting user profile:", error);
    throw error; // Rethrow the error for handling in the calling code
  }
};
