/**
 * @file AboutPage.jsx
 * @description A fully responsive About Us page that showcases Marmasco Technologies,
 *              emphasizing our professional Web Hosting and Domain Registration services.
 *              The page is divided into key sections:
 *                - Company Overview
 *                - Web Hosting Expertise (Cloud VPS Hosting)
 *                - Domain Registration Services
 *                - Founder’s Biography
 *                - Mission & Vision
 *                - Our Commitment to Excellence
 * @author
 *   Masimba Maregedze
 * @version 1.5.2
 */

import React, { useState, useEffect } from "react"; // modified: added useState, useEffect
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import featImg from "../assets/bg.jpg";
import { runData } from "../functions/api/processor"; // added runData

/**
 * @function AboutPage
 * @description Renders a professional About Us page detailing our company background,
 *              core services, and commitment to quality in web hosting and domain registration.
 *              The layout uses Bootstrap components for a responsive and visually appealing design.
 * @returns {JSX.Element} A production-ready About Us page component.
 */
const AboutPage = () => {
  // Dynamic SEO state using API fallback
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  // State to manage loading and error status during SEO data fetching.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "about" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Display an animated loading spinner while SEO data is being fetched.
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Display an error message if SEO data fails to load.
  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      {/* Header Section */}
      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Main Content Section */}
      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        {/* Section Header */}
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Background</h2>
            <p className="lead text-muted">
              Founded in 2015 and formally registered on January 3rd, 2019,
              Marmasco Technologies is a leading provider of digital solutions.
              Based in Chitungwiza, Zimbabwe, our expertise in Web Hosting and
              Domain Registration services has earned us a global reputation for
              reliability, security, and scalability.
            </p>
          </Col>
        </Row>

        {/* Web Hosting and Domain Registration Services */}
        <Row className="mb-4">
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="mb-4 shadow-sm w-100">
              <Card.Body>
                <Card.Title>Our Web Hosting Expertise</Card.Title>
                <Card.Text>
                  We specialize in robust and secure Cloud VPS Hosting
                  solutions, engineered to deliver superior performance and
                  scalability for growing businesses. Our infrastructure is
                  designed to ensure maximum uptime and seamless integration
                  with modern digital requirements.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="mb-4 shadow-sm w-100">
              <Card.Body>
                <Card.Title>Domain Registration Services</Card.Title>
                <Card.Text>
                  Secure your unique online identity with our comprehensive
                  domain registration services. Enjoy competitive pricing,
                  expert guidance for selecting the perfect domain, and seamless
                  integration with our hosting solutions.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Founder’s Biography and Mission */}
        <Row className="mb-4">
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="mb-4 shadow-sm w-100">
              <Card.Body>
                <Card.Title>Meet Our Founder</Card.Title>
                <Card.Text>
                  Masimba Maregedze, a seasoned Software Engineer with over{" "}
                  {new Date().getFullYear() - 2015} years of industry
                  experience, founded Marmasco Technologies. His vision and
                  commitment to excellence have driven our success in delivering
                  professional digital solutions.
                </Card.Text>
                <Button
                  className="w-100"
                  variant="outline-primary"
                  href="http://www.maregedze.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="d-flex align-items-stretch">
            <Card className="mb-4 shadow-sm w-100">
              <Card.Body>
                <Card.Title>Our Mission & Vision</Card.Title>
                <Card.Text>
                  Our mission is to empower businesses by providing top-tier
                  Cloud VPS Hosting and Domain Registration services. We strive
                  to be a global leader in digital solutions, continuously
                  delivering innovation, reliability, and unmatched customer
                  support.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Our Commitment */}
        <Row className="mb-0">
          <Col>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Our Commitment</Card.Title>
                <Card.Text>
                  At Marmasco Technologies, we are dedicated to maintaining the
                  highest standards of quality and professionalism. Your success
                  is our success—our team works relentlessly to ensure your
                  online presence remains robust, secure, and future-ready.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutPage;
