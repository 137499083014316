export default function notifyLogout(changeDetails = {}) {
    const event = new CustomEvent("loggedOut", {
      detail: {
        message: "The session has been logged out",
        ...changeDetails,
        timestamp: Date.now(),
      },
    });
    window.dispatchEvent(event);
  }
  