import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
// New import for phone input with dial codes and flags
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

/**
 * @constant {string[]} businessCategories
 * @description A comprehensive list of common business categories representing widely recognized industries and sectors.
 * This array can be extended or adjusted to fit specific business domain requirements.
 */
const businessCategories = [
  "Agriculture",
  "Automotive",
  "Beauty & Personal Care",
  "Business Services",
  "Construction",
  "Consulting",
  "Consumer Services",
  "Education",
  "Entertainment",
  "Finance & Insurance",
  "Food & Beverage",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Manufacturing",
  "Media & Communications",
  "Nonprofit",
  "Real Estate",
  "Retail",
  "Telecommunications",
  "Transportation & Logistics",
  "Travel & Tourism",
  "Wholesale",
];

// Freeze the array to prevent accidental modifications at runtime.
Object.freeze(businessCategories);

const CreateAccountModal = ({
  show,
  onHide,
  accountFormData,
  setAccountFormData,
  accountFormPage,
  setAccountFormPage,
  handleAccountCreationSubmit,
  // removed defaultEmail
}) => {
  // Remove or comment out the default email pre-fill effect so it doesn't override new input:
  // useEffect(() => {
  //   if (defaultEmail) {
  //     if (accountFormData.companyEmail === "") {
  //       setAccountFormData((prev) => ({ ...prev, companyEmail: defaultEmail }));
  //     }
  //     if (!accountFormData.userEmail) {
  //       setAccountFormData((prev) => ({ ...prev, userEmail: defaultEmail }));
  //     }
  //   }
  // }, [defaultEmail]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create an Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {accountFormPage === 1 && (
          <Form>
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Company Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                value={accountFormData.companyName}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    companyName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Company Services
              </Form.Label>
              {/* Use Typeahead for multiple services selection */}
              <Typeahead
                id="company-services-typeahead"
                multiple
                allowNew
                newSelectionPrefix="Add: "
                options={businessCategories}
                placeholder="Select or add services offered"
                onChange={(selected) => {
                  const selections = selected.map((item) =>
                    typeof item === "string" ? item : item.label || item
                  );
                  setAccountFormData({
                    ...accountFormData,
                    serviceOffered: selections,
                  });
                }}
                selected={
                  Array.isArray(accountFormData.serviceOffered)
                    ? accountFormData.serviceOffered
                    : []
                }
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Company Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter company email"
                value={accountFormData.companyEmail}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    companyEmail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Use company email for login?"
                checked={accountFormData.useCompanyEmailForLogin}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    useCompanyEmailForLogin: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </Form>
        )}
        {accountFormPage === 2 && (
          <Form>
            {/* Reordered fields */}
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                value={accountFormData.firstName || ""}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    firstName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={accountFormData.lastName || ""}
                onChange={(e) =>
                  setAccountFormData({
                    ...accountFormData,
                    lastName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            {!accountFormData.useCompanyEmailForLogin && (
              <Form.Group className="mb-4">
                <Form.Label style={{ fontWeight: "bold" }}>
                  User Email
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter user email"
                  value={accountFormData.userEmail || ""}
                  onChange={(e) =>
                    setAccountFormData({
                      ...accountFormData,
                      userEmail: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            )}
            <Form.Group className="mb-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                WhatsApp Number
              </Form.Label>
              <PhoneInput
                country={"zw"}
                value={accountFormData.phone}
                onChange={(phone) =>
                  setAccountFormData({ ...accountFormData, phone })
                }
                inputStyle={{ width: "100%" }}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          {accountFormPage === 1 ? (
            <span
              style={{
                cursor: "pointer",
                color: "#007bff",
                textDecoration: "underline",
                alignSelf: "center",
              }}
              onClick={onHide}
            >
              Already Registered?
            </span>
          ) : (
            <Button
              variant="secondary"
              onClick={() => setAccountFormPage(accountFormPage - 1)}
            >
              Back
            </Button>
          )}
        </div>
        <div>
          {accountFormPage < 2 ? (
            <Button
              variant="primary"
              onClick={() => setAccountFormPage(accountFormPage + 1)}
            >
              Continue
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAccountCreationSubmit}>
              Submit
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreateAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  accountFormData: PropTypes.object.isRequired,
  setAccountFormData: PropTypes.func.isRequired,
  accountFormPage: PropTypes.number.isRequired,
  setAccountFormPage: PropTypes.func.isRequired,
  handleAccountCreationSubmit: PropTypes.func.isRequired,
  // removed defaultEmail PropType
};

export default CreateAccountModal;
