import { runData } from "../functions/api/processor";

export const checkEmail = async (email) =>
  await runData({ email: email.trim() }, "/web/start/check-email/");

export const pinLogin = async (email, password) =>
  await runData({ email: email.trim(), password }, "/web/start/pin-login/");

export const requestOtp = async (email) =>
  await runData({ email: email.trim() }, "/web/start/request-otp/");

export const otpLogin = async (email, otpStr) =>
  await runData({ email: email.trim(), otp: otpStr }, "/web/start/otp-login/");

export const createAccount = async (accountData) =>
  await runData(accountData, "/web/start/create-account/");
