import { useState, useEffect } from "react";
import { isAuthenticated } from "../utils/auth";

/**
 * Custom hook to watch the session status.
 * @returns {boolean} - Returns whether the session is valid or not.
 */
const useSessionWatch = () => {
  const [sessionValid, setSessionValid] = useState(null); // Initially null to indicate loading state

  useEffect(() => {
    const checkSession = () => {
      try {
        const isValid = isAuthenticated();
        setSessionValid(isValid);
      } catch (error) {
        console.error("Error checking session validity:", error);
        setSessionValid(false); // If there's an error, assume session is not valid
      }
    };

    // Initial session check
    checkSession();

    // Handle session changes via BroadcastChannel
    const broadcast = new BroadcastChannel("sessionChannel");
    const handleBroadcastMessage = (event) => {
      if (typeof event.data === "boolean") {
        checkSession();
      }
    };

    broadcast.onmessage = handleBroadcastMessage;

    // Cleanup broadcast listener
    return () => {
      broadcast.close();
    };
  }, []); // Empty dependency array to run this effect only on mount and unmount

  return sessionValid;
};

export default useSessionWatch;
