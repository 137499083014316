// src/components/Navigation.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // For prop validation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlog,
  faGlobe,
  faHomeAlt,
  faLayerGroup,
  faLifeRing,
  faServer,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import { isAuthenticated, logout } from "../utils/auth";
import { Button } from "react-bootstrap";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../functions/api/processor";
import { FaUserAlt } from "react-icons/fa";

/**
 * Navigation component for Marmasco Technologies.
 * Renders navigation options with distinct styling:
 * - Section headings (e.g., "Resources") are bold and non-clickable.
 * - Clickable navigation links have a normal font weight.
 * Also includes a sticky logout/account button that does not cause horizontal scrolling.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.navClosed - Indicates if navigation is collapsed.
 * @param {Function} [props.toggleNav] - Function to toggle navigation (optional on mobile).
 * @returns {JSX.Element}
 */
const Navigation = ({ navClosed, toggleNav }) => {
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();
  const authenticated = isAuthenticated();

  // Handle user logout.
  const handleLogout = async () => {
    setProcessing(true);
    try {
      const data = {};
      const response = await runData(data, "/api/logout/");
      if (response.status === 200) {
        setReport({
          show: true,
          message:
            "You have successfully logged out. Thank you for visiting, and we look forward to welcoming you back soon.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred while logging out.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while logging out.",
        type: "error",
      });
    } finally {
      logout();
      setProcessing(false);
    }
  };

  // State to track if the view is mobile.
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 850);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine heading text visibility.
  // On mobile, always show the title; on larger screens, show only if nav is open.
  const getHeadingText = (title) => (isMobile ? title : navClosed ? "" : title);

  return (
    <div
      className={`navcontainer ${navClosed ? "navclose" : ""}`}
      style={{
        boxShadow: "10px 0 15px -5px rgba(0, 0, 0, 0.3)",
        minWidth: "100px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <nav className="nav" style={{ boxSizing: "border-box" }}>
        {/* Navigation Options */}
        <div className="nav-options mt-2">
          {/* Main Navigation Links */}
          {[
            { to: "/", label: "Welcome", icon: faHomeAlt },
            { to: "/domains/", label: "Domains", icon: faGlobe },
            { to: "/hosting/", label: "Hosting", icon: faServer },
            { to: "/suite/", label: "MTSuite", icon: faLayerGroup },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option text-truncate ${
                navClosed ? "hidden" : ""
              }`}
              onClick={isMobile ? toggleNav : undefined}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3 className="mb-0 ms-2 fw-normal link-text">
                {getHeadingText(link.label)}
              </h3>
            </NavLink>
          ))}

          {/* Section Heading for Resources (bold and non-clickable) */}
          <h6 className="mt-5 section-heading fw-bold">
            {getHeadingText("Resources")}
          </h6>
          {[
            { to: "/blog/", label: "Blog", icon: faBlog },
            { to: "/help/", label: "Help", icon: faLifeRing },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : undefined}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3 className="mb-0 ms-2 fw-normal link-text">
                {getHeadingText(link.label)}
              </h3>
            </NavLink>
          ))}
        </div>

        {/* Sticky Logout/Account Button */}
        {authenticated ? (
          <Button
            onClick={handleLogout}
            className="nav-option option logout-button mt-4 py-4"
            style={{
              borderRadius: "0",
              width: "100%",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color="red"
              className="nav-img"
            />
            <h3
              className={`${
                navClosed ? "hidden" : ""
              } mb-0 ms-2 fw-normal link-text`}
            >
              Logout
            </h3>
          </Button>
        ) : (
          <Link
            to="/start/"
            className="nav-option option logout-button mt-4 py-4 d-flex align-items-center"
            style={{
              borderRadius: "0",
              width: "100%",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <FaUserAlt className="nav-img me-2" color="white" size={30} />
            <h3
              className={`${
                navClosed ? "hidden" : ""
              } mb-0 ms-2 fw-normal link-text`}
            >
              Account
            </h3>
          </Link>
        )}
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func,
};

export default Navigation;
