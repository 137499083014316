import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import ProtectedRoute from "../functions/ProtectedRoute";
import PublicRoute from "../functions/PublicRoute";
import Dashboard from "../dashboard/Dashboard";
import GetStarted from "../start/GetStarted";
import FourOFour from "../includes/Four0Four";
import UserProfile from "../account/profile";
import ManageDomains from "../domains/ManageDomains";
import ManageWebHosting from "../hosting/ManageHosting";
import SupportTicketsPage from "../dashboard/Support";
import BillingPage from "../dashboard/Billing";
import HomePage from "../website/Home";
import HostingPage from "../website/Hosting";
import DomainsPage from "../website/Domains";
import AboutPage from "../website/About";
import ContactPage from "../website/Contact";
import HelpPage from "../website/Help";
import PrivacyPage from "../website/Privacy";
import TermsPage from "../website/Terms";
import CheckoutPage from "../website/Checkout";
import BlogPage from "../website/Blog";
import BlogPostPage from "../website/BlogPost";
import HelpPostPage from "../website/HelpPost";
import SuitePage from "../website/Suite";

/**
 * Main App component that handles routing and authentication.
 * @component
 * @returns {JSX.Element}
 */
const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/suite/" element={<SuitePage />} />
          <Route exact path="/hosting/" element={<HostingPage />} />
          <Route exact path="/domains/" element={<DomainsPage />} />
          <Route exact path="/about/" element={<AboutPage />} />
          <Route exact path="/contact/" element={<ContactPage />} />
          <Route exact path="/help/" element={<HelpPage />} />
          <Route exact path="/privacy-policy/" element={<PrivacyPage />} />
          <Route exact path="/terms-service/" element={<TermsPage />} />
          <Route exact path="/blog/" element={<BlogPage />} />
          <Route exact path="/blog/:post/" element={<BlogPostPage />} />
          <Route exact path="/help/:post/" element={<HelpPostPage />} />
          <Route
            exact
            path="/checkout/"
            element={<ProtectedRoute element={<CheckoutPage />} />}
          />
          <Route
            path="/start/"
            element={<PublicRoute element={<GetStarted />} />}
          />
          <Route
            exact
            path="/account/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            exact
            path="/account/profile/"
            element={<ProtectedRoute element={<UserProfile />} />}
          />
          <Route
            exact
            path="/account/domains/"
            element={<ProtectedRoute element={<ManageDomains />} />}
          />
          <Route
            exact
            path="/account/hosting/"
            element={<ProtectedRoute element={<ManageWebHosting />} />}
          />
          <Route
            exact
            path="/account/support/"
            element={<ProtectedRoute element={<SupportTicketsPage />} />}
          />
          <Route
            exact
            path="/account/billing/"
            element={<ProtectedRoute element={<BillingPage />} />}
          />
          <Route path="*" element={<FourOFour />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
