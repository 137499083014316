/**
 * @file ContactPage.jsx
 * @description A professionally designed, fully responsive Contact Us page for Marmasco Technologies.
 *              This page provides essential contact details and support information, including:
 *                - Phone numbers (WhatsApp Bot/Calls & WhatsApp Chat/Calls)
 *                - Email addresses (Technical and Billing Support)
 *                - Physical address
 *                - Support details (Availability, Reliability, Professionalism, and Technical assistance)
 * @author
 *   Masimba Maregedze
 * @version 1.5.1
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import featImg from "../assets/bg.jpg";
import { runData } from "../functions/api/processor";

/**
 * @function ContactPage
 * @description Renders a production-ready Contact Us page that provides comprehensive contact details and support information.
 *              Utilizes Bootstrap components for a responsive and professional layout.
 * @returns {JSX.Element} A Contact Us page component.
 */
const ContactPage = () => {
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: featImg,
    canonical_url: window.location.href,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await runData({ page: "contact" }, "/web/pages/");
        if (response.status === 200 && response.data.seo) {
          let seoResponse = response.data.seo;
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>

      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      <Container
        id="content-start"
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Contacts</h2>
            <p className="lead text-muted">
              Get in touch with us using the contact details below.
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <Card className="shadow-sm h-100">
              <Card.Body>
                <Card.Title>Contact Details</Card.Title>
                <Card.Text>
                  <strong>Phone Numbers:</strong>
                </Card.Text>
                <ul>
                  <li>+263 78 583 3095</li>
                  <li>+263 71 460 2698</li>
                </ul>
                <Card.Text>
                  <strong>Email Addresses:</strong>
                </Card.Text>
                <ul>
                  <li>support@marmasco.com</li>
                  <li>finance@marmasco.com</li>
                </ul>
                <Card.Text>
                  <strong>Physical Address:</strong>
                </Card.Text>
                <p>15992 Unit P, Seke, Chitungwiza - Zimbabwe</p>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="shadow-sm h-100">
              <Card.Body>
                <Card.Title>Support Information</Card.Title>
                <Card.Text>
                  <strong>Availability:</strong> Our support team is available
                  on weekdays from 8am to 5pm, with premium support offering
                  24/7 assistance.
                </Card.Text>
                <Card.Text>
                  <strong>Reliability:</strong> We are committed to providing
                  dependable service.
                </Card.Text>
                <Card.Text>
                  <strong>Professionalism:</strong> Our team ensures quality
                  interactions and resolutions.
                </Card.Text>
                <Card.Text>
                  <strong>Technical Assistance:</strong> Our experts are ready
                  to help you with any technical challenges.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactPage;
