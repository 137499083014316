/**
 * @file BlogPostPage.jsx
 * @description A production-ready single blog post page for Marmasco Technologies.
 *              This component dynamically loads and displays a blog post based on the URL parameter.
 *              It re-evaluates its content whenever the ":post" parameter changes, ensuring consistency.
 *              A related posts section is included at the bottom to showcase additional relevant articles.
 * @author
 *   Masimba Maregedze
 * @version 1.1.3
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { runData } from "../functions/api/processor"; // API call
import blogImage from "../assets/bg.jpg"; // Use fallback image

const BlogPostPage = () => {
  const { post } = useParams();
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "Loading...",
    keywords: "",
    og_title: "Loading...",
    og_description: "Loading...",
    og_image: blogImage,
    canonical_url: window.location.href,
  });
  const [blogPost, setBlogPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        // Fetch dynamic SEO and blog post data along with related posts
        const response = await runData({ page: post }, "/web/pages/blog/");
        if (response.status === 200 && response.data.blogPost) {
          setBlogPost(response.data.blogPost);
          setRelatedPosts(response.data.relatedPosts || []);
          // Update seoData from API
          if (response.data.seo) {
            let seoResponse = response.data.seo;
            if (
              seoResponse.canonical_url &&
              seoResponse.canonical_url.startsWith("/")
            ) {
              let hostname = window.location.hostname;
              if (!hostname.startsWith("www.")) {
                hostname = "www." + hostname;
              }
              // Insert "/blog" between hostname and the returned canonical_url
              seoResponse.canonical_url =
                window.location.protocol +
                "//" +
                hostname +
                "/blog" +
                seoResponse.canonical_url;
            }
            setSeoData(seoResponse);
          }
        } else {
          setError("Blog post not found.");
        }
      } catch (err) {
        console.error("Failed to load blog post data:", err);
        setError(
          err.message || "An error occurred while loading blog post data."
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [post]);

  if (loading) {
    return (
      <Container className="py-4 text-center" style={{ minHeight: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-4 text-center" style={{ minHeight: "100vh" }}>
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  if (!blogPost) {
    return (
      <Container className="py-4 text-center">
        <h2>Post Not Found</h2>
        <p>We couldn't find the blog post you're looking for.</p>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <Helmet>
        <title>{seoData.title} - Marmasco Technologies</title>
        <meta name="description" content={seoData.meta_description} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "headline": "${seoData.title} - Marmasco Technologies",
              "url": "${seoData.canonical_url}",
              "description": "${seoData.meta_description}"
            }
          `}
        </script>
      </Helmet>
      <Row className="mb-5">
        <Col>
          <h1>{blogPost.title}</h1>
          <p className="text-muted">
            {new Date(blogPost.date).toLocaleDateString()}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow-sm mb-4">
            <Card.Img
              variant="top"
              src={blogPost.image}
              alt={blogPost.title}
              style={{ maxHeight: "300px", objectFit: "cover" }}
            />
            <Card.Body>
              <Card.Text>{blogPost.content}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {relatedPosts.length > 0 && (
        <>
          <Row className="mt-5 mb-3">
            <Col>
              <h2>Related Posts</h2>
            </Col>
          </Row>
          <Row>
            {relatedPosts.map((related) => (
              <Col
                key={related.id}
                md={4}
                className="d-flex align-items-stretch mb-4"
                style={{ padding: "0 15px" }}
              >
                <Card
                  className="shadow-sm w-100"
                  style={{ marginBottom: "30px" }}
                >
                  <Card.Img
                    variant="top"
                    src={related.image}
                    alt={related.title}
                    style={{ maxHeight: "200px", objectFit: "cover" }}
                  />
                  <Card.Body className="d-flex flex-column p-3">
                    <Card.Title>{related.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {new Date(related.date).toLocaleDateString()}
                    </Card.Subtitle>
                    <Card.Text>{related.summary}</Card.Text>
                    <div className="mt-auto">
                      <Link
                        to={`/blog/${related.slug}/`}
                        className="btn btn-primary w-100"
                      >
                        Read More
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default BlogPostPage;
