/**
 * @file HostingPage.jsx
 * @description A professionally designed, fully responsive page detailing our comprehensive cloud VPS hosting solutions.
 * This page provides complete information on our Cloud VPS Hosting packages, including detailed features and packages.
 * Anchors and additional navigation have been optimized for a Cloud VPS focused approach.
 * This version includes an animated loading spinner until SEO data is ready and an error screen if data loading fails.
 * The VPS section now features a full-width, centered location selector to display a server configurator
 * which filters servers based on user selection.
 * @author
 * Masimba Maregedze
 * @version 1.6.0
 */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import hostingImage from "../assets/hosting.jpg";
import ServerConfigurator from "./helpers/ServerConfigurator"; // Import the Configurator
import { runData } from "../functions/api/processor";

const HostingPage = () => {
  // State for VPS packages, locations, selected location, SEO, loading, and error.
  const [vpsPackages, setVpsPackages] = useState([]);
  // locations: array of objects: { system, title }
  const [locations, setLocations] = useState([]);
  // selectedLocation stores the system code.
  const [selectedLocation, setSelectedLocation] = useState("");
  const [seoData, setSeoData] = useState({
    title: "Loading...",
    meta_description: "",
    keywords: "",
    og_title: "",
    og_description: "",
    og_image: hostingImage,
    canonical_url: window.location.href,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Determines the location (system code) with the lowest monthly price.
   * @param {Array} packages - Array of VPS package objects.
   * @param {Array} locs - Array of unique location objects.
   * @returns {string} - The system code of the location with the lowest monthly price.
   */
  const getLowestPriceLocation = (packages, locs) => {
    let lowestLocation = null;
    let lowestPrice = Infinity;
    locs.forEach((loc) => {
      const availablePackages = packages.filter(
        (pkg) =>
          pkg.groupedPrices &&
          pkg.groupedPrices[loc.system] &&
          pkg.groupedPrices[loc.system].prices &&
          pkg.groupedPrices[loc.system].prices.length > 0
      );
      if (availablePackages.length > 0) {
        const minPriceForLoc = Math.min(
          ...availablePackages.map((pkg) =>
            parseFloat(
              pkg.groupedPrices[loc.system].prices[0].price_monthly.gross
            )
          )
        );
        if (minPriceForLoc < lowestPrice) {
          lowestPrice = minPriceForLoc;
          lowestLocation = loc.system;
        }
      }
    });
    return lowestLocation;
  };

  useEffect(() => {
    (async () => {
      try {
        // Retrieve SEO data.
        const response = await runData({ page: "hosting" }, "/web/pages/");
        // Retrieve VPS packages (server types) and locations from the backend.
        const prices = await runData({}, "/web/cloud/servers/types/");
        if (
          response.status === 200 &&
          response.data.seo &&
          prices.status === 200 &&
          prices.data.server_types &&
          prices.data.locations
        ) {
          let seoResponse = response.data.seo;
          const pricingData = prices.data.server_types; // flat objects with needed fields
          const locList = prices.data.locations; // array of { system, title }
          // Ensure canonical URL is absolute.
          if (
            seoResponse.canonical_url &&
            seoResponse.canonical_url.startsWith("/")
          ) {
            let hostname = window.location.hostname;
            if (!hostname.startsWith("www.")) {
              hostname = "www." + hostname;
            }
            seoResponse.canonical_url =
              window.location.protocol +
              "//" +
              hostname +
              seoResponse.canonical_url;
          }
          setSeoData(seoResponse);
          setVpsPackages(pricingData);
          // Use the locations list from backend.
          setLocations(locList);
          // Set default selected location as the one with the lowest price.
          const defaultLoc = getLowestPriceLocation(pricingData, locList);
          if (defaultLoc) {
            setSelectedLocation(defaultLoc);
          } else if (locList.length > 0) {
            setSelectedLocation(locList[0].system);
          }
        }
      } catch (err) {
        console.error("Failed to load SEO data:", err);
        setError(err.message || "An error occurred while loading SEO data.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Error: {error}</h2>
      </Container>
    );
  }

  /**
   * Filters VPS packages to those matching the selected location.
   * @returns {Array} - Filtered VPS package objects.
   */
  const getFilteredPackages = () => {
    return vpsPackages.filter(
      (pkg) =>
        pkg.groupedPrices &&
        pkg.groupedPrices[selectedLocation] &&
        pkg.groupedPrices[selectedLocation].prices &&
        pkg.groupedPrices[selectedLocation].prices.length > 0
    );
  };

  const filteredPackages = getFilteredPackages();

  return (
    <>
      <Helmet>
        <title>{seoData.title} - Marmasco Cloud VPS Hosting</title>
        <meta name="description" content={seoData.meta_description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.og_title} />
        <meta property="og:description" content={seoData.og_description} />
        <meta property="og:image" content={seoData.og_image} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={seoData.canonical_url} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Product",
            name: `${seoData.title} - Marmasco Cloud VPS Hosting`,
            description: seoData.meta_description,
          })}
        </script>
      </Helmet>

      {/* Header Section */}
      <Container fluid className="py-2 fade-in">
        <div
          className="page-hero text-center text-white mb-5"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${seoData.og_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "4rem 2rem",
          }}
        >
          <div style={{ marginBottom: "15rem" }}>
            <h1 className="display-4 fw-bold">{seoData.title}</h1>
            <p className="lead fs-4 mx-auto" style={{ maxWidth: "75%" }}>
              {seoData.meta_description}
            </p>
          </div>
        </div>
      </Container>

      {/* Main Content Section */}
      <Container
        className="bg-white py-4 px-4 fade-in mb-2 rounded-3 shadow-lg"
        style={{ marginTop: "-18rem", borderRadius: "1rem" }}
      >
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="section-title">Configure Your Server</h2>
            <p className="lead text-muted">Find your ideal server by selecting your location and adjusting the specs—prices and availability vary by region. Every server includes a free dedicated IP, with extra floating IPs available upon activation.</p>
          </Col>
        </Row>
        {/* Location Selector */}
        {locations.length > 0 && (
          <Row className="mt-5 mb-2">
            <div
              className="d-grid gap-2"
              style={{
                gridTemplateColumns:
                  locations.length > 1
                    ? "repeat(auto-fit, minmax(150px, 1fr))"
                    : "auto",
              }}
            >
              {locations.map((loc, idx) => (
                <Button
                  key={idx}
                  variant={
                    loc.system === selectedLocation
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setSelectedLocation(loc.system)}
                  className="shadow-sm"
                >
                  {loc.title}
                </Button>
              ))}
            </div>
          </Row>
        )}

        {/* Server Configurator Section */}
        <Row>
          <Col>
            <ServerConfigurator
              location={selectedLocation}
              servers={filteredPackages}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HostingPage;
