/**
 * @constant privacyPolicyText
 * @type {string}
 * @description Final, structured Privacy Policy text (last updated 01 January 2025).
 */
export const privacyPolicyText = `Last Updated: 01 January 2025

1. Introduction

Marmasco Technologies (“we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your personal data when you interact with our services in accordance with the General Data Protection Regulation (GDPR).

2. Data We Collect

- Personal Information: This may include your name, email address, phone number, and similar details when you register or use our services.

- Usage Data: We collect information on how you navigate our website or platform, such as IP addresses, browser types, and activity logs.

3. How We Use Your Data

- Service Provision: We process your personal data to operate and maintain our core offerings, including domain registration, web hosting, and DNS management.

- Communication: We may send you emails or messages about your account, important updates, and technical notices.

- Marketing (If Applicable): We may send you promotional messages if you consent to receive them. You can opt out at any time.

4. Data Sharing

We may share your data with trusted third-party providers who assist in delivering our services (such as payment processors or hosting partners). These entities are obligated to comply with GDPR and protect your data.

5. Data Retention & Security

We retain personal data only as long as necessary for legitimate business or legal purposes. We implement security measures, including encryption and access restrictions, to safeguard your data against unauthorized access, alteration, or disclosure.

6. Your Rights Under GDPR

You have the right to:

- Access the personal data we hold about you

- Request corrections or deletions

- Object to or restrict our processing of your data

- Port your data to another service

For assistance, please contact us using the details below.

7. Updates to This Policy

We may update this Privacy Policy to reflect changes in our practices or to comply with legal obligations. Changes will be posted on our website.

8. Contact Information

If you have any questions or concerns about this Privacy Policy, please contact us at:

Email:
support@marmasco.com

Address:
15992 Unit P, Seke, Chitungwiza, Zimbabwe

Phone:
+263 78 605 8024
`;

/**
 * @constant cookiePolicyText
 * @type {string}
 * @description Final, structured Cookie Policy text (last updated 01 January 2025).
 */
export const cookiePolicyText = `Last Updated: 01 January 2025

1. Introduction

Marmasco Technologies (“we,” “us,” or “our”) uses cookies and similar technologies to enhance your experience and ensure our services function properly.

2. What Are Cookies?

Cookies are small data files stored on your device when you visit a website. They help us remember your preferences and improve site performance.

3. Types of Cookies We Use

- Strictly Necessary Cookies: Essential for the operation of our website.

- Performance Cookies: Collect anonymous data on how users interact with our site, helping us improve its efficiency.

- Functionality Cookies: Remember your preferences to customize your experience.

- Targeting/Advertising Cookies: May be used to deliver relevant ads, with your consent.

4. Managing Cookies

You can modify your cookie preferences or disable cookies entirely through your browser settings. However, some features of our services may not function correctly if cookies are disabled.

5. Third-Party Services

We may use trusted third-party services (like analytics providers) that set cookies on our behalf. These third parties are required to comply with GDPR.

6. Policy Updates

We may update this Cookie Policy to reflect changes in our technology or data practices. Updated versions will be posted on our website.

7. Contact Us

For questions about our Cookie Policy or to learn more about how we use cookies, contact us at:

Email:
support@marmasco.com

Address:
15992 Unit P, Seke, Chitungwiza, Zimbabwe

Phone:
+263 78 605 8024
`;

/**
 * @constant termsPolicyText
 * @type {string}
 * @description Final, structured Terms of Service text (last updated 01 January 2025).
 */
export const termsPolicyText = `Last Updated: 01 January 2025

1. Introduction

These Terms of Service ("Terms") govern your use of the Marmasco Technologies website and services ("Services"). By accessing or using our Services, you agree to comply with these Terms.

2. Services

Marmasco Technologies provides web hosting, domain registration, and related services. We reserve the right to modify or discontinue any part of our Services at any time.

3. User Responsibilities

- You agree to use our Services in compliance with all applicable laws and regulations.
- You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.

4. Payment and Billing

- You agree to pay all fees associated with the Services you purchase.
- All payments are non-refundable unless otherwise stated.

5. Intellectual Property

- All content and materials on our website are the intellectual property of Marmasco Technologies or its licensors.
- You may not use, reproduce, or distribute any content without our prior written permission.

6. Limitation of Liability

- Marmasco Technologies is not liable for any indirect, incidental, or consequential damages arising from your use of our Services.
- Our total liability to you for any claims arising from your use of our Services is limited to the amount you paid us in the past 12 months.

7. Termination

- We may terminate or suspend your access to our Services at any time, without prior notice or liability, for any reason, including if you breach these Terms.

8. Changes to Terms

- We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our Services after any changes constitutes your acceptance of the new Terms.

9. Governing Law

- These Terms are governed by and construed in accordance with the laws of Zimbabwe, without regard to its conflict of law principles.

10. Contact Information

If you have any questions or concerns about these Terms, please contact us at:

Email:
support@marmasco.com

Address:
15992 Unit P, Seke, Chitungwiza, Zimbabwe

Phone:
+263 78 605 8024
`;
