/**
 * @file DetailsSection.jsx
 * @description A React component for displaying detailed domain information in a sidebar-friendly layout,
 *              featuring DNS records, registrant information, and action buttons.
 * @param {Object} props - The component props.
 * @param {Object} props.domain - The currently selected domain containing domain.records, owner info, etc.
 * @returns {JSX.Element} A component rendering domain details and registrant information in tabs.
 * @author
 *   Masimba Maregedze
 */

import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import {
  FaSyncAlt, // Icon for "Renew"
  FaServer, // Icon for "DNS"
  FaUnlockAlt, // Icon for "Release"
  FaEdit, // Icon for "Edit"
  FaTrash,
  FaPlus,
  FaUserEdit, // Icon for "Delete"
} from "react-icons/fa";

const DetailsSection = ({ domain }) => {
  /**
   * State to toggle between "Records" and "Registrant" views.
   */
  const [showDetails, setShowDetails] = useState(true);

  /**
   * Fallback sample records for demo purposes only.
   * Remove or replace with real data in production.
   */
  const sampleRecords = [
    { id: 1, name: domain.name, ttl: 14400, type: "A", record: "1.2.3.4" },
    {
      id: 2,
      name: domain.name,
      ttl: 14400,
      type: "MX",
      record: "mail.example.com.",
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
    {
      id: 3,
      name: "www.example.com.",
      ttl: 14400,
      type: "CNAME",
      record: domain.name,
    },
  ];

  /**
   * Fallback sample owner info for demo purposes.
   * Remove or replace with real data in production.
   */
  const sampleOwner = {
    name: "John Doe",
    email: "john@example.com",
    org: "Example Inc.",
    phone: "+1 (555) 123-4567",
  };

  /**
   * Derived domain data.
   *  - If domain.records is missing or empty, fallback to sampleRecords.
   *  - If domain.owner is missing, fallback to sampleOwner.
   */
  const records = domain?.records?.length ? domain.records : sampleRecords;

  const ownerData = domain?.owner ? domain.owner : sampleOwner;

  /**
   * Handlers for each action button
   * @function handleRenew
   * @description Handler for renewing the domain.
   */
  const handleRenew = () => {
    // TODO: Implement your renew logic here
    console.log("Domain renewed");
  };

  /**
   * @function handleViewOwner
   * @description Handler for showing the domain's owner information.
   */
  const handleViewOwner = () => {
    // TODO: Show ownership details or open a modal
    console.log("View owner details");
  };

  /**
   * @function handleNameservers
   * @description Handler for managing domain's Nameserver settings.
   */
  const handleNameservers = () => {
    // TODO: Manage DNS nameservers here
    console.log("Manage nameservers");
  };

  /**
   * @function handleRelease
   * @description Handler for releasing or removing the domain from your account.
   */
  const handleRelease = () => {
    // TODO: Implement domain release logic
    console.log("Domain released");
  };

  /**
   * @function handleEditRecord
   * @description Handler for editing a DNS record.
   * @param {number} recordId - The ID of the record to edit.
   */
  const handleEditRecord = (recordId) => {
    // TODO: Implement edit logic
    console.log("Edit record with ID:", recordId);
  };

  /**
   * @function handleDeleteRecord
   * @description Handler for deleting a DNS record.
   * @param {number} recordId - The ID of the record to delete.
   */
  const handleDeleteRecord = (recordId) => {
    // TODO: Implement delete logic
    console.log("Delete record with ID:", recordId);
  };

  /**
   * Handlers for adding new record types
   * @function handleAddARecord
   * @description Handler for adding a new A record.
   */
  const handleAddARecord = () => {
    // TODO: Open a form or handle adding an A record
    console.log("Add A record");
  };

  /**
   * @function handleAddCNAMERecord
   * @description Handler for adding a new CNAME record.
   */
  const handleAddCNAMERecord = () => {
    // TODO: Add CNAME record
    console.log("Add CNAME record");
  };

  /**
   * @function handleAddMXRecord
   * @description Handler for adding a new MX record.
   */
  const handleAddMXRecord = () => {
    // TODO: Add MX record
    console.log("Add MX record");
  };

  /**
   * @function handleAddTXTRecord
   * @description Handler for adding a new TXT record.
   */
  const handleAddTXTRecord = () => {
    // TODO: Add TXT record
    console.log("Add TXT record");
  };

  return (
    <div
      className="details-section d-flex flex-column"
      style={{
        height: "100vh", // Take full viewport height
        overflow: "hidden", // Prevent overall scrolling
      }}
    >
      {/* Tabs Section - Fixed at the top */}
      <div
        className="tabs-section mb-3 d-flex"
        style={{
          gap: "1rem",
          flexShrink: 0,
          backgroundColor: "#fff",
          zIndex: 2,
          padding: "0.5rem 0",
        }}
      >
        <Button
          variant={showDetails ? "secondary" : "outline-secondary"}
          onClick={() => setShowDetails(true)}
          style={{ flex: 1 }}
          size="sm"
        >
          <span>Records</span>
        </Button>
        <Button
          variant={!showDetails ? "secondary" : "outline-secondary"}
          onClick={() => setShowDetails(false)}
          style={{ flex: 1 }}
          size="sm"
        >
          <span>Registrant</span>
        </Button>
      </div>

      {/* Scrollable Content Area */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "1.5rem",
        }}
      >
        {domain ? (
          showDetails ? (
            <div>
              {/* DNS Records Section - Now Sidebar-Friendly */}
              <ListGroup variant="flush" className="mb-3">
                {records.map((rec) => (
                  <ListGroup.Item
                    key={rec.id}
                    className="mb-2"
                    style={{ border: "1px solid #dee2e6", borderRadius: "4px" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Record Title and Action Buttons */}
                      <div>
                        <strong>{rec.name}</strong>
                        <div style={{ fontSize: "0.85rem" }}>
                          <span className="text-muted">Type: </span>
                          {rec.type}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <Button
                          variant="outline-success"
                          size="sm"
                          onClick={() => handleEditRecord(rec.id)}
                          title="Edit DNS Record"
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleDeleteRecord(rec.id)}
                          title="Delete DNS Record"
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </div>

                    {/* Record Details */}
                    <div style={{ fontSize: "0.85rem", marginTop: "0.5rem" }}>
                      <span className="text-muted">TTL: </span>
                      {rec.ttl} &nbsp;|&nbsp;
                      <span className="text-muted">Record: </span>
                      {rec.record}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {/* Buttons container with flex layout */}
              <div className="d-flex flex-wrap gap-2 mb-3">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleAddARecord}
                  className="btn-tiny flex-grow-1"
                >
                  <FaPlus className="fs-tiny" />A Record
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleAddCNAMERecord}
                  className="btn-tiny flex-grow-1"
                >
                  <FaPlus className="fs-tiny" />
                  CNAME
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleAddMXRecord}
                  className="btn-tiny flex-grow-1"
                >
                  <FaPlus className="fs-tiny" />
                  MX
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleAddTXTRecord}
                  className="btn-tiny flex-grow-1"
                >
                  <FaPlus className="fs-tiny" />
                  TXT
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <br />
              <p>
                <strong>Name:</strong> {ownerData.name}
              </p>
              <p>
                <strong>Email:</strong> {ownerData.email}
              </p>
              <p>
                <strong>Organization:</strong> {ownerData.org}
              </p>
              <p>
                <strong>Phone:</strong> {ownerData.phone}
              </p>
            </div>
          )
        ) : (
          <h5 className="text-center">Select an item to see the details</h5>
        )}
      </div>

      {/* Action Buttons */}
      <div
        className="action-buttons d-flex flex-wrap"
        style={{ marginTop: "auto", paddingTop: "10px", gap: "0.25rem" }}
      >
        <Button
          variant="outline-primary"
          className="btn-sm flex-grow-1"
          onClick={handleRenew}
        >
          <FaSyncAlt className="me-2" />
          Renew
        </Button>

        <Button
          variant="outline-info"
          className="btn-sm flex-grow-1"
          onClick={handleViewOwner}
        >
          <FaUserEdit className="me-2" />
          Modify
        </Button>

        <Button
          variant="outline-warning"
          className="btn-sm flex-grow-1"
          onClick={handleNameservers}
        >
          <FaServer className="me-2" />
          Nameservers
        </Button>

        <Button
          variant="outline-danger"
          className="btn-sm flex-grow-1"
          onClick={handleRelease}
        >
          <FaUnlockAlt className="me-2" />
          Release
        </Button>
      </div>
    </div>
  );
};

export default DetailsSection;
